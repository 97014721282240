/* Options:
Date: 2024-11-05 13:58:05
Version: 6.10
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:5000

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export interface IGet
{
}

// @DataContract
export class QueryBase
{
    // @DataMember(Order=1)
    public skip?: number;

    // @DataMember(Order=2)
    public take?: number;

    // @DataMember(Order=3)
    public orderBy: string;

    // @DataMember(Order=4)
    public orderByDesc: string;

    // @DataMember(Order=5)
    public include: string;

    // @DataMember(Order=6)
    public fields: string;

    // @DataMember(Order=7)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<QueryBase>) { (Object as any).assign(this, init); }
}

export class QueryDb_2<From, Into> extends QueryBase
{

    public constructor(init?: Partial<QueryDb_2<From, Into>>) { super(init); (Object as any).assign(this, init); }
}

export class AgencyOri
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    public agencyId?: number;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public userId?: number;

    public ori: string;
    public ldi: string;
    public rowVersion: number;

    public constructor(init?: Partial<AgencyOri>) { (Object as any).assign(this, init); }
}

export class AgencySettings
{
    public parentNotificationDelayInHours?: number;
    public childFeatureEnabled?: boolean;
    public facialRecognitionEnabled?: boolean;
    public instantMessagingEnabled?: boolean;
    public intelFeatureEnabled?: boolean;
    public dispatcherLiveLinkFeatureEnabled?: boolean;
    public moneyBatchFeatureEnabled?: boolean;
    public currencyAgencyEnabled?: boolean;
    public globalSearchFeatureEnabled?: boolean;
    public subjectPacketFeatureEnabled?: boolean;
    public systemAdminFeatureEnabled?: boolean;
    public agencyAdminFeatureEnabled?: boolean;
    public investigationFeatureEnabled?: boolean;
    public investigativeAlertsFeatureEnabled?: boolean;
    public photoLineupFeatureEnabled?: boolean;
    public monitoringFeatureEnabled?: boolean;
    public stateSwitchEnabled?: boolean;
    public lprEnabled?: boolean;
    public voiceReadoutEnabled?: boolean;
    public defaultIncomingInvestigationDivisionNotifications: string;
    public defaultIncomingIntelDivisionNotifications: string;
    public latitude?: number;
    public longitude?: number;
    public defaultIncomingInvestigativeAlertDivisionNotifications: string;
    public allowFloatingOriFeatureEnabled?: boolean;
    public ipFilterFeatureEnabled?: boolean;
    public importSubjectPacketOrIntelFeatureEnabled?: boolean;
    public ips: string[];

    public constructor(init?: Partial<AgencySettings>) { (Object as any).assign(this, init); }
}

export class Contract
{
    public startDate?: string;
    public endDate?: string;
    public seats?: number;

    public constructor(init?: Partial<Contract>) { (Object as any).assign(this, init); }
}

export class AgencySetting
{
    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    // @Required()
    public id: number;

    public agency: Agency;
    public parentNotificationDelayInHours?: number;
    // @Ignore()
    public defaultFeatureEnabled: boolean;

    // @Required()
    public childFeatureEnabled: boolean;

    public facialRecognitionEnabled: boolean;
    // @Required()
    public intelFeatureEnabled: boolean;

    // @Required()
    public dispatcherLiveLinkFeatureEnabled: boolean;

    // @Required()
    public moneyBatchFeatureEnabled: boolean;

    public currencyAgencyEnabled: boolean;
    // @Required()
    public globalSearchFeatureEnabled: boolean;

    public subjectPacketFeatureEnabled: boolean;
    public systemAdminFeatureEnabled: boolean;
    public agencyAdminFeatureEnabled: boolean;
    public investigationFeatureEnabled: boolean;
    public photoLineupFeatureEnabled: boolean;
    public monitoringFeatureEnabled: boolean;
    public ipFilterFeatureEnabled: boolean;
    // @Required()
    public investigativeAlertsFeatureEnabled: boolean;

    // @Required()
    public stateSwitchEnabled: boolean;

    public lprEnabled: boolean;
    public voiceReadoutEnabled: boolean;
    public instantMessagingEnabled: boolean;
    public importSubjectPacketOrIntelFeatureEnabled: boolean;
    public defaultSeizureDivisionNotifications: string;
    public defaultIncomingInvestigationDivisionNotifications: string;
    public defaultIncomingIntelDivisionNotifications: string;
    public latitude?: number;
    public longitude?: number;
    public allowFloatingOriFeatureEnabled?: boolean;
    public defaultIncomingInvestigativeAlertDivisionNotifications: string;

    public constructor(init?: Partial<AgencySetting>) { (Object as any).assign(this, init); }
}

export class AgencyContract
{
    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    // @Required()
    public id: number;

    public agency: Agency;
    // @Required()
    public startDate: string;

    // @Required()
    public endDate: string;

    // @Required()
    public seats: number;

    public constructor(init?: Partial<AgencyContract>) { (Object as any).assign(this, init); }
}

export class Permission
{
    // @Required()
    public id: number;

    // @Required()
    public description: string;

    public constructor(init?: Partial<Permission>) { (Object as any).assign(this, init); }
}

export class PermissionGroupPermission
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.PermissionGroup)")
    // @Required()
    public permissionGroupId: string;

    public permissionGroup: PermissionGroup;
    // @References("typeof(LENSSX.ServiceModel.Types.Permission)")
    // @Required()
    public permissionId: number;

    public permission: Permission;

    public constructor(init?: Partial<PermissionGroupPermission>) { (Object as any).assign(this, init); }
}

export class PermissionGroupUser
{
    public id: string;
    // @References("typeof(LENSSX.ServiceModel.Types.PermissionGroup)")
    // @Required()
    public permissionGroupId: string;

    public permissionGroup: PermissionGroup;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public userId: number;

    public user: User;

    public constructor(init?: Partial<PermissionGroupUser>) { (Object as any).assign(this, init); }
}

export class PermissionGroup
{
    // @Required()
    public id: string;

    // @Required()
    public name: string;

    public systemManaged: boolean;
    public permissions: PermissionGroupPermission[];
    public agencyPermissionGroup: AgencyPermissionGroup;
    public permissionGroupUsers: PermissionGroupUser[];

    public constructor(init?: Partial<PermissionGroup>) { (Object as any).assign(this, init); }
}

export class AgencyPermissionGroup
{
    // @Required()
    public id: string;

    // @Required()
    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    public agencyId: number;

    public agency: Agency;
    // @References("typeof(LENSSX.ServiceModel.Types.PermissionGroup)")
    // @Required()
    public permissionGroupId: string;

    public permissionGroup: PermissionGroup;

    public constructor(init?: Partial<AgencyPermissionGroup>) { (Object as any).assign(this, init); }
}

export class AgencyIpBoundry
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    public agencyId?: number;

    public ipAddress: string;

    public constructor(init?: Partial<AgencyIpBoundry>) { (Object as any).assign(this, init); }
}

export class AgencyIpBoundryAuditLog
{
    // @Required()
    public id: number;

    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    public agencyId: number;

    public auditEvent: string;
    public eventDate: string;

    public constructor(init?: Partial<AgencyIpBoundryAuditLog>) { (Object as any).assign(this, init); }
}

export class Agency
{
    public id: number;
    public name: string;
    // @Required()
    public isActive: boolean;

    // @Required()
    public timezone: string;

    public primaryAddress1: string;
    public primaryAddress2: string;
    public primaryCity: string;
    // @References("typeof(LENSSX.ServiceModel.Types.State)")
    public primaryStateId?: number;

    public primaryZipcode: string;
    public primaryCountyParish: string;
    public primaryContactName: string;
    public primaryContactPhone: string;
    public secondaryAddress1: string;
    public secondaryAddress2: string;
    public secondaryCity: string;
    // @References("typeof(LENSSX.ServiceModel.Types.State)")
    public secondaryStateId?: number;

    public secondaryZipcode: string;
    public secondaryCountyParish: string;
    public secondaryContactName: string;
    public secondaryContactPhone: string;
    public ori: string;
    public deactivatedUserNotificationPhoneNumber: string;
    public deactivatedUserNotificationEmail: string;
    // @References("typeof(LENSSX.ServiceModel.Types.AgencyType)")
    public agencyTypeId?: number;

    public users: User[];
    public settings: AgencySetting;
    public contract: AgencyContract;
    public permissionGroups: AgencyPermissionGroup[];
    public agencyIpBoundries: AgencyIpBoundry[];
    public agencyIpBoundryAuditLog: AgencyIpBoundryAuditLog[];
    public ncicSeatCount?: number;
    // @Ignore()
    public isValid: boolean;

    public refactorConverted: boolean;
    public ldi: string;
    public notes: string;

    public constructor(init?: Partial<Agency>) { (Object as any).assign(this, init); }
}

export class UserPermission
{
    public id: number;
    // @Required()
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public userId: number;

    // @Required()
    // @References("typeof(LENSSX.ServiceModel.Types.Permission)")
    public permissionId: number;

    public permission: Permission;
    public deny: boolean;

    public constructor(init?: Partial<UserPermission>) { (Object as any).assign(this, init); }
}

export class Division
{
    public id: number;
    public name: string;
    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    // @Required()
    public agencyId: number;

    public agency: Agency;

    public constructor(init?: Partial<Division>) { (Object as any).assign(this, init); }
}

export class AgencyUserGroupAreaLink implements IAuditable
{
    public id: number;
    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    // @Required()
    public agencyId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.AgencyArea)")
    // @Required()
    public areaId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public userId: number;

    public user: User;
    // @References("typeof(LENSSX.ServiceModel.Types.Division)")
    // @Required()
    public divisionId: number;

    public division: Division;
    // @Required()
    public createdBy: number;

    // @Required()
    public updatedBy: number;

    // @Required()
    public createdAt: string;

    // @Required()
    public updatedAt: string;

    public constructor(init?: Partial<AgencyUserGroupAreaLink>) { (Object as any).assign(this, init); }
}

export class UserSettings
{
    public theme: string;
    public saveDataTableFilters: boolean;
    public defaultDashboardTab: string;
    public defaultMyRecordsTab: string;
    public defaultSystemAdminTab: string;
    public defaultAgencyAdminTab: string;
    public lprCaptureCancelDurationInSeconds?: number;
    public lprLiveStreamUrl: string;
    public readOutVoice: string;
    public readOutQueriesWhen: string;
    public defaultLPRStateId?: number;
    public hideQuickTagBox: boolean;

    public constructor(init?: Partial<UserSettings>) { (Object as any).assign(this, init); }
}

export class User
{
    public badgeNumber: string;
    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    // @Required()
    public agencyId: number;

    public agency: Agency;
    // @Required()
    public smsNotificationsEnabled: boolean;

    // @Required()
    public emailNotificationsEnabled: boolean;

    // @References("typeof(LENSSX.ServiceModel.Types.AccountState)")
    // @Required()
    public accountState: number;

    public email: string;
    // @Required()
    public emailConfirmed: boolean;

    public passwordHash: string;
    public securityStamp: string;
    public userAuthenticatorSecurityStamp: string;
    public phoneNumber: string;
    // @Required()
    public phoneNumberConfirmed: boolean;

    // @Required()
    public twoFactorEnabled: boolean;

    public lockoutEndDateUtc?: string;
    // @Required()
    public lockoutEnabled: boolean;

    // @Required()
    public accessFailedCount: number;

    // @Required()
    public userName: string;

    // @Required()
    public isActive: boolean;

    // @Required()
    public deleted: boolean;

    public userPermissions: UserPermission[];
    public permissionGroups: PermissionGroupUser[];
    public agencyUserGroupAreaLinks: AgencyUserGroupAreaLink[];
    public oris: AgencyOri[];
    // @Ignore()
    public canReceiveNotifications: boolean;

    public stateUserName: string;
    public statePassword: string;
    public statePasswordExpirationDate?: string;
    public id: number;
    public primaryEmail: string;
    public salt: string;
    public digestHa1Hash: string;
    public roles: string[];
    public permissions: string[];
    public refId?: number;
    public refIdStr: string;
    public invalidLoginAttempts: number;
    public lastLoginAttempt?: string;
    public lockedDate?: string;
    public createdDate: string;
    public modifiedDate: string;
    public displayName: string;
    public firstName: string;
    public lastName: string;
    public company: string;
    public birthDate?: string;
    public birthDateRaw: string;
    public address: string;
    public address2: string;
    public city: string;
    public state: string;
    public country: string;
    public culture: string;
    public fullName: string;
    public gender: string;
    public language: string;
    public mailAddress: string;
    public nickname: string;
    public postalCode: string;
    public timeZone: string;
    public meta: { [index: string]: string; };
    public settings: UserSettings;
    public combinedName: string;
    public refactorConverted: boolean;
    public secretKey: string;
    public encryptionKey: string;
    public sessionLockPIN: string;
    public userHasAuthenticator: boolean;
    public authenticatorCodeResetFlag: boolean;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class InvestigationType
{
    // @Required()
    public id: number;

    public name: string;

    public constructor(init?: Partial<InvestigationType>) { (Object as any).assign(this, init); }
}

export class InvestigationState
{
    // @Required()
    public id: number;

    public name: string;

    public constructor(init?: Partial<InvestigationState>) { (Object as any).assign(this, init); }
}

export class DispositionType
{
    public id: number;
    public name: string;

    public constructor(init?: Partial<DispositionType>) { (Object as any).assign(this, init); }
}

export class InvestigationDisposition
{
    public id: string;
    // @References("typeof(LENSSX.ServiceModel.Types.Investigation)")
    // @Required()
    public investigationId: string;

    // @References("typeof(LENSSX.ServiceModel.Types.DispositionType)")
    // @Required()
    public dispositionTypeId: number;

    public dispositionType: DispositionType;
    // @Required()
    public completedAdditionalInfo: boolean;

    public constructor(init?: Partial<InvestigationDisposition>) { (Object as any).assign(this, init); }
}

export class SubjectPacketIdentificationType
{
    public name: string;
    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacketType)")
    // @Required()
    public subjectPacketTypeId: number;

    // @Required()
    public id: number;

    public constructor(init?: Partial<SubjectPacketIdentificationType>) { (Object as any).assign(this, init); }
}

export class SubjectPacketType
{
    // @Required()
    public id: number;

    public name: string;

    public constructor(init?: Partial<SubjectPacketType>) { (Object as any).assign(this, init); }
}

export class State
{
    public abbreviation: string;
    public name: string;
    // @Required()
    public id: number;

    public stateSwitchStatus: string;

    public constructor(init?: Partial<State>) { (Object as any).assign(this, init); }
}

export class IdentificationMetadata
{
    public firstName: string;
    public lastName: string;
    public middleInitial: string;
    public birthdate: string;
    public sex: string;
    public race: string;
    public make: string;
    public model: string;
    public year?: number;
    public color: string;
    public caliber: string;
    public profileDescription: string;
    public mobilePaymentAppInformation: string;
    public mobilePaymentAppType: string;
    public cryptoCurrencyType: string;
    public cryptoCurrencyDescription: string;

    public constructor(init?: Partial<IdentificationMetadata>) { (Object as any).assign(this, init); }
}

export class TattooLocation
{
    // @Required()
    public id: number;

    public name: string;
    public shortName: string;

    public constructor(init?: Partial<TattooLocation>) { (Object as any).assign(this, init); }
}

export class SocialMediaType
{
    // @Required()
    public id: number;

    public name: string;

    public constructor(init?: Partial<SocialMediaType>) { (Object as any).assign(this, init); }
}

export class PacketIdentification implements IAuditable
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacket)")
    // @Required()
    public subjectPacketId: string;

    public subjectPacket: SubjectPacket;
    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacketIdentificationType)")
    // @Required()
    public subjectPacketIdentificationTypeId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.State)")
    public stateId?: number;

    public identificationNumber: string;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public createdBy: number;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public updatedBy: number;

    // @Required()
    public createdAt: string;

    // @Required()
    public updatedAt: string;

    public metadata: IdentificationMetadata;
    public publicNotes: string;
    public privateNotes: string;
    public tattooColor: string;
    public tattooDescription: string;
    public tattooAffiliations: string;
    // @References("typeof(LENSSX.ServiceModel.Types.TattooLocation)")
    public tattooLocationId?: number;

    public tattooLocation: TattooLocation;
    public maskedSSN: string;
    // @References("typeof(LENSSX.ServiceModel.Types.SocialMediaType)")
    public profileTypeId?: number;

    public socialMediaType: SocialMediaType;

    public constructor(init?: Partial<PacketIdentification>) { (Object as any).assign(this, init); }
}

export class PacketClassification implements IAuditable
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacket)")
    // @Required()
    public subjectPacketId: string;

    // @References("typeof(LENSSX.ServiceModel.Types.PacketIdentification)")
    public packetIdentificationId?: string;

    public packetIdentification: PacketIdentification;
    // @References("typeof(LENSSX.ServiceModel.Types.SubjectType)")
    // @Required()
    public subjectTypeId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacketClassification)")
    // @Required()
    public subjectPacketClassificationId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public createdBy: number;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public updatedBy: number;

    // @Required()
    public createdAt: string;

    // @Required()
    public updatedAt: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    // @Required()
    public agencyId: number;

    public reportNumber: string;
    public publicNotes: string;
    public privateNotes: string;

    public constructor(init?: Partial<PacketClassification>) { (Object as any).assign(this, init); }
}

export class SubjectPacketLink
{
    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacket)")
    // @Required()
    public firstSubjectPacketId: string;

    public firstSubjectPacket: SubjectPacket;
    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacket)")
    // @Required()
    public secondSubjectPacketId: string;

    public secondSubjectPacket: SubjectPacket;

    public constructor(init?: Partial<SubjectPacketLink>) { (Object as any).assign(this, init); }
}

export class PrivateSubjectPacketDivision
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacket)")
    // @Required()
    public subjectPacketId: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Division)")
    // @Required()
    public divisionId: number;

    public subjectPacket: SubjectPacket;
    public division: Division;

    public constructor(init?: Partial<PrivateSubjectPacketDivision>) { (Object as any).assign(this, init); }
}

export class SubjectPacket implements IAuditable, IHaveUtn
{
    // @Required()
    public id: string;

    public name: string;
    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacketType)")
    // @Required()
    public subjectPacketTypeId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.SubjectType)")
    // @Required()
    public subjectTypeId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacketClassification)")
    // @Required()
    public subjectPacketClassificationId: number;

    public reportNumber: string;
    public publicNotes: string;
    public privateNotes: string;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public createdBy: number;

    public createdByUser: User;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public updatedBy: number;

    // @Required()
    public createdAt: string;

    // @Required()
    public updatedAt: string;

    // @Required()
    public deleted: boolean;

    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    // @Required()
    public createdByAgencyId: number;

    public createdByAgency: Agency;
    // @Required()
    public utn: string;

    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacket)")
    public parentSubjectPacketId?: string;

    public parentSubjectPacket: SubjectPacket;
    public isPrivate: boolean;
    // @Required()
    public denomination: number;

    public jsonMetadata: string;
    // @Required()
    public jsonMetadataIsPublic: boolean;

    // @Required()
    public washed: boolean;

    public washedDate?: string;
    // @References("typeof(LENSSX.ServiceModel.Types.EntityState)")
    // @Required()
    public subjectPacketStateId: number;

    public expirationDate?: string;
    public moneyBatchFile: string;
    // @Ignore()
    public wasFileUpload: boolean;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public assignedTo: number;

    public assignedToUser: User;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public stateChangeApprovedByUserId?: number;

    public stateChangeApprovedAt?: string;
    public reviewDate?: string;
    public deactivationReason: string;
    public reactivationReason: string;
    public identifications: PacketIdentification[];
    public classifications: PacketClassification[];
    public children: SubjectPacket[];
    public subjectPacketLinks: SubjectPacketLink[];
    public investigationHits: InvestigationQueryHit[];
    public processing: boolean;
    public processingFlag: string;
    public isPublic: boolean;
    public isJuvenile: boolean;
    public developmentStatusId: number;
    // @References("typeof(LENSSX.ServiceModel.Types.Investigation)")
    public derivedFromInvestigationId?: string;

    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacket)")
    public derivedFromPacketId?: string;

    public privateSubjectPacketDivisions: PrivateSubjectPacketDivision[];
    public firstName: string;
    public lastName: string;
    public middleInitial: string;
    public dateOfBirth?: string;
    public sex: string;
    public race: string;
    public locationDescription: string;
    public locationAddress: string;

    public constructor(init?: Partial<SubjectPacket>) { (Object as any).assign(this, init); }
}

export class IntelType
{
    // @Required()
    public id: number;

    public name: string;
    public shortName: string;

    public constructor(init?: Partial<IntelType>) { (Object as any).assign(this, init); }
}

export class IntelSubjectAKA
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.IntelSubject)")
    // @Required()
    public intelSubjectId: string;

    public intelSubject: IntelSubject;
    public aka: string;

    public constructor(init?: Partial<IntelSubjectAKA>) { (Object as any).assign(this, init); }
}

export class PhotoLineupMetadata
{
    // @Required()
    public id: string;

    public race: string;
    public sex: string;
    public build: string;
    public eyeColor: string;
    public eyeSight: string;
    public hairStyle: string;
    public hairColor: string;
    public beard: string;
    public mustache: string;
    // @StringLength(4)
    public birthYear?: number;

    /**
    * Unit is `lb`
    */
    public weight?: number;
    /**
    * Unit is `inch`
    */
    public height?: number;
    // @References("typeof(LENSSX.ServiceModel.Types.Media)")
    public mediaId?: number;

    public media: Media;
    public createdBy?: number;
    public updatedBy?: number;
    public createdAt: string;
    public updatedAt: string;
    public name: string;
    public driverLicenseNumber: string;
    public birthDate?: string;

    public constructor(init?: Partial<PhotoLineupMetadata>) { (Object as any).assign(this, init); }
}

export class Media implements IAuditable
{
    public id: number;
    // @References("typeof(LENSSX.ServiceModel.Types.MediaParentType)")
    // @Required()
    public parentTypeId: number;

    public parentId?: string;
    public url: string;
    public fileName: string;
    public contentType: string;
    // @Required()
    public createdBy: number;

    // @Required()
    public updatedBy: number;

    // @Required()
    public createdAt: string;

    // @Required()
    public updatedAt: string;

    // @Required()
    public sizeInBytes: number;

    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    // @Required()
    public agencyId: number;

    public localFileName: string;
    public expirationDate?: string;
    public shA256Hash: string;
    public hadErrorIndexingFace: boolean;
    public metaData: PhotoLineupMetadata;

    public constructor(init?: Partial<Media>) { (Object as any).assign(this, init); }
}

export class Bounds
{
    public x: number;
    public y: number;
    public width: number;
    public height: number;

    public constructor(init?: Partial<Bounds>) { (Object as any).assign(this, init); }
}

export class Face
{
    // @Required()
    public id: string;

    // @Required()
    // @References("typeof(LENSSX.ServiceModel.Types.Media)")
    public mediaId: number;

    public media: Media;
    // @Required()
    public faceServiceId: string;

    // @Required()
    public fromService: string;

    public confidence: number;
    public bounds: Bounds;
    // @StringLength(2147483647)
    public metadata: string;

    // @Required()
    public createdAt: string;

    public constructor(init?: Partial<Face>) { (Object as any).assign(this, init); }
}

export class IntelSubject
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Intel)")
    // @Required()
    public intelId: string;

    public intel: Intel;
    public scarsMarksTattoos: string;
    public akAs: string;
    public lastName: string;
    public firstName: string;
    public middleName: string;
    public dob?: string;
    public residence: string;
    public driversLicenseNumber: string;
    // @References("typeof(LENSSX.ServiceModel.Types.State)")
    public driversLicenseStateId?: number;

    public race: string;
    public sex: string;
    public phoneNumber: string;
    public isNonCriminal: boolean;
    public akas: IntelSubjectAKA[];
    // @Required()
    public createdAt: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Face)")
    public faceId?: string;

    public face: Face;
    public isJuvenile: boolean;

    public constructor(init?: Partial<IntelSubject>) { (Object as any).assign(this, init); }
}

export class MediaDto
{
    public id: number;
    public url: string;
    public contentType: string;
    public fileName: string;
    public localFileName: string;
    public parentId: string;
    public parentTypeId: number;
    public createdBy: number;
    public agencyId: number;
    public shA256Hash: string;

    public constructor(init?: Partial<MediaDto>) { (Object as any).assign(this, init); }
}

export class TattooColor
{
    // @Required()
    public id: number;

    public name: string;
    public code: string;

    public constructor(init?: Partial<TattooColor>) { (Object as any).assign(this, init); }
}

export class IntelTattooColor
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.TattooColor)")
    public colorId: number;

    public tattooColor: TattooColor;
    public intelTattoo: IntelTattoo;
    // @References("typeof(LENSSX.ServiceModel.Types.IntelTattoo)")
    public intelTattooId: string;

    public constructor(init?: Partial<IntelTattooColor>) { (Object as any).assign(this, init); }
}

export class IntelTattoo
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Intel)")
    public intelId: string;

    public intel: Intel;
    public description: string;
    public affiliations: string;
    public isNonCriminal: boolean;
    public location: TattooLocation;
    // @References("typeof(LENSSX.ServiceModel.Types.TattooLocation)")
    public locationId?: number;

    public colors: string;
    public media: MediaDto[];
    public intelTattooColor: IntelTattooColor[];
    // @Required()
    public createdAt: string;

    public updatedAt: string;

    public constructor(init?: Partial<IntelTattoo>) { (Object as any).assign(this, init); }
}

export class IntelAddress
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Intel)")
    // @Required()
    public intelId: string;

    public intel: Intel;
    public residence: string;
    public isNonCriminal: boolean;
    // @Required()
    public createdAt: string;

    public constructor(init?: Partial<IntelAddress>) { (Object as any).assign(this, init); }
}

export class PrivateIntelDivision
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Intel)")
    // @Required()
    public intelId: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Division)")
    // @Required()
    public divisionId: number;

    public intel: Intel;
    public division: Division;

    public constructor(init?: Partial<PrivateIntelDivision>) { (Object as any).assign(this, init); }
}

export class IntelVehicle
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Intel)")
    // @Required()
    public intelId: string;

    public isNonCriminal: boolean;
    public intel: Intel;
    public year: string;
    public make: string;
    public model: string;
    public color: string;
    public trimLevel: string;
    public bodyStyle: string;
    public engineType: string;
    public vehicleMarks: string;
    // @References("typeof(LENSSX.ServiceModel.Types.State)")
    public stateId?: number;

    public stateAbbr: string;
    public vin: string;
    public licensePlate: string;
    // @Required()
    public createdAt: string;

    public constructor(init?: Partial<IntelVehicle>) { (Object as any).assign(this, init); }
}

export class IntelSocialMediaProfile
{
    // @Required()
    public id: string;

    public profileUrl: string;
    public description: string;
    // @References("typeof(LENSSX.ServiceModel.Types.Intel)")
    public intelId: string;

    public intel: Intel;
    public isNonCriminal: boolean;
    public socialMediaType: SocialMediaType;
    // @References("typeof(LENSSX.ServiceModel.Types.SocialMediaType)")
    public socialMediaTypeId?: number;

    public media: MediaDto[];
    // @Required()
    public createdAt: string;

    public updatedAt: string;

    public constructor(init?: Partial<IntelSocialMediaProfile>) { (Object as any).assign(this, init); }
}

export class IntelUser
{
    // @References("typeof(LENSSX.ServiceModel.Types.Intel)")
    // @Required()
    public intelId: string;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public userId: number;

    public user: User;

    public constructor(init?: Partial<IntelUser>) { (Object as any).assign(this, init); }
}

export class IntelNotificationSubscription
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Intel)")
    // @Required()
    public intelId: string;

    public intel: Intel;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public userId?: number;

    public user: User;
    // @References("typeof(LENSSX.ServiceModel.Types.Division)")
    public divisionId?: number;

    public division: Division;
    // @Required()
    public enabled: boolean;

    public constructor(init?: Partial<IntelNotificationSubscription>) { (Object as any).assign(this, init); }
}

export class IntelNote
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Intel)")
    // @Required()
    public intelId: string;

    public note: string;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public createdBy: number;

    // @Required()
    public updatedBy: number;

    // @Required()
    public createdAt: string;

    // @Required()
    public updatedAt: string;

    public constructor(init?: Partial<IntelNote>) { (Object as any).assign(this, init); }
}

export class IntelAgency
{
    // @References("typeof(LENSSX.ServiceModel.Types.Intel)")
    // @Required()
    public intelId: string;

    public intel: Intel;
    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    // @Required()
    public agencyId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public createdBy: number;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public updatedBy: number;

    // @Required()
    public createdAt: string;

    // @Required()
    public updatedAt: string;

    public constructor(init?: Partial<IntelAgency>) { (Object as any).assign(this, init); }
}

export class Intel implements IAuditable, IHaveUtn
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.IntelType)")
    // @Required()
    public intelTypeId: number;

    public intelType: IntelType;
    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    // @Required()
    public agencyId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.Investigation)")
    public userSubjectPacketQueryBatchId?: string;

    public reportNumber: string;
    public userEnteredDateTime?: string;
    public title: string;
    public district: string;
    public reasonForStop: string;
    // @Required()
    public utn: string;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public createdBy: number;

    // @Required()
    public updatedBy: number;

    // @Required()
    public createdAt: string;

    // @Required()
    public updatedAt: string;

    // @Required()
    public deleted: boolean;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public assignedTo: number;

    // @References("typeof(LENSSX.ServiceModel.Types.Investigation)")
    public derivedFromInvestigationId?: string;

    public intelSubjects: IntelSubject[];
    public intelTattoos: IntelTattoo[];
    public intelAddresses: IntelAddress[];
    public privateIntelDivisions: PrivateIntelDivision[];
    public intelVehicles: IntelVehicle[];
    public intelSocialMediaProfiles: IntelSocialMediaProfile[];
    public users: IntelUser[];
    public subscriptions: IntelNotificationSubscription[];
    public notes: IntelNote[];
    public sharedWithAgencies: IntelAgency[];
    public investigationHits: InvestigationQueryHit[];
    public isPrivate: boolean;
    public developmentStatusId: number;
    // @References("typeof(LENSSX.ServiceModel.Types.EntityState)")
    // @Required()
    public intelStateId: number;

    public deactivationReason: string;
    public reactivationReason: string;

    public constructor(init?: Partial<Intel>) { (Object as any).assign(this, init); }
}

export class SeizedItem
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.SeizedItemType)")
    // @Required()
    public itemTypeId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.Seizure)")
    // @Required()
    public seizureId: string;

    public seizure: Seizure;
    // @Required()
    public value: number;

    public identificationNumber: string;
    public notes: string;

    public constructor(init?: Partial<SeizedItem>) { (Object as any).assign(this, init); }
}

export class Seizure implements IHaveUtn, IAuditable
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.SeizureType)")
    // @Required()
    public seizureTypeId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    public districtAttorneyAgencyId?: number;

    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    // @Required()
    public agencyId: number;

    public agency: Agency;
    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacket)")
    public subjectPacketId?: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Investigation)")
    public userSubjectPacketBatchId?: string;

    public userSubjectPacketBatch: Investigation;
    // @References("typeof(LENSSX.ServiceModel.Types.InvestigationQuery)")
    public userSubjectPacketQueryId?: string;

    public investigationQuery: InvestigationQuery;
    // @References("typeof(LENSSX.ServiceModel.Types.InvestigationQueryHit)")
    public userSubjectPacketQueryDetailId?: string;

    public investigationQueryDetail: InvestigationQueryHit;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public createdBy: number;

    public createdByUser: User;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public updatedBy: number;

    public updatedByUser: User;
    // @Required()
    public createdAt: string;

    // @Required()
    public updatedAt: string;

    // @Required()
    public deleted: boolean;

    public caseNumber: string;
    // @Required()
    public utn: string;

    // @References("typeof(LENSSX.ServiceModel.Types.SeizureState)")
    // @Required()
    public seizureStateId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public approvedByUserId?: number;

    public approvedAt?: string;
    public seizedItems: SeizedItem[];

    public constructor(init?: Partial<Seizure>) { (Object as any).assign(this, init); }
}

export class Alert implements IAuditable, IHaveUtn
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    public agencyId?: number;

    public agency: Agency;
    // @References("typeof(LENSSX.ServiceModel.Types.AgencyArea)")
    public areaId?: number;

    // @References("typeof(LENSSX.ServiceModel.Types.Division)")
    public divisionId?: number;

    public title: string;
    public alertContent: string;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public createdBy: number;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public updatedBy: number;

    // @Required()
    public createdAt: string;

    // @Required()
    public updatedAt: string;

    // @Required()
    public utn: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Investigation)")
    public investigationId?: string;

    public investigation: Investigation;
    // @References("typeof(LENSSX.ServiceModel.Types.Intel)")
    public intelId?: string;

    public intel: Intel;
    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacket)")
    public subjectPacketId?: string;

    public subjectPacket: SubjectPacket;
    public deactivationReason: string;
    public reactivationReason: string;
    public deactivated: boolean;

    public constructor(init?: Partial<Alert>) { (Object as any).assign(this, init); }
}

export class InvestigationQueryHit
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.InvestigationQuery)")
    // @Required()
    public investigationQueryId: string;

    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacket)")
    // @Required()
    public subjectPacketId: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Intel)")
    public intelId?: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Seizure)")
    public seizureId?: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Alert)")
    public investigativeAlertId?: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Investigation)")
    public investigationId?: string;

    public subjectPacket: SubjectPacket;
    public intel: Intel;
    public seizure: Seizure;
    public investigativeAlert: Alert;
    public investigation: Investigation;

    public constructor(init?: Partial<InvestigationQueryHit>) { (Object as any).assign(this, init); }
}

export class StateResponseLog
{
    public id: string;
    // @References("typeof(LENSSX.ServiceModel.Types.StateRequestLog)")
    public stateRequestLogId?: string;

    public stateRequestLog: StateRequestLog;
    public intTransId: string;
    public totalSegments: number;
    public currentSegment: number;
    public segmentRecombinationId: string;
    public responseType: string;
    public intInterfaceName: string;
    public intSystemName: string;
    public class: string;
    public expirationDate?: string;
    public createdAt?: string;
    public wasExpired: boolean;
    public wasConverted?: boolean;
    // @StringLength(2147483647)
    public transaction: string;

    public processingFlag: string;
    public mocked: boolean;
    public internalResponseType: string;

    public constructor(init?: Partial<StateResponseLog>) { (Object as any).assign(this, init); }
}

export class StateRequestLog
{
    public id: string;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public userId?: number;

    public ipAddress: string;
    public ori: string;
    public ldi: string;
    public userName: string;
    public messageType: string;
    public dateSubmitted: string;
    public acknowledgedAt?: string;
    public transaction: string;
    // @References("typeof(LENSSX.ServiceModel.Types.InvestigationQuery)")
    public queryId?: string;

    public replyToQ: string;
    public investigationQuery: InvestigationQuery;
    public user: User;
    public responses: StateResponseLog[];

    public constructor(init?: Partial<StateRequestLog>) { (Object as any).assign(this, init); }
}

export class InvestigationQuery implements IHaveUtn
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public userId: number;

    public user: User;
    // @Required()
    public queryDate: string;

    // @Required()
    public utn: string;

    public identificationNumber: string;
    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacketIdentificationType)")
    // @Required()
    public subjectPacketIdentificationTypeId: number;

    public subjectPacketIdentificationType: SubjectPacketIdentificationType;
    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacketType)")
    // @Required()
    public subjectPacketTypeId: number;

    public subjectPacketType: SubjectPacketType;
    // @References("typeof(LENSSX.ServiceModel.Types.Investigation)")
    public investigationId?: string;

    public investigation: Investigation;
    // @References("typeof(LENSSX.ServiceModel.Types.State)")
    public stateId?: number;

    public state: State;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public createdForUserId?: number;

    public denomination?: number;
    public lastName: string;
    public firstName: string;
    public middleInitial: string;
    public dateOfBirth?: string;
    public race: string;
    public sex: string;
    public aka: string;
    public maskedSSN: string;
    public notes: string;
    public queryDetails: InvestigationQueryHit[];
    public stateRequestLogs: StateRequestLog[];
    public processingFlag: string;
    public sentStateRequest?: boolean;
    public faceMatchType: string;
    public related: boolean;
    public tattooDescription: string;
    public tattooColors: string;
    public tattooAffiliations: string;
    public profileUrl: string;
    public profileDescription: string;
    // @References("typeof(LENSSX.ServiceModel.Types.SocialMediaType)")
    public profileTypeId?: number;

    public socialMediaType: SocialMediaType;
    // @References("typeof(LENSSX.ServiceModel.Types.TattooLocation)")
    public tattooLocationId?: number;

    public tattooLocation: TattooLocation;

    public constructor(init?: Partial<InvestigationQuery>) { (Object as any).assign(this, init); }
}

export class Investigation implements IHaveUtn, IAuditable
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.InvestigationType)")
    // @Required()
    public investigationTypeId: number;

    public investigationType: InvestigationType;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public createdForId?: number;

    public createdForUser: User;
    // @Required()
    public startTime: string;

    public stopTime?: string;
    // @Required()
    public resultedInSiezure: boolean;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public createdBy: number;

    public createdByUser: User;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public updatedBy: number;

    // @Required()
    public createdAt: string;

    // @Required()
    public updatedAt: string;

    public notes: string;
    // @Required()
    public utn: string;

    public caseNumber: string;
    // @References("typeof(LENSSX.ServiceModel.Types.InvestigationState)")
    // @Required()
    public investigationStateId: number;

    public investigationState: InvestigationState;
    public dispatchNotes: string;
    public dispositions: InvestigationDisposition[];
    public investigationQueries: InvestigationQuery[];
    public completionReason: string;
    public fullQuickTagInvestigation: boolean;

    public constructor(init?: Partial<Investigation>) { (Object as any).assign(this, init); }
}

export interface IQuerySharable
{
    visibleToUserId?: number;
    ownershipTypeId?: number;
}

export class QueryDb_1<T> extends QueryBase
{

    public constructor(init?: Partial<QueryDb_1<T>>) { super(init); (Object as any).assign(this, init); }
}

export class AuditEvent
{
    public eventId: number;
    public insertedDate: string;
    public comments: string[];
    public eventType: string;
    public entityType: string;
    public entitySubType: string;
    public entityId: string;
    public userEmail: string;
    public userName: string;
    public userId: number;
    public details: string;

    public constructor(init?: Partial<AuditEvent>) { (Object as any).assign(this, init); }
}

export class AlertData
{
    public intelId?: string;
    public investigationId?: string;
    public subjectPacketId?: string;

    public constructor(init?: Partial<AlertData>) { (Object as any).assign(this, init); }
}

export class Money
{
    public serialNumber: string;
    public denomination: number;
    public id: string;

    public constructor(init?: Partial<Money>) { (Object as any).assign(this, init); }
}

export class UpdateDefaultNotificationsPayload
{
    public subjectTypeId: number;
    public classificationId: number;

    public constructor(init?: Partial<UpdateDefaultNotificationsPayload>) { (Object as any).assign(this, init); }
}

export class EntityViewLog
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.EntityType)")
    // @Required()
    public entityTypeId: number;

    // @Required()
    public entityId: string;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public viewedByUserId: number;

    public viewedByUser: User;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public createdByUserId: number;

    // @Required()
    public viewDate: string;

    public viewType: string;
    public viewedFromId?: string;

    public constructor(init?: Partial<EntityViewLog>) { (Object as any).assign(this, init); }
}

export class EntityViewLogResponse
{
    public id: string;
    public entityTypeId: number;
    public entityTypeName: string;
    public viewedByUserId: number;
    public userFullName: string;
    public userPhoneNumber: string;
    public userEmail: string;
    public agencyName: string;
    public viewDate: string;

    public constructor(init?: Partial<EntityViewLogResponse>) { (Object as any).assign(this, init); }
}

export class GlobalSearchItem
{
    public id: string;
    public entityTypeId: number;
    public entityTypeName: string;
    public createdAt: string;
    public userId: number;
    public utn: string;

    public constructor(init?: Partial<GlobalSearchItem>) { (Object as any).assign(this, init); }
}

export class GlobalSearchItemResponse
{
    public id: string;
    public entityTypeId: number;
    public entityTypeName: string;
    public createdAt: string;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public userId: number;

    public userFirstName: string;
    public userLastName: string;
    public userFullName: string;
    public userPhoneNumber: string;
    public userEmail: string;
    public utn: string;
    public relevantData: { [index: string]: string; };

    public constructor(init?: Partial<GlobalSearchItemResponse>) { (Object as any).assign(this, init); }
}

export class IntelVehicleDto
{
    public id: string;
    public year: string;
    public make: string;
    public model: string;
    public trimLevel: string;
    public color: string;
    public bodyStyle: string;
    public engineType: string;
    public vehicleMarks: string;
    public stateId?: number;
    public stateAbbr: string;
    public vin: string;
    public licensePlate: string;
    public isNonCriminal: boolean;
    public media: MediaDto[];
    public mediaToAdd: number[];
    public mediaToDelete: number[];

    public constructor(init?: Partial<IntelVehicleDto>) { (Object as any).assign(this, init); }
}

export class IntelSubjectAkaDto
{
    public id: string;
    public intelSubjectId: string;
    public aka: string;

    public constructor(init?: Partial<IntelSubjectAkaDto>) { (Object as any).assign(this, init); }
}

export class IntelSubjectDto
{
    public id: string;
    public scarsMarksTattoos: string;
    public lastName: string;
    public firstName: string;
    public middleName: string;
    public residence: string;
    public race: string;
    public sex: string;
    public driversLicenseStateId?: number;
    public driversLicenseNumber: string;
    public dob?: string;
    public phoneNumber: string;
    public media: MediaDto[];
    public akas: IntelSubjectAkaDto[];
    public faceId?: string;
    public isJuvenile: boolean;
    public hideJuvenileView: boolean;
    public isNonCriminal: boolean;

    public constructor(init?: Partial<IntelSubjectDto>) { (Object as any).assign(this, init); }
}

export class IntelTattooDto
{
    public id: string;
    public description: string;
    public locationId?: number;
    public affiliations: string;
    public media: MediaDto[];
    public mediaToAdd: number[];
    public mediaToDelete: number[];
    public tattooColors: number[];
    public isNonCriminal: boolean;

    public constructor(init?: Partial<IntelTattooDto>) { (Object as any).assign(this, init); }
}

export class IntelAddressDto
{
    public id: string;
    public residence: string;
    public isNonCriminal: boolean;

    public constructor(init?: Partial<IntelAddressDto>) { (Object as any).assign(this, init); }
}

export class IntelSocialMediaProfileDto
{
    public id: string;
    public profileUrl: string;
    public description: string;
    public socialMediaTypeId?: number;
    public media: MediaDto[];
    public mediaToAdd: number[];
    public mediaToDelete: number[];
    public isNonCriminal: boolean;

    public constructor(init?: Partial<IntelSocialMediaProfileDto>) { (Object as any).assign(this, init); }
}

export class IdentificationMetadataDto
{
    public firstName: string;
    public lastName: string;
    public middleInitial: string;
    public birthdate: string;
    public sex: string;
    public race: string;
    public make: string;
    public model: string;
    public year?: number;
    public color: string;
    public caliber: string;
    public profileDescription: string;
    public mobilePaymentAppInformation: string;
    public mobilePaymentAppType?: number;
    public cryptoCurrencyType?: number;
    public cryptoCurrencyDescription: string;

    public constructor(init?: Partial<IdentificationMetadataDto>) { (Object as any).assign(this, init); }
}

export class InvestigationDispositionDto
{
    public investigationId: string;
    public dispositionTypeId: number;
    public dispositionName: string;
    public dispositionAbbreviation: string;
    public completedAdditionalInfo: boolean;

    public constructor(init?: Partial<InvestigationDispositionDto>) { (Object as any).assign(this, init); }
}

export class InvestigationQueryDetailDto
{
    public id: string;
    public investigationQueryId: string;
    public subjectPacketId?: string;
    public developmentStatus: string;
    public packetPublicNotes: string;
    public packetPrivateNotes: string;
    public identificationPublicNotes: string;
    public subjectPacketStateId: number;
    public subjectPacketStateName: string;
    public identificationMetadata: IdentificationMetadataDto;
    public parentSubjectPacketId?: string;
    public intelId?: string;
    public seizureId?: string;
    public investigativeAlertId?: string;
    public investigationId?: string;
    public investigationTypeId?: number;
    public title: string;
    public media: MediaDto[];
    public ownerId: number;
    public ownerName: string;
    public ownerPhone: string;
    public ownerEmail: string;
    public ownerAgencyName: string;
    public ownerAgencyId: number;
    public createdDate: string;
    public denomination?: number;
    public isCurrency: boolean;
    public isCounterfeit: boolean;
    public isPublic: boolean;
    public isSharedWithMe: boolean;
    public notes: string;
    public dispositions: InvestigationDispositionDto[];
    public classification: string;
    public classificationType: string;
    public classificationAndType: string;
    public hitType: string;
    public tattooColor: string;
    public tattooDescription: string;
    public tattooAffiliations: string;
    public tattooLocationId?: number;
    public profileUrl: string;
    public profileTypeId?: number;
    public hideJuvenileView: boolean;
    public isJuvenile: boolean;
    public name: string;
    public packetName: string;
    public firstName: string;
    public lastName: string;
    public middleInitial: string;
    public dateOfBirth?: string;
    public sex: string;
    public race: string;
    public locationDescription: string;
    public locationAddress: string;
    public subjectTypeId: number;
    public userEnteredDateTime?: string;
    public district: string;
    public intelTypeId: number;
    public reportNumber: string;
    public reviewDate?: string;

    public constructor(init?: Partial<InvestigationQueryDetailDto>) { (Object as any).assign(this, init); }
}

export class FaceCandidateListResponse
{
    // @Required()
    public id: string;

    // @Required()
    // @References("typeof(LENSSX.ServiceModel.Types.InvestigationQuery)")
    public queryId: string;

    public query: InvestigationQuery;
    // @References("typeof(LENSSX.ServiceModel.Types.Face)")
    public faceId: string;

    public face: Face;
    // @Required()
    public similarity: number;

    public constructor(init?: Partial<FaceCandidateListResponse>) { (Object as any).assign(this, init); }
}

export class SearchInvestigationQueryResponse
{
    public id: string;
    public investigationStartTime: string;
    public userFirstName: string;
    public userLastName: string;
    public investigationId: string;
    public investigationTypeId: number;
    public investigationTypeName: string;
    public subjectPacketIdentificationTypeId: number;
    public subjectPacketIdentificationTypeName: string;
    public stateId?: number;
    public stateAbbreviation: string;
    public investigationCreatedForId?: number;
    public investigationCreatedForUserFullName: string;
    public investigationNotes: string;
    public identificationNumber: string;
    public lastName: string;
    public firstName: string;
    public middleInitial: string;
    public queriedData: string;
    public dateOfBirth?: string;
    public race: string;
    public sex: string;
    public suspectName: string;
    public dispositions: InvestigationDispositionDto[];

    public constructor(init?: Partial<SearchInvestigationQueryResponse>) { (Object as any).assign(this, init); }
}

export class StateResponseFollowUpInfo
{
    public responseId: string;
    public notes: string;
    public description: string;

    public constructor(init?: Partial<StateResponseFollowUpInfo>) { (Object as any).assign(this, init); }
}

export class SearchIncompleteInvestigationResponse
{
    public id: string;
    public userFirstName: string;
    public userLastName: string;
    public startTime: string;
    public investigationTypeName: string;
    public utn: string;

    public constructor(init?: Partial<SearchIncompleteInvestigationResponse>) { (Object as any).assign(this, init); }
}

export class MediaStore
{
    public id: string;
    public fileName: string;
    public contentType: string;
    // @Required()
    public createdAt: string;

    // @Required()
    public sizeInBytes: number;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public userId: number;

    public user: User;
    public localFileName: string;
    public expirationDate?: string;

    public constructor(init?: Partial<MediaStore>) { (Object as any).assign(this, init); }
}

export class Conversation implements IAuditable
{
    public id: string;
    public lastMessageSentAt?: string;
    public latestMessageId?: string;
    public createdAt: string;
    public updatedAt: string;
    public createdBy: number;
    public updatedBy: number;

    public constructor(init?: Partial<Conversation>) { (Object as any).assign(this, init); }
}

export class Message implements IAuditable
{
    public id: string;
    // @References("typeof(LENSSX.ServiceModel.Types.Conversation)")
    public conversationId: string;

    public conversation: Conversation;
    // @StringLength(2147483647)
    public content: string;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public createdBy: number;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public updatedBy: number;

    // @Ignore()
    public createdByName: string;

    // @Ignore()
    public media: MediaDto[];

    public createdAt: string;
    public updatedAt: string;
    public clientCreatedAt: string;
    public deleted: boolean;
    /**
    * If participant is added or removed from the conversation then this flag will be set to true
    */
    public isParticipantOperationMessage: boolean;
    /**
    * It is just flag to refer that participant is removed. It will be use fpor client side to show some icon for delete, also it will be use in future for filtering.
    */
    public isDeleteOperation: boolean;
    /**
    * Capture the removed used ids from the conversation, it will used in future to decide the message limit.
    */
    public removedUserIds: string;
    /**
    * Capture the removed divison ids from the conversation, It is of no used for now but we may need this.
    */
    public removeDivisionIds: string;

    public constructor(init?: Partial<Message>) { (Object as any).assign(this, init); }
}

export class EntityNote
{
    // @Required()
    public id: string;

    public entityId: string;
    public entityType: string;
    // @StringLength(2147483647)
    public note: string;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public userId: number;

    public user: User;
    public date: string;

    public constructor(init?: Partial<EntityNote>) { (Object as any).assign(this, init); }
}

export class TriggeringUser
{
    public id: number;
    public fullName: string;
    public agencyName: string;
    public userPhone: string;
    public userEmail: string;
    public agencyPhone: string;
    public userTimezone: string;

    public constructor(init?: Partial<TriggeringUser>) { (Object as any).assign(this, init); }
}

export class NotificationDataBag
{
    public key: string;
    public value: string;

    public constructor(init?: Partial<NotificationDataBag>) { (Object as any).assign(this, init); }
}

export class NotificationInformation
{
    public shortTitle: string;
    public longTitle: string;
    public entityType: string;
    public entityId: string;
    public action: string;
    public actionVerbiage: string;
    public source: string;
    public date: string;
    public externalShortenedUrl: string;
    public internalUrlPath: string;
    public additionalInfo: NotificationDataBag[];

    public constructor(init?: Partial<NotificationInformation>) { (Object as any).assign(this, init); }
}

export class NotificationModel
{
    public triggeringUser: TriggeringUser;
    public notification: NotificationInformation;

    public constructor(init?: Partial<NotificationModel>) { (Object as any).assign(this, init); }
}

export class Notification
{
    public id: string;
    public data: NotificationModel;
    public createdDate: string;
    public read: boolean;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public recipientUserId?: number;

    public recipientUser: User;
    public recipientName: string;
    public recipientEmail: string;
    public recipientPhone: string;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public senderUserId?: number;

    public senderUser: User;
    public senderName: string;
    public senderEmail: string;
    public entityType: string;
    public entityId: string;
    public action: string;
    public title: string;
    public sent: boolean;
    public source: string;
    public moreInformation: string;
    public statuses: NotificationStatus[];

    public constructor(init?: Partial<Notification>) { (Object as any).assign(this, init); }
}

export class NotificationStatus
{
    public id: string;
    // @References("typeof(LENSSX.ServiceModel.Types.Notification)")
    public notificationId: string;

    public notification: Notification;
    public mechanism: string;
    public sent: boolean;

    public constructor(init?: Partial<NotificationStatus>) { (Object as any).assign(this, init); }
}

export class NotificationResponse
{
    public id: string;
    public data: NotificationModel;
    public createdDate: string;
    public deleted: boolean;
    public read: boolean;
    public recipientUserId?: number;
    public recipientName: string;
    public recipientEmail: string;
    public recipientPhone: string;
    public senderUserId?: number;
    public senderName: string;
    public senderEmail: string;
    public entityType: string;
    public entityId: string;
    public action: string;
    public title: string;
    public sent: boolean;
    public moreInformation: string;
    public source: string;

    public constructor(init?: Partial<NotificationResponse>) { (Object as any).assign(this, init); }
}

export class NotificationSetting
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public userId: number;

    public user: User;
    public action: string;
    public mechanism: string;
    public entityType: string;
    public enabled: boolean;

    public constructor(init?: Partial<NotificationSetting>) { (Object as any).assign(this, init); }
}

export class IdentificationDto
{
    public id: string;
    public subjectPacketIdentificationTypeId: number;
    public subjectPacketIdentificationTypeName: string;
    public stateId?: number;
    public stateName: string;
    public identificationNumber: string;
    public metadata: IdentificationMetadataDto;
    public media: MediaDto[];
    public mediaToAdd: number[];
    public mediaToDelete: number[];
    public createdAt: string;
    public publicNotes: string;
    public privateNotes: string;
    public tattooColor: string;
    public tattooDescription: string;
    public tattooAffiliations: string;
    public tattooLocationId?: number;
    public profileTypeId?: number;
    public maskedSSN: string;

    public constructor(init?: Partial<IdentificationDto>) { (Object as any).assign(this, init); }
}

export class PhotoLineup implements IAuditable, IHaveUtn
{
    // @Required()
    public id: string;

    public title: string;
    // @Required()
    public utn: string;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public createdBy: number;

    // @Required()
    public updatedBy: number;

    // @Required()
    public createdAt: string;

    // @Required()
    public updatedAt: string;

    // @Required()
    public deleted: boolean;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    // @Required()
    public assignedTo: number;

    public constructor(init?: Partial<PhotoLineup>) { (Object as any).assign(this, init); }
}

export class CreatePhotoRequest
{
    public race: string;
    public sex: string;
    public build: string;
    public eyeColor: string;
    public eyeSight: string;
    public hairStyle: string;
    public hairColor: string;
    public beard: string;
    public mustache: string;
    public birthYear?: number;
    public weight?: number;
    public heightInFeet: number;
    public heightInInch: number;
    public mediaId: number;
    public height?: number;
    public count: number;
    public name: string;
    public driverLicenseNumber: string;
    public birthDate?: string;

    public constructor(init?: Partial<CreatePhotoRequest>) { (Object as any).assign(this, init); }
}

export class SeizedItemDto
{
    public id: string;
    public identificationNumber: string;
    public seizureId: string;
    public notes: string;
    public value: number;
    public itemTypeId: number;

    public constructor(init?: Partial<SeizedItemDto>) { (Object as any).assign(this, init); }
}

export class Share
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public sharedByUserId: number;

    public sharedByUser: User;
    // @References("typeof(LENSSX.ServiceModel.Types.User)")
    public sharedWithUserId?: number;

    public sharedWithUser: User;
    // @References("typeof(LENSSX.ServiceModel.Types.Division)")
    public sharedWithDivisionId?: number;

    public sharedWithDivision: Division;
    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    public sharedWithAgencyId?: number;

    public sharedWithAgency: Agency;
    public entityType: string;
    public entityId: string;
    public sharedDate: string;
    public negate: boolean;

    public constructor(init?: Partial<Share>) { (Object as any).assign(this, init); }
}

export class SpeechOptions
{
    public textType: string;
    public voiceId: string;
    public engine: string;

    public constructor(init?: Partial<SpeechOptions>) { (Object as any).assign(this, init); }
}

export class StateHostOperationsResponse
{
    public stateId: number;
    public name: string;
    public abbreviation: string;
    public stateSwitchStatus: string;

    public constructor(init?: Partial<StateHostOperationsResponse>) { (Object as any).assign(this, init); }
}

export class StateResponseLogResponse
{
    public id: string;
    public stateRequestLogId?: string;
    public responseType: string;
    public intInterfaceName: string;
    public intSystemName: string;
    public class: string;
    public expirationDate?: string;
    public wasExpired: boolean;
    public transaction: string;
    public processingFlag: string;
    public mocked: boolean;
    public internalResponseType: string;
    public createdAt?: string;

    public constructor(init?: Partial<StateResponseLogResponse>) { (Object as any).assign(this, init); }
}

export class SearchStateRequestLogsResponse
{
    public id: string;
    public userId?: number;
    public userName: string;
    public messageType: string;
    public dateSubmitted: string;
    public transaction: string;
    public queryId?: string;
    public investigationQueryInvestigationId?: string;
    public investigationQueryIdentificationNumber: string;
    public investigationQueryFirstName: string;
    public investigationQueryLastName: string;
    public investigationQueryDateOfBirth?: string;
    public investigationQueryDenomination?: number;
    public investigationQueryRace: string;
    public investigationQuerySex: string;
    public investigationQueryStateId?: number;
    public investigationQuerySubjectPacketIdentificationTypeId?: number;
    public userFullName: string;
    public userEmail: string;
    public agencyName: string;
    public responses: StateResponseLogResponse[];
    public maskedSSN: string;

    public constructor(init?: Partial<SearchStateRequestLogsResponse>) { (Object as any).assign(this, init); }
}

export class VehicleMonitoring implements IAuditable
{
    public id: string;
    public title: string;
    public location: string;
    public licensePlateNumber: string;
    // @References("typeof(LENSSX.ServiceModel.Types.State)")
    public stateId: number;

    public state: State;
    public createdBy: number;
    public updatedBy: number;
    public createdAt: string;
    public updatedAt: string;

    public constructor(init?: Partial<VehicleMonitoring>) { (Object as any).assign(this, init); }
}

export class AgencyDefaultSubjectNotification
{
    // @Required()
    public id: string;

    // @References("typeof(LENSSX.ServiceModel.Types.Agency)")
    // @Required()
    public agencyId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacketClassification)")
    // @Required()
    public classificationId: number;

    // @References("typeof(LENSSX.ServiceModel.Types.SubjectType)")
    // @Required()
    public subjectTypeId: number;

    public constructor(init?: Partial<AgencyDefaultSubjectNotification>) { (Object as any).assign(this, init); }
}

export class DefaultNotificationResponse
{
    public subjectTypeId: number;
    public classificationId: number;

    public constructor(init?: Partial<DefaultNotificationResponse>) { (Object as any).assign(this, init); }
}

export class AutocompleteLookupResult
{
    public street: string;
    public secondary: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public entries: string;

    public constructor(init?: Partial<AutocompleteLookupResult>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class MoneyResponse
{
    public id: string;
    public utn: string;
    public serialNumber: string;
    public denomination: number;
    public createdAt: string;
    public washed: string;
    public washedDate?: string;
    public hasLinkage: boolean;

    public constructor(init?: Partial<MoneyResponse>) { (Object as any).assign(this, init); }
}

export class PrivatePacketDivisions
{
    public id: number;
    public name: string;

    public constructor(init?: Partial<PrivatePacketDivisions>) { (Object as any).assign(this, init); }
}

export class BillWashSummaryResponse
{
    public id: string;
    public name: string;
    public agency: string;
    public phone: string;
    public date: string;
    public caseNumber: string;

    public constructor(init?: Partial<BillWashSummaryResponse>) { (Object as any).assign(this, init); }
}

export class SegregatedBill
{
    public location: number;
    public serialNumber: string;
    public denomination: number;
    public hitBatchType: string;
    public hitAgencyName: string;
    public hitUserName: string;
    public hitContactPhone: string;
    public agencyReferenceNumber: string;

    public constructor(init?: Partial<SegregatedBill>) { (Object as any).assign(this, init); }
}

export class DivisionUser
{
    public id: number;
    public fullName: string;

    public constructor(init?: Partial<DivisionUser>) { (Object as any).assign(this, init); }
}

export class UserDivision
{
    public id: number;
    public name: string;

    public constructor(init?: Partial<UserDivision>) { (Object as any).assign(this, init); }
}

export class AnnouncementEnvironmentResponse
{
    public url: string;
    public apiSecretKey: string;

    public constructor(init?: Partial<AnnouncementEnvironmentResponse>) { (Object as any).assign(this, init); }
}

export interface IAmIdentifiable
{
    firstName: string;
    lastName: string;
    stateId?: number;
    driversLicenseNumber: string;
    race: string;
    sex: string;
    middleName: string;
    sid: string;
    dateOfBirth?: string;
}

export class IntelUserDto
{
    public userId: number;
    public userName: string;

    public constructor(init?: Partial<IntelUserDto>) { (Object as any).assign(this, init); }
}

export class IntelSubscriptionDto
{
    public id: string;
    public intelId: string;
    public userId?: number;
    public userName: string;
    public divisionId?: number;
    public divisionName: string;
    public enabled: boolean;

    public constructor(init?: Partial<IntelSubscriptionDto>) { (Object as any).assign(this, init); }
}

export class PrivateDivisions
{
    public id: number;
    public name: string;

    public constructor(init?: Partial<PrivateDivisions>) { (Object as any).assign(this, init); }
}

export class CondensedInvestigationResponse
{
    public id: string;
    public investigationTypeName: string;
    public startTime: string;
    public stopTime?: string;
    public dispositions: string[];
    public createdByName: string;
    public createdByAgencyName: string;
    public createdByEmail: string;
    public createdByPhone: string;
    public dispatchNotes: string;
    public notes: string;

    public constructor(init?: Partial<CondensedInvestigationResponse>) { (Object as any).assign(this, init); }
}

export class Hits
{
    public investigations: number;
    public subjectPackets: number;
    public intel: number;
    public currency: number;

    public constructor(init?: Partial<Hits>) { (Object as any).assign(this, init); }
}

export class ProtectionOrder
{
    public protectedPersonBirthDate?: string;
    public protectedPersonName: string;
    public protectedPersonRaceCode: string;
    public protectedPersonSexCode: string;
    public protectiveOrderCondition: string;
    public protectedPersonFirstName: string;
    public protectedPersonLastName: string;
    public protectedPersonMiddleName: string;

    public constructor(init?: Partial<ProtectionOrder>) { (Object as any).assign(this, init); }
}

export class Person
{
    public name: string;
    public operatorLicenseNumber: string;
    public operatorLicenseClassCode: string;
    public licenseStatusName: string;
    public birthDate?: string;
    public raceCode: string;
    public sexCode: string;
    public eyeColorCode: string;
    public hairColorCode: string;
    public height: string;
    public weight: string;
    public isProtectionOrder: boolean;
    public isOnProbation: boolean;
    public isSexualPredator: boolean;
    public isSexOffender: boolean;
    public isViolentGangOrTerrorist: boolean;
    public isWanted: boolean;
    public isImmigrantViolator: boolean;
    public isMissingPerson: boolean;
    public address: string;
    public addressCity: string;
    public addressStreet: string;
    public addressZipCode: string;
    public licenseExpirationDate?: string;
    public operatorLicenseStateCode: string;
    public operatorLicenseRestrictions: string;
    public alsoKnownAs: string;
    public birthPlaceCode: string;
    public stateIdentificationNumber: string;
    public stateIdentificationNumberStateId?: number;
    public fbiNumber: string;
    public ncicNumber: string;
    public miscellaneousNumber: string;
    public offenseCode: string;
    public originatingAgencyCaseNumber: string;
    public originatingAgencyIdentifier: string;
    public scarMarkTattooCode: string;
    public socialSecurityNumber: string;
    public convictionDate?: string;
    public convictionRegistration: string;
    public freeText: string;
    public registrationStatusCode: string;
    public warrantDate?: string;
    public protectionOrder: ProtectionOrder;
    public licenseClassName: string;
    public firstName: string;
    public lastName: string;
    public middleName: string;
    public aliases: string;

    public constructor(init?: Partial<Person>) { (Object as any).assign(this, init); }
}

export class Vehicle
{
    public isStolenPlate: boolean;
    public isStolenVehicle: boolean;
    public isInsuranceConfirmed: boolean;
    public address: string;
    public addressCity: string;
    public addressStateCode: string;
    public addressStreet: string;
    public addressZipCode: string;
    public expirationDate?: string;
    public licensePlateNumber: string;
    public licensePlateStateCode: string;
    public licensePlateTypeCode: string;
    public licensePlateYear: string;
    public insuringEntity: string;
    public name: string;
    public operatorLicenseNumber: string;
    public policyNumber: string;
    public vehicleColorCode: string;
    public vehicleIdentificationNumber: string;
    public vehicleMakeCode: string;
    public vehicleModelCode: string;
    public vehicleStyleCode: string;
    public vehicleYear: string;
    public theftDate?: string;
    public vinDecodedMake: string;
    public vinDecodedModel: string;
    public vinDecodedTrim: string;
    public vinDecodedVehicleType: string;
    public hasCanceledPlate: boolean;

    public constructor(init?: Partial<Vehicle>) { (Object as any).assign(this, init); }
}

export class Gun
{
    public gunCaliber: string;
    public gunModel: string;
    public gunSerialNumber: string;
    public gunTypeCode: string;
    public theftDate?: string;

    public constructor(init?: Partial<Gun>) { (Object as any).assign(this, init); }
}

export class NameSearchPerson implements IAmIdentifiable
{
    public rawDateOfBirth: string;
    public dateOfBirth?: string;
    public sid: string;
    public nid: string;
    public firstName: string;
    public lastName: string;
    public socialSecurityNumber: string;
    public driversLicenseNumber: string;
    public driversLicenseState: string;
    public stateId?: number;
    public fbiNumber: string;
    public race: string;
    public sex: string;
    public middleName: string;
    public media: MediaDto;
    public address: string;
    public isExactMatch: boolean;

    public constructor(init?: Partial<NameSearchPerson>) { (Object as any).assign(this, init); }
}

export class CriminalHistoryContinueWithPerson
{
    public nameId: string;
    public dateOfBirth: string;
    public name: string;
    public race: string;
    public sex: string;
    public yearOfBirth: string;
    public purpose: string;

    public constructor(init?: Partial<CriminalHistoryContinueWithPerson>) { (Object as any).assign(this, init); }
}

export class CandidateList
{
    public people: NameSearchPerson[];
    public continueWithPerson: CriminalHistoryContinueWithPerson;
    public hasMore: boolean;

    public constructor(init?: Partial<CandidateList>) { (Object as any).assign(this, init); }
}

export class NcicDataResponse
{
    public id: string;
    public queryId?: string;
    public responseId: string;
    public stateHostRequestId?: string;
    public media: MediaDto[];
    public expirationDate?: string;
    public wasExpired: boolean;
    public expirationDateFormatted: string;
    public rawMessageData: string;
    public responseClass: string;
    public responseType: string;
    public internalResponseType: string;
    public systemName: string;
    public interfaceName: string;
    public title: string;
    public banner: string;
    public canParseIntoPacket: boolean;
    public canParseIntoIntel: boolean;
    public processingFlag: string;
    public person: Person;
    public vehicle: Vehicle;
    public gun: Gun;
    public candidateList: CandidateList;
    public transformation: string;
    public isSupplementary: boolean;
    public source: string;

    public constructor(init?: Partial<NcicDataResponse>) { (Object as any).assign(this, init); }
}

export class Region
{
    public code: string;

    public constructor(init?: Partial<Region>) { (Object as any).assign(this, init); }
}

export class Results
{
    public plate: string;
    public region: Region;

    public constructor(init?: Partial<Results>) { (Object as any).assign(this, init); }
}

export class UnreadConversation
{
    public totalUnreadMessages: number;
    public conversationId: string;

    public constructor(init?: Partial<UnreadConversation>) { (Object as any).assign(this, init); }
}

export class ParticipantResponse
{
    public participantId: number;
    public name: string;
    public participantType: string;
    public isOnline?: boolean;
    public agencyName: string;
    public agencyId: number;
    public conversationId: string;
    public deleted: boolean;
    public deletedAt?: string;

    public constructor(init?: Partial<ParticipantResponse>) { (Object as any).assign(this, init); }
}

export class UsersAndDivisionsResponse
{
    public id: number;
    public name: string;
    public participantType: string;

    public constructor(init?: Partial<UsersAndDivisionsResponse>) { (Object as any).assign(this, init); }
}

export class PhotoLineupDatasetMediaDto
{
    public id: number;
    public url: string;
    public contentType: string;
    public fileName: string;
    public localFileName: string;
    public parentId: string;
    public parentTypeId: number;
    public createdBy: number;
    public agencyId: number;
    public shA256Hash: string;
    public isNCICImage: boolean;

    public constructor(init?: Partial<PhotoLineupDatasetMediaDto>) { (Object as any).assign(this, init); }
}

export class MetaDataDto
{
    public id: string;
    public race: string;
    public sex: string;
    public build: string;
    public eyeColor: string;
    public eyeSight: string;
    public hairStyle: string;
    public hairColor: string;
    public beard: string;
    public mustache: string;
    public birthYear?: number;
    public weight?: number;
    public height?: number;
    public name: string;
    public driverLicenseNumber: string;
    public birthDate?: string;

    public constructor(init?: Partial<MetaDataDto>) { (Object as any).assign(this, init); }
}

export class PhotoLineupMediaDto
{
    public id: number;
    public url: string;
    public contentType: string;
    public fileName: string;
    public localFileName: string;
    public parentId: string;
    public parentTypeId: number;
    public createdBy: number;
    public agencyId: number;
    public shA256Hash: string;
    public metaData: MetaDataDto;

    public constructor(init?: Partial<PhotoLineupMediaDto>) { (Object as any).assign(this, init); }
}

export class PhotoLineupMetadataResponse
{
    public id: string;
    public race: string;
    public sex: string;
    public build: string;
    public eyeColor: string;
    public eyeSight: string;
    public hairStyle: string;
    public hairColor: string;
    public mustache: string;
    public beard: string;
    public weight?: number;
    public height?: number;
    public birthYear?: number;
    public createdBy?: number;
    public updatedBy?: number;
    public createdAt: string;
    public updatedAt: string;
    public media: PhotoLineupDatasetMediaDto;
    public mediaId: number;
    public name: string;
    public driverLicenseNumber: string;

    public constructor(init?: Partial<PhotoLineupMetadataResponse>) { (Object as any).assign(this, init); }
}

export interface IMessageHandlerStats
{
    name: string;
    totalMessagesProcessed: number;
    totalMessagesFailed: number;
    totalRetries: number;
    totalNormalMessagesReceived: number;
    totalPriorityMessagesReceived: number;
    lastMessageProcessed?: string;
}

export class UserPermissionAssignment
{
    public permissionName: string;
    public deny: boolean;

    public constructor(init?: Partial<UserPermissionAssignment>) { (Object as any).assign(this, init); }
}

export class TimeZone
{
    public id: string;
    public displayName: string;

    public constructor(init?: Partial<TimeZone>) { (Object as any).assign(this, init); }
}

export class AccountState
{
    // @Required()
    public id: number;

    public name: string;

    public constructor(init?: Partial<AccountState>) { (Object as any).assign(this, init); }
}

export class AgencyType
{
    public name: string;
    // @Required()
    public id: number;

    public constructor(init?: Partial<AgencyType>) { (Object as any).assign(this, init); }
}

export class EntityType
{
    // @Required()
    public id: number;

    public name: string;

    public constructor(init?: Partial<EntityType>) { (Object as any).assign(this, init); }
}

export class IdentificationTypeResponse
{
    public id: number;
    public name: string;
    public requiresState: boolean;

    public constructor(init?: Partial<IdentificationTypeResponse>) { (Object as any).assign(this, init); }
}

export class MediaParentType
{
    // @Required()
    public id: number;

    public name: string;
    public relativePath: string;

    public constructor(init?: Partial<MediaParentType>) { (Object as any).assign(this, init); }
}

export class NotificationType
{
    // @Required()
    public id: number;

    public name: string;

    public constructor(init?: Partial<NotificationType>) { (Object as any).assign(this, init); }
}

export class EntityState
{
    // @Required()
    public id: number;

    public name: string;

    public constructor(init?: Partial<EntityState>) { (Object as any).assign(this, init); }
}

export class SubjectPacketClassification
{
    public name: string;
    // @Required()
    public id: number;

    public constructor(init?: Partial<SubjectPacketClassification>) { (Object as any).assign(this, init); }
}

export class SubjectType
{
    public name: string;
    // @References("typeof(LENSSX.ServiceModel.Types.SubjectPacketType)")
    // @Required()
    public subjectPacketTypeId: number;

    // @Required()
    public id: number;

    // @Required()
    public deleted: boolean;

    public constructor(init?: Partial<SubjectType>) { (Object as any).assign(this, init); }
}

export interface IAuditable
{
    createdBy: number;
    updatedBy: number;
    createdAt: string;
    updatedAt: string;
}

export interface IHaveUtn
{
    utn: string;
}

export class StreetAddressResult
{
    public fullAddress: string;
    public state: string;

    public constructor(init?: Partial<StreetAddressResult>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryResponse<T>
{
    // @DataMember(Order=1)
    public offset: number;

    // @DataMember(Order=2)
    public total: number;

    // @DataMember(Order=3)
    public results: T[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    // @DataMember(Order=5)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QueryResponse<T>>) { (Object as any).assign(this, init); }
}

export class OriResponse
{
    public id: string;
    public ori: string;
    public ldi: string;
    public agencyId: number;
    public agencyName: string;
    public userId?: number;
    public userFirstName: string;
    public userLastName: string;
    public userFullName: string;

    public constructor(init?: Partial<OriResponse>) { (Object as any).assign(this, init); }
}

export class AgencyResponse
{
    public name: string;
    public agencyContractsSeats: number;
    public isActive: boolean;
    public agencyContractsStartDate?: string;
    public agencyContractsEndDate?: string;
    public stateAbbreviation: string;
    public id: number;
    public totalUsers: number;
    public agencySettingsLatitude: number;
    public agencySettingsLongitude: number;
    public agencyTypeShortcode: string;
    public timezone: string;
    public primaryAddress1: string;
    public primaryAddress2: string;
    public primaryCity: string;
    public primaryStateId?: number;
    public primaryZipcode: string;
    public primaryCountyParish: string;
    public primaryContactName: string;
    public primaryContactPhone: string;
    public secondaryAddress1: string;
    public secondaryAddress2: string;
    public secondaryCity: string;
    public secondaryStateId?: number;
    public secondaryZipcode: string;
    public secondaryCountyParish: string;
    public secondaryContactName: string;
    public secondaryContactPhone: string;
    public ori: string;
    public ldi: string;
    public deactivatedUserNotificationPhoneNumber: string;
    public deactivatedUserNotificationEmail: string;
    public agencyTypeId: number;
    public settings: AgencySettings;
    public contract: Contract;
    public permissionGroups: string[];
    public ncicSeatCount?: number;
    public districtAttorney?: number;
    public isValid: boolean;
    public refactorConverted: boolean;
    public notes: string;

    public constructor(init?: Partial<AgencyResponse>) { (Object as any).assign(this, init); }
}

export class AlertResponse
{
    public id: string;
    public agencyId?: number;
    public agencyName: string;
    public areaId?: number;
    public areaName: string;
    public divisionId?: number;
    public divisionName: string;
    public title: string;
    public alertContent: string;
    public createdBy: number;
    public createdByName: string;
    public createdByPhone: string;
    public createdByEmail: string;
    public createdAt: string;
    public updatedAt: string;
    public utn: string;
    public agencies: number[];
    public divisions: number[];
    public investigationId?: string;
    public intelId?: string;
    public subjectPacketId?: string;
    public sharedWithMe: boolean;
    public deactivated: boolean;

    public constructor(init?: Partial<AlertResponse>) { (Object as any).assign(this, init); }
}

export class CommSysReportResponse
{
    public agencyName: string;
    public agencyId: number;
    public year: number;
    public month: string;
    public userCount: number;
    public userEmail: string;

    public constructor(init?: Partial<CommSysReportResponse>) { (Object as any).assign(this, init); }
}

export class FaceDto implements IAmIdentifiable
{
    public id: string;
    public faceServiceId: string;
    public fromService: string;
    public confidence: number;
    public media: MediaDto;
    public bounds: Bounds;
    public metadata: Object;
    public createdAt: string;
    public similarity: number;
    public collection: string;
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public dateOfBirth?: string;
    public sex: string;
    public race: string;
    public socialSecurityNumber: string;
    public driversLicenseNumber: string;
    public stateId?: number;
    public sid: string;

    public constructor(init?: Partial<FaceDto>) { (Object as any).assign(this, init); }
}

export class IntelResponse
{
    public id: string;
    public isPrivate: boolean;
    public intelTypeId: number;
    public developmentStatusId: number;
    public developmentStatusName: string;
    public intelTypeName: string;
    public agencyId: number;
    public agencyName: string;
    public userSubjectPacketQueryBatchId?: string;
    public reportNumber: string;
    public userEnteredDateTime?: string;
    public title: string;
    public district: string;
    public reasonForStop: string;
    public utn: string;
    public createdBy: number;
    public assignedToName: string;
    public assignedToPhone: string;
    public assignedToEmail: string;
    public createdAt: string;
    public updatedAt: string;
    public deleted: boolean;
    public assignedTo: number;
    public vehicles: IntelVehicleDto[];
    public subjects: IntelSubjectDto[];
    public tattoos: IntelTattooDto[];
    public addresses: IntelAddressDto[];
    public socialMediaProfiles: IntelSocialMediaProfileDto[];
    public users: IntelUserDto[];
    public subscriptions: IntelSubscriptionDto[];
    public media: MediaDto[];
    public face: FaceDto;
    public derivedFromInvestigationId?: string;
    public sharedWithMe: boolean;
    public intelStateId: number;
    public intelStateName: string;
    public divisions: PrivateDivisions[];

    public constructor(init?: Partial<IntelResponse>) { (Object as any).assign(this, init); }
}

// @Route("/api/Intel", "POST")
export class CreateIntel implements IReturn<IntelResponse>
{
    /**
    * Type of Intel that this corresponds to. <a href="/swagger-ui/#!/api/IntelTypeRequest">See Intel types</a>.
    */
    // @ApiMember(DataType="int", Description="Type of Intel that this corresponds to. <a href=\"/swagger-ui/#!/api/IntelTypeRequest\">See Intel types</a>.")
    public intelTypeId: number;

    /**
    * Investigation identification
    */
    // @ApiMember(DataType="GUID", Description="Investigation identification")
    public userSubjectPacketQueryBatchId?: string;

    /**
    * Report identification number
    */
    // @ApiMember(DataType="string", Description="Report identification number")
    public reportNumber: string;

    /**
    * User entered date and time of entry
    */
    // @ApiMember(DataType="DateTime", Description="User entered date and time of entry")
    public userEnteredDateTime?: string;

    /**
    * Title of the Intel
    */
    // @ApiMember(DataType="string", Description="Title of the Intel")
    public title: string;

    /**
    * Notes related to the Intel
    */
    // @ApiMember(DataType="string", Description="Notes related to the Intel")
    public notes: string;

    // @ApiMember(DataType="string")
    public district: string;

    /**
    * 
    */
    // @ApiMember(DataType="string", Description="")
    public reasonForStop: string;

    /**
    * User id of the person the Intel is assigned to
    */
    // @ApiMember(DataType="int", Description="User id of the person the Intel is assigned to")
    public assignedTo?: number;

    /**
    * List of vehicles associated with Intel. At least one field must be populated.
    */
    // @ApiMember(DataType="List of IntelVehicleDto", Description="List of vehicles associated with Intel. At least one field must be populated.")
    public vehicles: IntelVehicleDto[];

    /**
    * List of subjects associated with Intel. At least one field must be populated.
    */
    // @ApiMember(DataType="List of IntelSubjectDto", Description="List of subjects associated with Intel. At least one field must be populated.")
    public subjects: IntelSubjectDto[];

    /**
    * List of tattoos associated with Intel. At least one field must be populated.
    */
    // @ApiMember(DataType="List of IntelTattooDto", Description="List of tattoos associated with Intel. At least one field must be populated.")
    public tattoos: IntelTattooDto[];

    /**
    * List of addresses associated with Intel. At least one field must be populated.
    */
    // @ApiMember(DataType="List of IntelAddressDto", Description="List of addresses associated with Intel. At least one field must be populated.")
    public addresses: IntelAddressDto[];

    /**
    * List of SocialMediaProfiles associated with Intel. At least one field must be populated.
    */
    // @ApiMember(DataType="List of IntelSocialMediaProfileDto", Description="List of SocialMediaProfiles associated with Intel. At least one field must be populated.")
    public socialMediaProfiles: IntelSocialMediaProfileDto[];

    /**
    * Users that will be subscribed to updates
    */
    // @ApiMember(DataType="List of int", Description="Users that will be subscribed to updates")
    public usersToSubscribe: number[];

    /**
    * Divisions that will be subscribed to updates
    */
    // @ApiMember(DataType="List of int", Description="Divisions that will be subscribed to updates")
    public divisionsToSubscribe: number[];

    /**
    * Users that were on site when gathering this Intel
    */
    // @ApiMember(DataType="List of int", Description="Users that were on site when gathering this Intel")
    public onsiteUsers: number[];

    /**
    * Other agencies that we want to share with
    */
    // @ApiMember(DataType="List of int", Description="Other agencies that we want to share with")
    public agenciesToShareWith: number[];

    /**
    * Media associated with this Intel
    */
    // @ApiMember(DataType="List", Description="Media associated with this Intel")
    public media: number[];

    public derivedFromInvestigationId?: string;
    /**
    * The Development Status id (1 = Complete, 2 = In Development). Default: 1
    */
    // @ApiMember(Description="The Development Status id (1 = Complete, 2 = In Development). Default: 1")
    public developmentStatusId: number;

    public isPrivate: boolean;
    public divisions: number[];

    public constructor(init?: Partial<CreateIntel>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateIntel'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IntelResponse(); }
}

export class SubjectPacketResponse
{
    public id: string;
    public name: string;
    public subjectPacketTypeId: number;
    public subjectPacketTypeName: string;
    public subjectTypeId?: number;
    public subjectTypeName: string;
    public subjectPacketClassificationId?: number;
    public subjectClassificationName: string;
    public classificationAndType: string;
    public reportNumber: string;
    public publicNotes: string;
    public privateNotes: string;
    public identifications: IdentificationDto[];
    public identificationCount: number;
    public assignedTo: number;
    public createdByAgencyId: number;
    public createdByAgencyName: string;
    public createdBy: number;
    public assignedToName: string;
    public assignedToPhone: string;
    public assignedToEmail: string;
    public utn: string;
    public createdAt: string;
    public updatedAt: string;
    public reviewDate?: string;
    public subjectPacketStateId: number;
    public subjectPacketStateName: string;
    public stateChangeApprovedByUserId?: number;
    public stateChangeApprovedByUserCombinedName: string;
    public stateChangeApprovedAt?: string;
    public deactivationReason: string;
    public developmentStatusId: number;
    public developmentStatusName: string;
    public derivedFromInvestigationId?: string;
    public sharedWithMe: boolean;
    public isJuvenile: boolean;
    public isPrivate: boolean;
    public privateDivisions: PrivatePacketDivisions[];
    public firstName: string;
    public lastName: string;
    public middleInitial: string;
    public dateOfBirth?: string;
    public sex: string;
    public race: string;
    public locationAddress: string;
    public locationDescription: string;

    public constructor(init?: Partial<SubjectPacketResponse>) { (Object as any).assign(this, init); }
}

// @Route("/api/SubjectPackets", "POST")
export class CreateSubjectPacket implements IReturn<SubjectPacketResponse>
{
    /**
    * Name for the Subject Packet, IE 'Market Street Gang'
    */
    // @ApiMember(Description="Name for the Subject Packet, IE 'Market Street Gang'")
    public name: string;

    /**
    * The Subject Type id (Burglar, Drug Dealer, etc...)
    */
    // @ApiMember(Description="The Subject Type id (Burglar, Drug Dealer, etc...)")
    public subjectTypeId?: number;

    /**
    * The Subject Classification id (Confirmed or Suspected)
    */
    // @ApiMember(Description="The Subject Classification id (Confirmed or Suspected)")
    public subjectPacketClassificationId?: number;

    /**
    * A report number from an external system
    */
    // @ApiMember(Description="A report number from an external system")
    public reportNumber: string;

    /**
    * Public notes for this Packet
    */
    // @ApiMember(Description="Public notes for this Packet")
    public publicNotes: string;

    /**
    * Private notes for this Packet (only the creator can see these)
    */
    // @ApiMember(Description="Private notes for this Packet (only the creator can see these)")
    public privateNotes: string;

    /**
    * List of Identifications related to this Subject Packet.
    */
    // @ApiMember(DataType="List", Description="List of Identifications related to this Subject Packet.")
    public identifications: IdentificationDto[];

    /**
    * List of Division ids related to this Subject Packet.
    */
    // @ApiMember(DataType="List", Description="List of Division ids related to this Subject Packet.")
    public divisions: number[];

    /**
    * List of user ids related to this Subject Packet.
    */
    // @ApiMember(DataType="List", Description="List of user ids related to this Subject Packet.")
    public users: number[];

    /**
    * Review date of the Subject Packet.
    */
    // @ApiMember(DataType="DateTime", Description="Review date of the Subject Packet.")
    public reviewDate?: string;

    /**
    * User id assigned to the Subject Packet.
    */
    // @ApiMember(DataType="int", Description="User id assigned to the Subject Packet.")
    public assignedTo?: number;

    public media: number[];
    /**
    * The Development Status id (1 = Complete, 2 = In Development). Default: 1
    */
    // @ApiMember(Description="The Development Status id (1 = Complete, 2 = In Development). Default: 1")
    public developmentStatusId: number;

    public derivedFromInvestigationId?: string;
    public derivedFromPacketId?: string;
    public isJuvenile: boolean;
    public tattooColor: string;
    public tattooDescription: string;
    public tattooAffiliations: string;
    public tattooLocationId?: number;
    public isPrivate: boolean;
    public privateDivisions: number[];
    public firstName: string;
    public lastName: string;
    public middleInitial: string;
    public dateOfBirth?: string;
    public sex: string;
    public race: string;
    public locationAddress: string;
    public locationDescription: string;

    public constructor(init?: Partial<CreateSubjectPacket>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateSubjectPacket'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SubjectPacketResponse(); }
}

// @Route("/api/Alerts", "POST")
export class CreateAlert implements IReturn<AlertResponse>
{
    /**
    * Title of the Investigative Alert
    */
    // @ApiMember(DataType="string", Description="Title of the Investigative Alert", IsRequired=true)
    public title: string;

    /**
    * Description of the Alert
    */
    // @ApiMember(DataType="string", Description="Description of the Alert", IsRequired=true)
    public alertContent: string;

    /**
    * Agencies that will be subscribed to the Alert
    */
    // @ApiMember(DataType="int[]", Description="Agencies that will be subscribed to the Alert")
    public agenciesToSubscribe: number[];

    /**
    * Divisions that will be subscribed to the Alert
    */
    // @ApiMember(DataType="int[]", Description="Divisions that will be subscribed to the Alert")
    public divisionsToSubscribe: number[];

    /**
    * Users that will be subscribed to the Alert
    */
    // @ApiMember(DataType="int[]", Description="Users that will be subscribed to the Alert")
    public usersToSubscribe: number[];

    /**
    * The Investigation this alert is tied to
    */
    // @ApiMember(Description="The Investigation this alert is tied to")
    public investigationId?: string;

    /**
    * The Intel this alert is tied to
    */
    // @ApiMember(Description="The Intel this alert is tied to")
    public intelId?: string;

    /**
    * The Packet this alert is tied to
    */
    // @ApiMember(Description="The Packet this alert is tied to")
    public subjectPacketId?: string;

    /**
    * The Packet this alert is tied to
    */
    // @ApiMember(Description="The Packet this alert is tied to")
    public media: number[];

    public constructor(init?: Partial<CreateAlert>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateAlert'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AlertResponse(); }
}

export class CreateFromNcicSummaryResult
{
    public intel: CreateIntel;
    public packet: CreateSubjectPacket;
    public alert: CreateAlert;

    public constructor(init?: Partial<CreateFromNcicSummaryResult>) { (Object as any).assign(this, init); }
}

export class CurrencyResponse extends SubjectPacketResponse
{
    public classificationTypeId?: number;
    public classificationTypeName: string;
    public sum: number;
    public totalBillCount: number;
    public monies: MoneyResponse[];
    public isPublic?: boolean;
    public wasFileUpload: boolean;
    public processing: boolean;
    public processingFlag: string;

    public constructor(init?: Partial<CurrencyResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DivisionResponse
{
    public id: number;
    public name: string;
    public agencyId: number;
    public agencyName: string;
    public userCount: number;
    public classificationCount: number;
    public assignedUsers: DivisionUser[];

    public constructor(init?: Partial<DivisionResponse>) { (Object as any).assign(this, init); }
}

export class UserDivisionsResponse
{
    public userId: number;
    public divisions: UserDivision[];

    public constructor(init?: Partial<UserDivisionsResponse>) { (Object as any).assign(this, init); }
}

export class EnvironmentResponse
{
    public version: string;
    public environmentName: string;
    public googleApiKey: string;
    public decodeThisApiKey: string;
    public smartyStreetsApiKey: string;
    public systemFeatures: string[];
    public sentryDsn: string;
    public voicePlaybackRate: number;
    public announcementEnvironment: AnnouncementEnvironmentResponse;

    public constructor(init?: Partial<EnvironmentResponse>) { (Object as any).assign(this, init); }
}

export class DetectFacesResponse
{
    public detectedFaces: FaceDto[];

    public constructor(init?: Partial<DetectFacesResponse>) { (Object as any).assign(this, init); }
}

export class InvestigationQueryDto
{
    public id: string;
    public userId: number;
    public userName: string;
    public userEmail: string;
    public userPhone: string;
    public queryDate: string;
    public utn: string;
    public identificationNumber: string;
    public subjectPacketIdentificationTypeId: number;
    public subjectPacketIdentificationTypeName: string;
    public subjectPacketTypeId: number;
    public subjectPacketTypeName: string;
    public investigationId?: string;
    public stateId?: number;
    public stateName: string;
    public stateAbbreviation: string;
    public createdForUserId?: number;
    public createdForUserName: string;
    public denomination?: number;
    public lastName: string;
    public firstName: string;
    public middleInitial: string;
    public dateOfBirth?: string;
    public race: string;
    public sex: string;
    public aka: string;
    public maskedSSN: string;
    public tattooDescription: string;
    public tattooColors: string;
    public tattooAffiliations: string;
    public title: string;
    public hits: Hits;
    public ncicResponses: NcicDataResponse[];
    public pendingStateResponse: boolean;
    public processingFlag: string;
    public sentStateRequest?: boolean;
    public related?: boolean;
    public media: MediaDto[];
    public profileUrl: string;
    public profileDescription: string;
    public profileTypeId?: number;
    public entitiesToUpdate: string[];

    public constructor(init?: Partial<InvestigationQueryDto>) { (Object as any).assign(this, init); }
}

export class InvestigationResponse
{
    public id: string;
    public investigationTypeId: number;
    public investigationTypeName: string;
    public createdForId?: number;
    public createdForName: string;
    public createdForEmail: string;
    public createdForPhone: string;
    public agency: string;
    public startTime: string;
    public stopTime?: string;
    public resultedInSiezure: boolean;
    public createdBy: number;
    public createdByName: string;
    public createdByEmail: string;
    public createdByPhone: string;
    public queryCount: number;
    public hitCount: number;
    public createdAt: string;
    public updatedAt: string;
    public notes: string;
    public utn: string;
    public caseNumber: string;
    public investigationStateId: number;
    public investigationStateName: string;
    public dispatchNotes: string;
    public dispositions: InvestigationDispositionDto[];
    public queries: InvestigationQueryDto[];
    public media: number[];
    public associatedAlertId?: string;
    public associatedPacketId?: string;
    public associatedIntelId?: string;
    public sharedWithMe: boolean;
    public completionReason: string;
    public fullQuickTagInvestigation: boolean;

    public constructor(init?: Partial<InvestigationResponse>) { (Object as any).assign(this, init); }
}

export class InvestigationIdResponse
{
    public id: string;
    public investigationTypeId: number;

    public constructor(init?: Partial<InvestigationIdResponse>) { (Object as any).assign(this, init); }
}

export class LPRResponse
{
    public results: Results[];
    public success: boolean;

    public constructor(init?: Partial<LPRResponse>) { (Object as any).assign(this, init); }
}

export class CheckLicensePlateResponse
{
    public match: boolean;

    public constructor(init?: Partial<CheckLicensePlateResponse>) { (Object as any).assign(this, init); }
}

export class MediaStoreResponse
{
    public id?: string;
    public url: string;
    public fileName: string;
    public contentType: string;
    public createdBy: number;
    public createdAt: string;
    public sizeInBytes: number;
    public userId: number;
    public localFileName: string;
    public expirationDate?: string;

    public constructor(init?: Partial<MediaStoreResponse>) { (Object as any).assign(this, init); }
}

export class GetUnreadMessagesResponse
{
    public conversations: UnreadConversation[];

    public constructor(init?: Partial<GetUnreadMessagesResponse>) { (Object as any).assign(this, init); }
}

export class MessageResponse
{
    public id: string;
    public content: string;
    public media: MediaDto[];
    public createdBy: number;
    public createdByName: string;
    public createdByAgencyId: number;
    public createdByAgencyName: string;
    public updatedBy: number;
    public clientCreatedAt: string;
    public updatedAt: string;
    public deleted: boolean;
    public conversationId: string;
    public isParticipantOperationMessage: boolean;
    public isNotificationEnabled: boolean;
    public isDeleteOperation: boolean;
    public messageDeleteResponse: boolean;
    public messageToUndoResponse: boolean;
    public partialDeleteResponse: boolean;

    public constructor(init?: Partial<MessageResponse>) { (Object as any).assign(this, init); }
}

export class ConversationResponse
{
    public id: string;
    public latestMessage: MessageResponse;
    public participants: ParticipantResponse[];
    public createdBy: number;
    public createdAt: string;
    public updatedAt: string;
    public channel: string;
    public totalUnreadMessages: number;
    public lastMessageSentAt?: string;
    public newParticipants: ParticipantResponse[];
    public isExistingConversation: boolean;
    public isNotificationEnabled: boolean;
    public isParticipantPresent: boolean;

    public constructor(init?: Partial<ConversationResponse>) { (Object as any).assign(this, init); }
}

export class NoteResponse
{
    public id: string;
    public note: string;
    public userId: number;
    public userFirstName: string;
    public userLastName: string;
    public date: string;
    public entityId: string;
    public fullName: string;

    public constructor(init?: Partial<NoteResponse>) { (Object as any).assign(this, init); }
}

export class NotificationSettingDto
{
    public id?: string;
    public userId: number;
    public notificationType: string;
    public notificationMechanism: string;
    public enabled: boolean;

    public constructor(init?: Partial<NotificationSettingDto>) { (Object as any).assign(this, init); }
}

export class PermissionGroupResponse
{
    public id: string;
    public name: string;
    public systemManaged: boolean;
    public permissions: string[];
    public hasUsers?: boolean;
    public userCount: number;

    public constructor(init?: Partial<PermissionGroupResponse>) { (Object as any).assign(this, init); }
}

export class PhotoLineupDatatsetResult
{
    public id: string;
    public race: string;
    public sex: string;
    public build: string;
    public eyeColor: string;
    public eyeSight: string;
    public hairStyle: string;
    public hairColor: string;
    public mustache: string;
    public beard: string;
    public weight?: number;
    public height?: number;
    public birthYear?: number;
    public createdBy?: number;
    public updatedBy?: number;
    public createdAt: string;
    public updatedAt: string;
    public media: PhotoLineupDatasetMediaDto;
    public mediaId: number;
    public name: string;
    public driverLicenseNumber: string;

    public constructor(init?: Partial<PhotoLineupDatatsetResult>) { (Object as any).assign(this, init); }
}

export class PhotoLineupDatatsetResponse
{
    public results: PhotoLineupDatatsetResult[];
    public total: number;

    public constructor(init?: Partial<PhotoLineupDatatsetResponse>) { (Object as any).assign(this, init); }
}

export class PhotoLineupResponse
{
    public id: string;
    public title: string;
    public utn: string;
    public createdBy: number;
    public createdAt: string;
    public updatedAt: string;
    public media: PhotoLineupMediaDto[];
    public createdByName: string;
    public createdByPhone: string;
    public createdByEmail: string;
    public assignedTo: number;

    public constructor(init?: Partial<PhotoLineupResponse>) { (Object as any).assign(this, init); }
}

export class PhotoLineUpMediaResult
{
    public medias: PhotoLineupMediaDto[];
    public total: number;

    public constructor(init?: Partial<PhotoLineUpMediaResult>) { (Object as any).assign(this, init); }
}

export class CreateReportResponse
{
    public id: string;
    public expiresAt: string;

    public constructor(init?: Partial<CreateReportResponse>) { (Object as any).assign(this, init); }
}

export class SeizureResponse
{
    public id: string;
    public seizureTypeId?: number;
    public seizureTypeName: string;
    public caseNumber: string;
    public utn: string;
    public agencyId: number;
    public agencyName: string;
    public createdBy: number;
    public createdByName: string;
    public createdByPhone: string;
    public createdAt: string;
    public districtAttorneyAgencyId?: number;
    public districtAttorneyAgencyName: string;
    public subjectPacketId?: string;
    public sumOfSeizedItems: number;
    public countOfSeizedItems: number;
    public seizedItems: SeizedItemDto[];
    public media: number[];

    public constructor(init?: Partial<SeizureResponse>) { (Object as any).assign(this, init); }
}

export class ShareResponse
{
    public id: string;
    public sharedByUserId: number;
    public sharedByUserEmail: string;
    public sharedWithUserId?: number;
    public sharedWithUserName: string;
    public sharedWithUser: User;
    public sharedWithDivisionId?: number;
    public divisionName: string;
    public sharedWithAgencyId?: number;
    public agencyName: string;
    public entityType: string;
    public entityId: string;
    public sharedDate: string;

    public constructor(init?: Partial<ShareResponse>) { (Object as any).assign(this, init); }
}

export class SiteConfiguration
{
    // @Required()
    public id: number;

    // @StringLength(2147483647)
    // @Required()
    public homePageLogo: string;

    // @Required()
    public homePageLogoName: string;

    // @StringLength(2147483647)
    // @Required()
    public description: string;

    // @Required()
    public hideHelpLineNumber: boolean;

    public helpLineNumber: string;
    public facebookUrl: string;
    public twitterUrl: string;
    public linkedInUrl: string;
    public aboutSectionUrl: string;
    public supportButtonUrl: string;
    public supportUrl: string;
    public supportText: string;
    public jiraSupportKey: string;
    public jiraSupportBaseUrl: string;
    public jiraSupportWidgetSourceUrl: string;
    // @Ignore()
    public aboutSectionLinkEnabled: boolean;

    // @Ignore()
    public supportButtonLinkEnabled: boolean;

    // @Ignore()
    public supportLinkEnabled: boolean;

    // @Ignore()
    public jiraSupportEnabled: boolean;

    public constructor(init?: Partial<SiteConfiguration>) { (Object as any).assign(this, init); }
}

export class SpeakResponse
{
    public encodedAudio: string;

    public constructor(init?: Partial<SpeakResponse>) { (Object as any).assign(this, init); }
}

export class StateLogonApiResponse
{
    public logOnStatus: string;
    public statusText: string;
    public errorCode: string;
    public errorText: string;
    public expirationDate?: string;

    public constructor(init?: Partial<StateLogonApiResponse>) { (Object as any).assign(this, init); }
}

export class StateHostDetails
{
    public stateId: number;
    public name: string;
    public stateSwitchStatus: string;
    public messageHandlerStats: IMessageHandlerStats;
    public messageHandlerStatsDescription: string;
    public averageResponseTime: number;
    public maxmimumResponseTime: number;
    public requestsWithoutResponse: number;

    public constructor(init?: Partial<StateHostDetails>) { (Object as any).assign(this, init); }
}

export class StateRequestDetails
{
    public id: string;
    public userId?: number;
    public ori: string;
    public ldi: string;
    public userName: string;
    public messageType: string;
    public dateSubmitted: string;
    public transaction: Object;
    public queryId?: string;
    public replyToQ: string;
    public acknowledgedAt?: string;
    public siblingRequests: StateRequestDetails[];
    public investigationQuery: InvestigationQueryDto;
    public responses: StateResponseLogResponse[];

    public constructor(init?: Partial<StateRequestDetails>) { (Object as any).assign(this, init); }
}

export class StateResponseDetails
{
    public id: string;
    public media: MediaDto[];
    public parsedResponses: NcicDataResponse[];
    public exceptionParsingResponse: string;
    public canParseResponse: boolean;
    public transaction: Object;
    public request: StateRequestDetails;

    public constructor(init?: Partial<StateResponseDetails>) { (Object as any).assign(this, init); }
}

export class TermsOfServiceVersion
{
    // @Required()
    public id: number;

    public version: number;
    public dateCreated: string;
    // @StringLength(2147483647)
    public termsOfService: string;

    public constructor(init?: Partial<TermsOfServiceVersion>) { (Object as any).assign(this, init); }
}

export class CreateUsersResponse
{
    public errors: string[];
    public successes: string[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreateUsersResponse>) { (Object as any).assign(this, init); }
}

export class GetRegisteredUserResponse
{
    public accountState?: number;

    public constructor(init?: Partial<GetRegisteredUserResponse>) { (Object as any).assign(this, init); }
}

export class UserResponse
{
    public badgeNumber: string;
    public agencyId: number;
    public agencyName: string;
    public smsNotificationsEnabled: boolean;
    public emailNotificationsEnabled: boolean;
    public accountState: number;
    public accountStateName: string;
    public email: string;
    public phoneNumber: string;
    public userName: string;
    public isActive: boolean;
    public id: number;
    public firstName: string;
    public lastName: string;
    public userPermissions: UserPermissionAssignment[];
    public permissionGroups: string[];
    public divisions: string[];
    public deleted?: boolean;
    public stateUserName: string;
    public statePassword: string;
    public statePasswordExpirationDate?: string;
    public combinedName: string;
    public badgeName: string;
    public isLockedOut: boolean;

    public constructor(init?: Partial<UserResponse>) { (Object as any).assign(this, init); }
}

export class DeactivationChecksResponse
{
    public hasPackets: boolean;

    public constructor(init?: Partial<DeactivationChecksResponse>) { (Object as any).assign(this, init); }
}

export class GetBearerTokenResponse
{
    public bearerToken: string;
    public refreshToken: string;

    public constructor(init?: Partial<GetBearerTokenResponse>) { (Object as any).assign(this, init); }
}

export class NomNomNomCookiesResponse
{
    public bearerToken: string;
    public refreshToken: string;

    public constructor(init?: Partial<NomNomNomCookiesResponse>) { (Object as any).assign(this, init); }
}

export class GetUserMFAAuthenticatorSecretResponse
{
    public userId: number;
    public isNewUser: boolean;
    public userHasAuthenticator: boolean;
    public authenticatorResetFlag: boolean;
    public secretKey: string;

    public constructor(init?: Partial<GetUserMFAAuthenticatorSecretResponse>) { (Object as any).assign(this, init); }
}

export class GetUserIsAuthenticatorEnabledResponse
{
    public userHasAuthenticator: boolean;
    public authenticatorCodeResetFlag: boolean;

    public constructor(init?: Partial<GetUserIsAuthenticatorEnabledResponse>) { (Object as any).assign(this, init); }
}

export class ValidateMFAAuthenticatorCodeResponse
{
    public successful: boolean;

    public constructor(init?: Partial<ValidateMFAAuthenticatorCodeResponse>) { (Object as any).assign(this, init); }
}

export class DisableMFAAuthenticatorResponse
{
    public successful: boolean;

    public constructor(init?: Partial<DisableMFAAuthenticatorResponse>) { (Object as any).assign(this, init); }
}

export class GetUserSecretResponse
{
    public secretKey: string;

    public constructor(init?: Partial<GetUserSecretResponse>) { (Object as any).assign(this, init); }
}

export class VehicleMonitoringResponse
{
    public id: string;
    public title: string;
    public location: string;
    public stateId: number;
    public licensePlateNumber: string;
    public createdBy: number;
    public updatedBy: number;
    public createdAt: string;
    public updatedAt: string;

    public constructor(init?: Partial<VehicleMonitoringResponse>) { (Object as any).assign(this, init); }
}

export class DecodeVinResponse
{
    public make: string;
    public model: string;
    public year: string;
    public trim: string;
    public bodyStyle: string;
    public vin: string;
    public errorText: string;

    public constructor(init?: Partial<DecodeVinResponse>) { (Object as any).assign(this, init); }
}

export class StateResponse
{
    public results: State[];

    public constructor(init?: Partial<StateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @Route("/api/AddressLookup", "GET")
export class AddressLookup implements IReturn<StreetAddressResult[]>
{
    public streetAddress: string;

    public constructor(init?: Partial<AddressLookup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddressLookup'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<StreetAddressResult>(); }
}

// @Route("/api/AutocompleteLookup", "GET")
export class AutocompleteLookup implements IReturn<AutocompleteLookupResult[]>
{
    public streetAddress: string;

    public constructor(init?: Partial<AutocompleteLookup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AutocompleteLookup'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AutocompleteLookupResult>(); }
}

// @Route("/api/ValidateStreetAddress", "GET")
export class ValidateStreetAddress implements IReturn<StreetAddressResult>
{
    public streetAddress: string;

    public constructor(init?: Partial<ValidateStreetAddress>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ValidateStreetAddress'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StreetAddressResult(); }
}

// @Route("/api/Oris", "DELETE")
export class DeleteOris implements IReturnVoid
{
    public ids: string[];

    public constructor(init?: Partial<DeleteOris>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteOris'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api/Oris", "GET")
export class SearchOris extends QueryDb_2<AgencyOri, OriResponse> implements IReturn<QueryResponse<OriResponse>>
{
    public oriStartsWith: string;
    public ldiStartsWith: string;
    public agencyId?: number;
    public userId?: number;
    public userFirstNameContains: string;
    public userLastNameContains: string;
    public userFullNameContains: string;

    public constructor(init?: Partial<SearchOris>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchOris'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<OriResponse>(); }
}

// @Route("/api/Oris/{Id}", "GET")
export class GetOri implements IReturn<OriResponse>
{
    public id: string;

    public constructor(init?: Partial<GetOri>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOri'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OriResponse(); }
}

// @Route("/api/Oris", "POST")
export class CreateOri implements IReturn<OriResponse>
{
    public agencyId: number;
    public userId: number[];
    public ori: string;
    public ldi: string;

    public constructor(init?: Partial<CreateOri>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateOri'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OriResponse(); }
}

// @Route("/api/Oris/{AgencyId}/Batch", "POST")
export class CreateBatchOris implements IReturn<OriResponse[]>
{
    public agencyId: number;

    public constructor(init?: Partial<CreateBatchOris>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateBatchOris'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Array<OriResponse>(); }
}

// @Route("/api/Oris/BatchAssign")
export class BatchAssignOris implements IReturnVoid
{
    public agencyId: number;

    public constructor(init?: Partial<BatchAssignOris>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BatchAssignOris'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/Oris", "PUT")
export class UpdateOri implements IReturnVoid
{
    public id: string;
    public agencyId: number;
    public userId?: number;
    public ori: string;
    public ldi: string;

    public constructor(init?: Partial<UpdateOri>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateOri'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Agencies", "POST")
export class CreateAgency implements IReturn<AgencyResponse>
{
    /**
    * Name of the Agency to be created.
    */
    // @Required()
    // @ApiMember(DataType="string", Description="Name of the Agency to be created.", IsRequired=true)
    public name: string;

    /**
    * Agency active status.
    */
    // @Required()
    // @ApiMember(DataType="boolean", Description="Agency active status.", IsRequired=true)
    public isActive: boolean;

    /**
    * Timezone of the Agency
    */
    // @Required()
    // @ApiMember(DataType="string", Description="Timezone of the Agency", IsRequired=true)
    public timezone: string;

    /**
    * Primary address of the Agency.
    */
    // @Required()
    // @ApiMember(DataType="string", Description="Primary address of the Agency.", IsRequired=true)
    public primaryAddress1: string;

    /**
    * Second line of the Agency's primary address.
    */
    // @ApiMember(DataType="string", Description="Second line of the Agency's primary address.")
    public primaryAddress2: string;

    /**
    * Primary city of the Agency.
    */
    // @Required()
    // @ApiMember(DataType="string", Description="Primary city of the Agency.", IsRequired=true)
    public primaryCity: string;

    /**
    * Id of the Agency's primary state. <a href="/swagger-ui/#!/api/StateRequest">See States</a>.
    */
    // @Required()
    // @ApiMember(DataType="int", Description="Id of the Agency's primary state. <a href=\"/swagger-ui/#!/api/StateRequest\">See States</a>.", IsRequired=true)
    public primaryStateId: number;

    /**
    * Primary zipcode of the Agency
    */
    // @Required()
    // @ApiMember(DataType="string", Description="Primary zipcode of the Agency", IsRequired=true)
    public primaryZipcode: string;

    /**
    * Primary county/parish of the Agency.
    */
    // @Required()
    // @ApiMember(DataType="string", Description="Primary county/parish of the Agency.", IsRequired=true)
    public primaryCountyParish: string;

    /**
    * Name of the Agency's primary contact.
    */
    // @Required()
    // @ApiMember(DataType="string", Description="Name of the Agency's primary contact.", IsRequired=true)
    public primaryContactName: string;

    /**
    * Phone number of the Agency's primary contact.
    */
    // @Required()
    // @ApiMember(DataType="string", Description="Phone number of the Agency's primary contact.", IsRequired=true)
    public primaryContactPhone: string;

    /**
    * Secondary address of the Agency
    */
    // @ApiMember(DataType="string", Description="Secondary address of the Agency")
    public secondaryAddress1: string;

    /**
    * Second line of the Agency's secondary address.
    */
    // @ApiMember(DataType="string", Description="Second line of the Agency's secondary address.")
    public secondaryAddress2: string;

    /**
    * Secondary city of the Agency.
    */
    // @ApiMember(DataType="string", Description="Secondary city of the Agency.")
    public secondaryCity: string;

    /**
    * Id of the Agency's secondary state. <a href="/swagger-ui/#!/api/StateRequest">See States</a>.
    */
    // @ApiMember(DataType="int", Description="Id of the Agency's secondary state. <a href=\"/swagger-ui/#!/api/StateRequest\">See States</a>.")
    public secondaryStateId?: number;

    /**
    * Secondary zipcode of the Agency.
    */
    // @ApiMember(DataType="string", Description="Secondary zipcode of the Agency.")
    public secondaryZipcode: string;

    /**
    * Secondary county/parish of the Agency.
    */
    // @ApiMember(DataType="string", Description="Secondary county/parish of the Agency.")
    public secondaryCountyParish: string;

    /**
    * Name of the Agency's secondary contact.
    */
    // @ApiMember(DataType="string", Description="Name of the Agency's secondary contact.")
    public secondaryContactName: string;

    /**
    * Phone number of the Agency's secondary contact.
    */
    // @ApiMember(DataType="string", Description="Phone number of the Agency's secondary contact.")
    public secondaryContactPhone: string;

    /**
    * Originating Agency Identification
    */
    // @ApiMember(DataType="string", Description="Originating Agency Identification", IsRequired=true)
    public ori: string;

    /**
    * Agency's LDI number
    */
    // @ApiMember(DataType="string", Description="Agency's LDI number", IsRequired=true)
    public ldi: string;

    /**
    * The phone number to send a notification to when a user is deactived
    */
    // @ApiMember(DataType="string", Description="The phone number to send a notification to when a user is deactived", IsRequired=true)
    public deactivatedUserNotificationPhoneNumber: string;

    /**
    * The email address to send a notification to when a user is deactivated
    */
    // @ApiMember(DataType="string", Description="The email address to send a notification to when a user is deactivated", IsRequired=true)
    public deactivatedUserNotificationEmail: string;

    /**
    * Type of Agency that this corresponds to. <a href="/swagger-ui/#!/api/AgencyTypeRequest">See Agency types</a>.
    */
    // @Required()
    // @ApiMember(DataType="int", Description="Type of Agency that this corresponds to. <a href=\"/swagger-ui/#!/api/AgencyTypeRequest\">See Agency types</a>.", IsRequired=true)
    public agencyTypeId: number;

    /**
    * ...
    */
    // @Required()
    // @ApiMember(DataType="boolean", Description="...", IsRequired=true)
    public investigativeAlertsFeatureEnabled: boolean;

    /**
    * ...
    */
    // @ApiMember(DataType="string", Description="...")
    public defaultSeizureDivisionNotifications: string;

    /**
    * ...
    */
    // @ApiMember(DataType="string", Description="...")
    public defaultIncomingIntelDivisionNotifications: string;

    /**
    * ...
    */
    // @ApiMember(DataType="string", Description="...")
    public defaultIncomingInvestigativeAlertDivisionNotifications: string;

    /**
    * Agency settings that this corresponds to. Must be an administrator to update.
    */
    // @Required()
    // @ApiMember(DataType="AgencySetting", Description="Agency settings that this corresponds to. Must be an administrator to update.")
    public settings: AgencySettings;

    /**
    * Agency contract that this corresponds to. Must be an administrator to update.
    */
    // @Required()
    // @ApiMember(DataType="AgencyContract", Description="Agency contract that this corresponds to. Must be an administrator to update.")
    public contract: Contract;

    /**
    * Number of NCIC seats available to the agency
    */
    // @ApiMember(DataType="int", Description="Number of NCIC seats available to the agency")
    public ncicSeatCount?: number;

    /**
    * The ID of the agency that will serve as a DA to this agency.
    */
    // @ApiMember(DataType="int", Description="The ID of the agency that will serve as a DA to this agency.")
    public districtAttorney?: number;

    public notes: string;
    public ips: string[];

    public constructor(init?: Partial<CreateAgency>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateAgency'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AgencyResponse(); }
}

// @Route("/api/Agencies", "GET")
export class AgencyQuery extends QueryDb_2<Agency, AgencyResponse> implements IReturn<QueryResponse<AgencyResponse>>
{
    public id?: number;
    public idGreaterThanOrEqualTo?: number;
    public idGreaterThan?: number;
    public idLessThan?: number;
    public idLessThanOrEqualTo?: number;
    public idNotEqualTo?: number;
    public idBetween: number[];
    public idIn: number[];
    public name: string;
    public nameStartsWith: string;
    public nameEndsWith: string;
    public nameContains: string;
    public nameLike: string;
    public nameBetween: string[];
    public nameIn: string[];
    public isActive?: boolean;
    public timezone: string;
    public timezoneStartsWith: string;
    public timezoneEndsWith: string;
    public timezoneContains: string;
    public timezoneLike: string;
    public timezoneBetween: string[];
    public timezoneIn: string[];
    public primaryAddress1: string;
    public primaryAddress1StartsWith: string;
    public primaryAddress1EndsWith: string;
    public primaryAddress1Contains: string;
    public primaryAddress1Like: string;
    public primaryAddress1Between: string[];
    public primaryAddress1In: string[];
    public primaryAddress2: string;
    public primaryAddress2StartsWith: string;
    public primaryAddress2EndsWith: string;
    public primaryAddress2Contains: string;
    public primaryAddress2Like: string;
    public primaryAddress2Between: string[];
    public primaryAddress2In: string[];
    public primaryCity: string;
    public primaryCityStartsWith: string;
    public primaryCityEndsWith: string;
    public primaryCityContains: string;
    public primaryCityLike: string;
    public primaryCityBetween: string[];
    public primaryCityIn: string[];
    public primaryStateId?: number;
    public primaryStateIdGreaterThanOrEqualTo?: number;
    public primaryStateIdGreaterThan?: number;
    public primaryStateIdLessThan?: number;
    public primaryStateIdLessThanOrEqualTo?: number;
    public primaryStateIdNotEqualTo?: number;
    public primaryStateIdBetween: number[];
    public primaryStateIdIn: number[];
    public primaryZipcode: string;
    public primaryZipcodeStartsWith: string;
    public primaryZipcodeEndsWith: string;
    public primaryZipcodeContains: string;
    public primaryZipcodeLike: string;
    public primaryZipcodeBetween: string[];
    public primaryZipcodeIn: string[];
    public primaryCountyParish: string;
    public primaryCountyParishStartsWith: string;
    public primaryCountyParishEndsWith: string;
    public primaryCountyParishContains: string;
    public primaryCountyParishLike: string;
    public primaryCountyParishBetween: string[];
    public primaryCountyParishIn: string[];
    public primaryContactName: string;
    public primaryContactNameStartsWith: string;
    public primaryContactNameEndsWith: string;
    public primaryContactNameContains: string;
    public primaryContactNameLike: string;
    public primaryContactNameBetween: string[];
    public primaryContactNameIn: string[];
    public primaryContactPhone: string;
    public primaryContactPhoneStartsWith: string;
    public primaryContactPhoneEndsWith: string;
    public primaryContactPhoneContains: string;
    public primaryContactPhoneLike: string;
    public primaryContactPhoneBetween: string[];
    public primaryContactPhoneIn: string[];
    public secondaryAddress1: string;
    public secondaryAddress1StartsWith: string;
    public secondaryAddress1EndsWith: string;
    public secondaryAddress1Contains: string;
    public secondaryAddress1Like: string;
    public secondaryAddress1Between: string[];
    public secondaryAddress1In: string[];
    public secondaryAddress2: string;
    public secondaryAddress2StartsWith: string;
    public secondaryAddress2EndsWith: string;
    public secondaryAddress2Contains: string;
    public secondaryAddress2Like: string;
    public secondaryAddress2Between: string[];
    public secondaryAddress2In: string[];
    public secondaryCity: string;
    public secondaryCityStartsWith: string;
    public secondaryCityEndsWith: string;
    public secondaryCityContains: string;
    public secondaryCityLike: string;
    public secondaryCityBetween: string[];
    public secondaryCityIn: string[];
    public secondaryStateId?: number;
    public secondaryStateIdGreaterThanOrEqualTo?: number;
    public secondaryStateIdGreaterThan?: number;
    public secondaryStateIdLessThan?: number;
    public secondaryStateIdLessThanOrEqualTo?: number;
    public secondaryStateIdNotEqualTo?: number;
    public secondaryStateIdBetween: number[];
    public secondaryStateIdIn: number[];
    public secondaryZipcode: string;
    public secondaryZipcodeStartsWith: string;
    public secondaryZipcodeEndsWith: string;
    public secondaryZipcodeContains: string;
    public secondaryZipcodeLike: string;
    public secondaryZipcodeBetween: string[];
    public secondaryZipcodeIn: string[];
    public secondaryCountyParish: string;
    public secondaryCountyParishStartsWith: string;
    public secondaryCountyParishEndsWith: string;
    public secondaryCountyParishContains: string;
    public secondaryCountyParishLike: string;
    public secondaryCountyParishBetween: string[];
    public secondaryCountyParishIn: string[];
    public secondaryContactName: string;
    public secondaryContactNameStartsWith: string;
    public secondaryContactNameEndsWith: string;
    public secondaryContactNameContains: string;
    public secondaryContactNameLike: string;
    public secondaryContactNameBetween: string[];
    public secondaryContactNameIn: string[];
    public secondaryContactPhone: string;
    public secondaryContactPhoneStartsWith: string;
    public secondaryContactPhoneEndsWith: string;
    public secondaryContactPhoneContains: string;
    public secondaryContactPhoneLike: string;
    public secondaryContactPhoneBetween: string[];
    public secondaryContactPhoneIn: string[];
    public oriNumber: string;
    public oriNumberStartsWith: string;
    public oriNumberEndsWith: string;
    public oriNumberContains: string;
    public oriNumberLike: string;
    public oriNumberBetween: string[];
    public oriNumberIn: string[];
    public agencyTypeId?: number;
    public agencyTypeIdGreaterThanOrEqualTo?: number;
    public agencyTypeIdGreaterThan?: number;
    public agencyTypeIdLessThan?: number;
    public agencyTypeIdLessThanOrEqualTo?: number;
    public agencyTypeIdNotEqualTo?: number;
    public agencyTypeIdBetween: number[];
    public agencyTypeIdIn: number[];
    public refactorConverted?: boolean;

    public constructor(init?: Partial<AgencyQuery>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'AgencyQuery'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<AgencyResponse>(); }
}

// @Route("/api/Agencies/{Id}", "GET")
export class GetAgency implements IReturn<AgencyResponse>
{
    // @Required()
    public id: number;

    public constructor(init?: Partial<GetAgency>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAgency'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AgencyResponse(); }
}

// @Route("/api/Agencies/{AgencyId}", "PUT")
export class UpdateAgency implements IReturnVoid
{
    /**
    * Id of the Agency to be updated.
    */
    // @Required()
    // @ApiMember(DataType="int", Description="Id of the Agency to be updated.", IsRequired=true)
    public agencyId: number;

    /**
    * Name of the Agency.
    */
    // @ApiMember(DataType="string", Description="Name of the Agency.")
    public name: string;

    /**
    * Active status of the Agency. Must be an administrator to update
    */
    // @ApiMember(DataType="boolean", Description="Active status of the Agency. Must be an administrator to update")
    public isActive?: boolean;

    /**
    * Primary timezone of the Agency.
    */
    // @ApiMember(DataType="string", Description="Primary timezone of the Agency.")
    public timezone: string;

    /**
    * Primary address of the Agency.
    */
    // @ApiMember(DataType="string", Description="Primary address of the Agency.")
    public primaryAddress1: string;

    /**
    * Second line of the Agency's primary address.
    */
    // @ApiMember(DataType="string", Description="Second line of the Agency's primary address.")
    public primaryAddress2: string;

    /**
    * Primary city of the Agency.
    */
    // @ApiMember(DataType="string", Description="Primary city of the Agency.")
    public primaryCity: string;

    /**
    * Id of the Agency's primary state. <a href="/swagger-ui/#!/api/StateRequest">See States</a>.
    */
    // @ApiMember(DataType="int", Description="Id of the Agency's primary state. <a href=\"/swagger-ui/#!/api/StateRequest\">See States</a>.")
    public primaryStateId?: number;

    /**
    * Primary zipcode of the Agency.
    */
    // @ApiMember(DataType="string", Description="Primary zipcode of the Agency.")
    public primaryZipcode: string;

    /**
    * Primary county/parish of the Agency.
    */
    // @ApiMember(DataType="string", Description="Primary county/parish of the Agency.")
    public primaryCountyParish: string;

    /**
    * Name of the Agency's primary contact.
    */
    // @ApiMember(DataType="string", Description="Name of the Agency's primary contact.")
    public primaryContactName: string;

    /**
    * Phone number of the Agency's primary contact.
    */
    // @ApiMember(DataType="string", Description="Phone number of the Agency's primary contact.")
    public primaryContactPhone: string;

    /**
    * Secondary address of the Agency.
    */
    // @ApiMember(DataType="string", Description="Secondary address of the Agency.")
    public secondaryAddress1: string;

    /**
    * Second line of the Agency's secondary address.
    */
    // @ApiMember(DataType="string", Description="Second line of the Agency's secondary address.")
    public secondaryAddress2: string;

    /**
    * Secondary city of the Agency.
    */
    // @ApiMember(DataType="string", Description="Secondary city of the Agency.")
    public secondaryCity: string;

    /**
    * Id of the Agency's secondary state. <a href="/swagger-ui/#!/api/StateRequest">See States</a>.
    */
    // @ApiMember(DataType="int", Description="Id of the Agency's secondary state. <a href=\"/swagger-ui/#!/api/StateRequest\">See States</a>.")
    public secondaryStateId?: number;

    /**
    * Secondary zip code of the Agency.
    */
    // @ApiMember(DataType="string", Description="Secondary zip code of the Agency.")
    public secondaryZipcode: string;

    /**
    * Secondary county/parish of the Agency.
    */
    // @ApiMember(DataType="string", Description="Secondary county/parish of the Agency.")
    public secondaryCountyParish: string;

    /**
    * Name of the Agency's secondary contact.
    */
    // @ApiMember(DataType="string", Description="Name of the Agency's secondary contact.")
    public secondaryContactName: string;

    /**
    * Phone number of the Agency's secondary contact.
    */
    // @ApiMember(DataType="string", Description="Phone number of the Agency's secondary contact.")
    public secondaryContactPhone: string;

    /**
    * Originating Agency identification
    */
    // @ApiMember(DataType="string", Description="Originating Agency identification")
    public ori: string;

    /**
    * Agency's LDI number
    */
    // @ApiMember(DataType="string", Description="Agency's LDI number")
    public ldi: string;

    /**
    * The phone number to send a notification to when a user is deactived
    */
    // @ApiMember(DataType="string", Description="The phone number to send a notification to when a user is deactived", IsRequired=true)
    public deactivatedUserNotificationPhoneNumber: string;

    /**
    * The email address to send a notification to when a user is deactivated
    */
    // @ApiMember(DataType="string", Description="The email address to send a notification to when a user is deactivated", IsRequired=true)
    public deactivatedUserNotificationEmail: string;

    /**
    * Type of Agency that this corresponds to. <a href="/swagger-ui/#!/api/AgencyTypeRequest">See Agency types</a>.
    */
    // @ApiMember(DataType="int", Description="Type of Agency that this corresponds to. <a href=\"/swagger-ui/#!/api/AgencyTypeRequest\">See Agency types</a>.")
    public agencyTypeId?: number;

    /**
    * Agency settings that this corresponds to. Must be an administrator to update.
    */
    // @ApiMember(DataType="AgencySetting", Description="Agency settings that this corresponds to. Must be an administrator to update.")
    public settings: AgencySettings;

    /**
    * Agency contract that this corresponds to. Must be an administrator to update.
    */
    // @ApiMember(DataType="AgencyContract", Description="Agency contract that this corresponds to. Must be an administrator to update.")
    public contract: Contract;

    public ncicSeatCount?: number;
    /**
    * The ID of the agency that will serve as a DA to this agency.
    */
    // @ApiMember(DataType="int", Description="The ID of the agency that will serve as a DA to this agency.")
    public districtAttorney?: number;

    public notes: string;
    public ips: string[];

    public constructor(init?: Partial<UpdateAgency>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateAgency'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Agencies/ConvertToRefactor", "PUT")
export class ConvertAgencyToRefactor implements IReturnVoid
{
    public agencyId: number;

    public constructor(init?: Partial<ConvertAgencyToRefactor>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConvertAgencyToRefactor'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Alerts", "GET")
export class SearchAlerts extends QueryDb_2<Alert, AlertResponse> implements IReturn<QueryResponse<AlertResponse>>, IQuerySharable
{
    public titleContains: string;
    public alertContentContains: string;
    public createdAtLessThanOrEqualTo?: string;
    public createdAtGreaterThanOrEqualTo?: string;
    public updatedAtLessThanOrEqualTo?: string;
    public updatedAtGreaterThanOrEqualTo?: string;
    public visibleToUserId?: number;
    public createdBy?: number;
    public ownershipType?: number;
    public ownershipTypeId?: number;

    public constructor(init?: Partial<SearchAlerts>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchAlerts'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<AlertResponse>(); }
}

// @Route("/api/Alerts/{Id}", "GET")
export class GetAlert implements IReturn<AlertResponse>
{
    // @Required()
    public id: string;

    public constructor(init?: Partial<GetAlert>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAlert'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AlertResponse(); }
}

// @Route("/api/Alerts/{Id}", "PUT")
export class UpdateAlert implements IReturn<AlertResponse>
{
    // @Required()
    public id: string;

    /**
    * Title of the Investigative Alert
    */
    // @ApiMember(DataType="string", Description="Title of the Investigative Alert", IsRequired=true)
    public title: string;

    /**
    * Description of the Alert
    */
    // @ApiMember(DataType="string", Description="Description of the Alert", IsRequired=true)
    public alertContent: string;

    /**
    * Agencies that will be subscribed to the Alert
    */
    // @ApiMember(DataType="int[]", Description="Agencies that will be subscribed to the Alert")
    public agenciesToSubscribe: number[];

    /**
    * Divisions that will be subscribed to the Alert
    */
    // @ApiMember(DataType="int[]", Description="Divisions that will be subscribed to the Alert")
    public divisionsToSubscribe: number[];

    /**
    * The Investigation this alert is tied to
    */
    // @ApiMember(Description="The Investigation this alert is tied to")
    public investigationId?: string;

    /**
    * The Intel this alert is tied to
    */
    // @ApiMember(Description="The Intel this alert is tied to")
    public intelId?: string;

    /**
    * The Packet this alert is tied to
    */
    // @ApiMember(Description="The Packet this alert is tied to")
    public subjectPacketId?: string;

    /**
    * The Media to add to this Alert
    */
    // @ApiMember(Description="The Media to add to this Alert")
    public media: number[];

    /**
    * The Media to delete from this Alert
    */
    // @ApiMember(Description="The Media to delete from this Alert")
    public mediaToDelete: number[];

    public constructor(init?: Partial<UpdateAlert>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateAlert'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new AlertResponse(); }
}

// @Route("/api/Alerts/{Id}", "PUT")
export class ToggleAlertDeactivation implements IReturn<AlertResponse>
{
    // @Required()
    public id: string;

    public deactivationReason: string;
    public reactivationReason: string;
    public deactivated: boolean;

    public constructor(init?: Partial<ToggleAlertDeactivation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ToggleAlertDeactivation'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new AlertResponse(); }
}

// @Route("/api/AuditLogs", "GET")
export class SearchAuditLogs extends QueryDb_1<AuditEvent> implements IReturn<QueryResponse<AuditEvent>>
{
    // @Required()
    public entityId: string;

    // @Required()
    public entityType: string;

    public insertedDateGreaterThanOrEqualTo?: string;
    public insertedDateLessThanOrEqualTo?: string;
    public userNameContains: string;
    public userEmailContains: string;
    public detailsContains: string;

    public constructor(init?: Partial<SearchAuditLogs>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchAuditLogs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<AuditEvent>(); }
}

// @Route("/api/CommSys", "GET")
export class CommSysReport implements IReturn<CommSysReportResponse>
{
    public agencyId: number[];
    public startDate?: string;
    public endDate?: string;

    public constructor(init?: Partial<CommSysReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommSysReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommSysReportResponse(); }
}

// @Route("/api/NcicConversionSummary", "POST")
export class GetNcicConversionSummary implements IReturn<CreateFromNcicSummaryResult>
{
    public intel: string[];
    public packet: string[];
    public packetQueryIds: string[];
    public intelQueryIds: string[];
    public alertWithData: AlertData;
    public initialIntel: CreateIntel;
    public initialPacket: CreateSubjectPacket;

    public constructor(init?: Partial<GetNcicConversionSummary>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetNcicConversionSummary'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateFromNcicSummaryResult(); }
}

// @Route("/api/Currency", "GET")
export class SearchCurrency extends QueryDb_2<SubjectPacket, CurrencyResponse> implements IReturn<QueryResponse<CurrencyResponse>>, IQuerySharable
{
    /**
    * The Id of the Parent Batch
    */
    // @ApiMember(Description="The Id of the Parent Batch")
    public id?: string;

    public subjectPacketTypeId: number;
    public subjectPacketStateId?: number;
    public subjectPacketStateName: string;
    /**
    * The UTN of the Parent Batch
    */
    // @ApiMember(Description="The UTN of the Parent Batch")
    public utnStartsWith: string;

    /**
    * The owning Agency Id
    */
    // @ApiMember(Description="The owning Agency Id")
    public createdByAgencyId?: number;

    /**
    * The owning User Id
    */
    // @ApiMember(Description="The owning User Id")
    public createdBy?: number;

    public createdByNotEqualTo?: number;
    /**
    * Whether the bills were washed
    */
    // @ApiMember(Description="Whether the bills were washed")
    public washed?: boolean;

    public deleted?: boolean;
    public nameContains: string;
    public wasFileUpload: boolean;
    public createdAtLessThanOrEqualTo?: string;
    public createdAtGreaterThanOrEqualTo?: string;
    public updatedAtLessThanOrEqualTo?: string;
    public updatedAtGreaterThanOrEqualTo?: string;
    public reportNumberContains: string;
    public visibleToUserId?: number;
    public ownershipTypeId?: number;
    public processingFlag: string;

    public constructor(init?: Partial<SearchCurrency>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchCurrency'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<CurrencyResponse>(); }
}

// @Route("/api/Currency/{Id}", "GET")
export class GetCurrency implements IReturn<CurrencyResponse>
{
    public id: string;

    public constructor(init?: Partial<GetCurrency>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCurrency'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CurrencyResponse(); }
}

// @Route("/api/Currency/{BillId}/WashSummary ")
export class GetBillWashSummary implements IReturn<BillWashSummaryResponse[]>
{
    public billId: string;

    public constructor(init?: Partial<GetBillWashSummary>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBillWashSummary'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<BillWashSummaryResponse>(); }
}

// @Route("/api/Currency/batch", "POST")
export class CreateCurrencyBatch implements IReturn<CurrencyResponse>
{
    public attachToParentId?: string;
    public subjectTypeId: number;
    public publicNotes: string;
    public privateNotes: string;
    public isPublic: boolean;
    public reportNumber: string;
    public monies: Money[];
    public name: string;

    public constructor(init?: Partial<CreateCurrencyBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateCurrencyBatch'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CurrencyResponse(); }
}

// @Route("/api/Currency", "PUT")
export class UpdateCurrency implements IReturnVoid
{
    public id: string;
    public attachToParentId?: string;
    public subjectTypeId: number;
    public publicNotes: string;
    public privateNotes: string;
    public isPublic: boolean;
    public reportNumber: string;
    public monies: Money[];
    public name: string;
    public user: number;
    public media: number[];
    public mediaToDelete: number[];

    public constructor(init?: Partial<UpdateCurrency>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateCurrency'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Currency/Bills", "DELETE")
export class DeleteCurrencyBills implements IReturnVoid
{
    public billIds: string[];

    public constructor(init?: Partial<DeleteCurrencyBills>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteCurrencyBills'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api/Currency/Batches", "PUT")
export class DeleteCurrencyBatch implements IReturnVoid
{
    public monies: Money[];
    public attachToParentId?: string;
    public user: number;

    public constructor(init?: Partial<DeleteCurrencyBatch>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteCurrencyBatch'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Currency/{BatchId}/Segregate", "PUT")
export class SegregateCurrency implements IReturn<SegregatedBill[]>
{
    /**
    * Id of the batch to segregate against
    */
    // @ApiMember(Description="Id of the batch to segregate against")
    public batchId: string;

    public constructor(init?: Partial<SegregateCurrency>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SegregateCurrency'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new Array<SegregatedBill>(); }
}

// @Route("/api/Currency", "POST")
export class CreateCurrency implements IReturn<CurrencyResponse>
{
    public attachToParentId?: string;
    public subjectTypeId: number;
    public publicNotes: string;
    public privateNotes: string;
    public isPublic: boolean;
    public reportNumber: string;
    public monies: Money[];
    public name: string;

    public constructor(init?: Partial<CreateCurrency>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateCurrency'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CurrencyResponse(); }
}

// @Route("/api/Currency/{BatchId}/Process", "PUT")
export class ProcessCurrency implements IReturnVoid
{
    public batchId: string;

    public constructor(init?: Partial<ProcessCurrency>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcessCurrency'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Divisions/{DivisionId}/DefaultNotifications", "POST")
export class UpdateDefaultNotifications implements IReturnVoid, IPost
{
    public divisionId: number;
    public updates: UpdateDefaultNotificationsPayload[];

    public constructor(init?: Partial<UpdateDefaultNotifications>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateDefaultNotifications'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/Divisions", "GET")
// @Route("/api/Agency/{AgencyId}/Divisions", "GET")
export class SearchDivisions extends QueryDb_2<Division, DivisionResponse> implements IReturn<QueryResponse<DivisionResponse>>
{
    public agencyId?: number;
    public nameContains: string;

    public constructor(init?: Partial<SearchDivisions>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchDivisions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<DivisionResponse>(); }
}

// @Route("/api/Divisions", "POST")
// @Route("/api/Agencies/{AgencyId}/Divisions", "POST")
export class CreateDivision implements IReturn<DivisionResponse>
{
    public agencyId: number;
    public name: string;
    public usersToAssign: number[];

    public constructor(init?: Partial<CreateDivision>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateDivision'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DivisionResponse(); }
}

// @Route("/api/Divisions", "PUT")
// @Route("/api/Agencies/{AgencyId}/Divisions", "PUT")
export class UpdateDivision implements IReturnVoid
{
    public id: number;
    public name: string;
    public agencyId?: number;
    public users: number[];

    public constructor(init?: Partial<UpdateDivision>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateDivision'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Divisions", "DELETE")
export class DeleteDivisions implements IReturnVoid
{
    public ids: number[];

    public constructor(init?: Partial<DeleteDivisions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteDivisions'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api/Divisions", "GET")
export class GetUserDivisions implements IReturn<UserDivisionsResponse>
{
    public userId: number;

    public constructor(init?: Partial<GetUserDivisions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserDivisions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UserDivisionsResponse(); }
}

// @Route("/api/EntityViewLogs", "GET")
export class SearchEntityViewLogs extends QueryDb_2<EntityViewLog, EntityViewLogResponse> implements IReturn<QueryResponse<EntityViewLogResponse>>
{
    public viewDateLessThanOrEqualTo?: string;
    public viewDateGreaterThanOrEqualTo?: string;
    public userFullNameContains: string;
    public userEmailContains: string;
    public userPhoneNumberContains: string;
    public agencyNameContains: string;
    public entityTypeId: number;
    public entityId: string;

    public constructor(init?: Partial<SearchEntityViewLogs>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchEntityViewLogs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<EntityViewLogResponse>(); }
}

// @Route("/api/Environment", "GET")
export class GetEnvironment implements IReturn<EnvironmentResponse>
{

    public constructor(init?: Partial<GetEnvironment>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEnvironment'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EnvironmentResponse(); }
}

export class TriggerIntentionalError implements IReturnVoid
{
    public statusCode: number;
    public message: string;
    public sqlError?: boolean;

    public constructor(init?: Partial<TriggerIntentionalError>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TriggerIntentionalError'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/api/Media/{MediaId}/Faces", "GET")
export class DetectFacesRequest implements IReturn<DetectFacesResponse>
{
    public mediaId: number;

    public constructor(init?: Partial<DetectFacesRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DetectFacesRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DetectFacesResponse(); }
}

// @Route("/api/IndexesFaces", "POST")
export class CreateIndexedFace implements IReturn<FaceDto>
{
    /**
    * An optional collection to store this face in.In none is specified, then the face will be stored in a default collection.
    */
    // @ApiMember(DataType="string", Description="An optional collection to store this face in.In none is specified, then the face will be stored in a default collection.")
    public collectionId: string;

    /**
    * The ID of the media that the face is contained in. Images must be uploaded first using the UploadMedia operation.
    */
    // @ApiMember(DataType="int", Description="The ID of the media that the face is contained in. Images must be uploaded first using the UploadMedia operation.", IsRequired=true)
    public mediaId: number;

    /**
    * If no bounds are set,then it's assumed that the image contains one face.If multiple faces are in the image, then it's recommended that the bounds are set that surrounds the face that you need to be indexed.
    */
    // @ApiMember(DataType="object", Description="If no bounds are set,then it's assumed that the image contains one face.If multiple faces are in the image, then it's recommended that the bounds are set that surrounds the face that you need to be indexed.")
    public faceBounds: Bounds;

    public constructor(init?: Partial<CreateIndexedFace>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateIndexedFace'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FaceDto(); }
}

// @Route("/api/Faces/{Id}/Matches", "GET")
export class SearchMatchingFaces implements IReturn<FaceDto[]>
{
    // @ApiMember(DataType="Guid", IsRequired=true)
    public id: string;

    /**
    * Whether or not to include faces that are not associated with any Intel, Packets or Investigations.
    */
    // @ApiMember(DataType="bool", Description="Whether or not to include faces that are not associated with any Intel, Packets or Investigations.")
    public includeUnassociatedFaces: boolean;

    /**
    * Whether or not to include the personal info associated with each face, like name race, and date of birth.This information comes from Intel, Packets and Investigations.
    */
    // @ApiMember(DataType="bool", Description="Whether or not to include the personal info associated with each face, like name race, and date of birth.This information comes from Intel, Packets and Investigations.")
    public includePersonalInfo: boolean;

    // @ApiMember(DataType="int")
    public investigationIdNotEqualTo?: string;

    public constructor(init?: Partial<SearchMatchingFaces>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchMatchingFaces'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<FaceDto>(); }
}

// @Route("/api/Faces/{Id}/Media", "GET")
export class GetMediaForFace implements IReturn<Object>
{
    public id: string;

    public constructor(init?: Partial<GetMediaForFace>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMediaForFace'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Object(); }
}

// @Route("/api/GlobalSearch", "GET")
export class GlobalSearch extends QueryDb_2<GlobalSearchItem, GlobalSearchItemResponse> implements IReturn<QueryResponse<GlobalSearchItemResponse>>
{
    public utnStartsWith: string;
    public searchTerm: string;
    public globalSearchTypeId: number;
    public entityTypeId?: number;
    public userFirstNameContains: string;
    public userLastNameContains: string;
    public userEmailContains: string;
    public userPhoneNumberContains: string;

    public constructor(init?: Partial<GlobalSearch>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GlobalSearch'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<GlobalSearchItemResponse>(); }
}

// @Route("/api/Intel", "GET")
export class SearchIntel extends QueryDb_2<Intel, IntelResponse> implements IReturn<QueryResponse<IntelResponse>>, IQuerySharable
{
    public reportNumberContains: string;
    public districtContains: string;
    public reasonForStopContains: string;
    public titleContains: string;
    public utnStartsWith: string;
    public createdAtLessThanOrEqualTo?: string;
    public createdAtGreaterThanOrEqualTo?: string;
    public updatedAtLessThanOrEqualTo?: string;
    public updatedAtGreaterThanOrEqualTo?: string;
    public intelTypeId?: number;
    public intelTypeName: string;
    public visibleToUserId?: number;
    public createdBy?: number;
    public createdByNotEqualTo?: number;
    public ownershipTypeId?: number;
    public developmentStatusId?: number;
    public intelStateId?: number;
    public agencyId?: number;

    public constructor(init?: Partial<SearchIntel>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchIntel'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<IntelResponse>(); }
}

// @Route("/api/Intel/{Id}", "GET")
export class GetIntel implements IReturn<IntelResponse>
{
    public id: string;

    public constructor(init?: Partial<GetIntel>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIntel'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new IntelResponse(); }
}

// @Route("/api/Intel/{id}/InvestigationHits", "GET")
export class GetIntelInvestigationHits implements IReturn<CondensedInvestigationResponse[]>
{
    // @Required()
    public id: string;

    public constructor(init?: Partial<GetIntelInvestigationHits>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIntelInvestigationHits'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<CondensedInvestigationResponse>(); }
}

// @Route("/api/Intel/{id}/PriorInvestigations", "GET")
export class GetIntelPriorInvestigations implements IReturn<CondensedInvestigationResponse[]>
{
    // @Required()
    public id: string;

    public constructor(init?: Partial<GetIntelPriorInvestigations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIntelPriorInvestigations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<CondensedInvestigationResponse>(); }
}

// @Route("/api/Intel", "PUT")
export class UpdateIntel implements IReturnVoid
{
    /**
    * Identification of the Intel to be updated.
    */
    // @ApiMember(DataType="Guid", Description="Identification of the Intel to be updated.", IsRequired=true)
    public id: string;

    /**
    * Type of Intel that this corresponds to. <a href="/swagger-ui/#!/api/IntelTypeRequest">See Intel types</a>.
    */
    // @ApiMember(DataType="int", Description="Type of Intel that this corresponds to. <a href=\"/swagger-ui/#!/api/IntelTypeRequest\">See Intel types</a>.")
    public intelTypeId: number;

    /**
    * Report number of external system.
    */
    // @ApiMember(DataType="string", Description="Report number of external system.")
    public reportNumber: string;

    public isPrivate: boolean;
    /**
    * User entered date and time of entry
    */
    // @ApiMember(DataType="DateTIme", Description="User entered date and time of entry")
    public userEnteredDateTime?: string;

    /**
    * Title of the Intel
    */
    // @ApiMember(DataType="string", Description="Title of the Intel", IsRequired=true)
    public title: string;

    // @ApiMember(DataType="string")
    public district: string;

    /**
    * 
    */
    // @ApiMember(DataType="string", Description="", IsRequired=true)
    public reasonForStop: string;

    /**
    * User id of the person the Intel is assigned to
    */
    // @ApiMember(DataType="int", Description="User id of the person the Intel is assigned to")
    public assignedTo: number;

    /**
    * List of vehicles associated with Intel. At least one field must be populated.
    */
    // @ApiMember(DataType="List of IntelVehicleDto", Description="List of vehicles associated with Intel. At least one field must be populated.")
    public vehicles: IntelVehicleDto[];

    /**
    * List of subjects associated with Intel. At least one field must be populated.
    */
    // @ApiMember(DataType="List of IntelSubjectDto", Description="List of subjects associated with Intel. At least one field must be populated.")
    public subjects: IntelSubjectDto[];

    /**
    * List of tattoo associated with Intel. At least one field must be populated.
    */
    // @ApiMember(DataType="List of IntelTattooDto", Description="List of tattoo associated with Intel. At least one field must be populated.")
    public tattoos: IntelTattooDto[];

    /**
    * List of Addresses associated with Intel. At least one field must be populated.
    */
    // @ApiMember(DataType="List of IntelAddressDto", Description="List of Addresses associated with Intel. At least one field must be populated.")
    public addresses: IntelAddressDto[];

    /**
    * List of IntelSocialMediaProfile associated with Intel. At least one field must be populated.
    */
    // @ApiMember(DataType="List of IntelSocialMediaProfileDto", Description="List of IntelSocialMediaProfile associated with Intel. At least one field must be populated.")
    public socialMediaProfiles: IntelSocialMediaProfileDto[];

    public usersToSubscribe: number[];
    /**
    * Divisions that will be subscribed to updates
    */
    // @ApiMember(DataType="List of int", Description="Divisions that will be subscribed to updates")
    public divisionsToSubscribe: number[];

    /**
    * Users that were on site when gathering this Intel
    */
    // @ApiMember(DataType="List of int", Description="Users that were on site when gathering this Intel")
    public onsiteUsers: number[];

    /**
    * Other agencies that we want to share with
    */
    // @ApiMember(DataType="List of int", Description="Other agencies that we want to share with")
    public agenciesToShareWith: number[];

    /**
    * Media ids associated with this Intel
    */
    // @ApiMember(DataType="List", Description="Media ids associated with this Intel")
    public media: MediaDto[];

    public mediaToDelete: MediaDto[];
    public developmentStatusId: number;
    public divisions: number[];

    public constructor(init?: Partial<UpdateIntel>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateIntel'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Intels/{Id}/Deactivate", "PUT")
export class MarkIntelDeactivate implements IReturn<IntelResponse>
{
    public id: string;
    public deactivationReason: string;

    public constructor(init?: Partial<MarkIntelDeactivate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MarkIntelDeactivate'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new IntelResponse(); }
}

// @Route("/api/Intels/{Id}/Reactivate", "PUT")
export class MarkIntelReactivate implements IReturn<IntelResponse>
{
    public id: string;
    public reactivationReason: string;

    public constructor(init?: Partial<MarkIntelReactivate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MarkIntelReactivate'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new IntelResponse(); }
}

// @Route("/api/importIntels", "POST")
export class ImportIntels implements IReturnVoid
{

    public constructor(init?: Partial<ImportIntels>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportIntels'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/InvestigationQueries/{InvestigationQueryId}/PriorInvestigations", "GET")
export class GetInvestigationQueryPriorInvestigations extends QueryDb_2<InvestigationQueryHit, InvestigationQueryDetailDto> implements IReturn<QueryResponse<InvestigationQueryDetailDto>>
{
    public investigationQueryId: string;
    public includeOnlyTotal: boolean;

    public constructor(init?: Partial<GetInvestigationQueryPriorInvestigations>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInvestigationQueryPriorInvestigations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<InvestigationQueryDetailDto>(); }
}

// @Route("/api/InvestigationQueries/{InvestigationQueryId}/SubjectPackets", "GET")
export class GetInvestigationQuerySubjectPackets extends QueryDb_2<InvestigationQueryHit, InvestigationQueryDetailDto> implements IReturn<QueryResponse<InvestigationQueryDetailDto>>
{
    public investigationQueryId: string;
    public includeOnlyTotal: boolean;

    public constructor(init?: Partial<GetInvestigationQuerySubjectPackets>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInvestigationQuerySubjectPackets'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<InvestigationQueryDetailDto>(); }
}

// @Route("/api/InvestigationQueries/{InvestigationQueryId}/Intel", "GET")
export class GetInvestigationQueryIntel extends QueryDb_2<InvestigationQueryHit, InvestigationQueryDetailDto> implements IReturn<QueryResponse<InvestigationQueryDetailDto>>
{
    public investigationQueryId: string;
    public includeOnlyTotal: boolean;

    public constructor(init?: Partial<GetInvestigationQueryIntel>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInvestigationQueryIntel'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<InvestigationQueryDetailDto>(); }
}

// @Route("/api/InvestigationQueries/{InvestigationQueryId}/Currency")
export class GetInvestigationQueryCurrency extends QueryDb_2<InvestigationQueryHit, InvestigationQueryDetailDto> implements IReturn<QueryResponse<InvestigationQueryDetailDto>>
{
    public investigationQueryId: string;
    public includeOnlyTotal: boolean;

    public constructor(init?: Partial<GetInvestigationQueryCurrency>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInvestigationQueryCurrency'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<InvestigationQueryDetailDto>(); }
}

// @Route("/api/InvestigationQueries/{InvestigationQueryId}/Faces", "GET")
export class GetInvestigationQueryFaces extends QueryDb_2<FaceCandidateListResponse, FaceDto> implements IReturn<QueryResponse<FaceDto>>
{
    public investigationQueryId: string;

    public constructor(init?: Partial<GetInvestigationQueryFaces>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInvestigationQueryFaces'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<FaceDto>(); }
}

// @Route("/api/InvestigationQueries", "GET")
export class SearchInvestigationQueries extends QueryDb_2<InvestigationQuery, SearchInvestigationQueryResponse> implements IReturn<QueryResponse<SearchInvestigationQueryResponse>>
{
    public investigationStartTimeGreaterThanOrEqualTo?: string;
    public investigationStartTimeLessThanOrEqualTo?: string;
    public userId?: number;
    public agencyId: number;
    public subjectPacketIdentificationTypeId?: number;
    public identificationNumberContains: string;
    public investigationInvestigationTypeId?: string;
    public investigationTypeIdNotIn: number[];
    public orderByDesc: string;
    public hasQueries?: boolean;
    public race: string;
    public sex: string;
    public firstName: string;
    public lastName: string;
    public dispositionTypeIds: number;
    public hasDisposition?: boolean;

    public constructor(init?: Partial<SearchInvestigationQueries>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchInvestigationQueries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<SearchInvestigationQueryResponse>(); }
}

// @Route("/api/InvestigationQueries/{Id}", "GET")
export class GetInvestigationQuery implements IReturn<InvestigationQueryDto>
{
    public id: string;

    public constructor(init?: Partial<GetInvestigationQuery>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInvestigationQuery'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvestigationQueryDto(); }
}

// @Route("/api/Investigations/{InvestigationId}/Queries", "POST")
// @Route("/api/InvestigationQueries", "POST")
export class CreateInvestigationQuery implements IReturn<InvestigationQueryDto>
{
    /**
    * Identification number of the Investigation Query to be created
    */
    // @ApiMember(DataType="string", Description="Identification number of the Investigation Query to be created", IsRequired=true)
    public identificationNumber: string;

    /**
    * When searching for currency, must pass in denomination. Conditionally required.
    */
    // @ApiMember(DataType="int", Description="When searching for currency, must pass in denomination. Conditionally required.")
    public denomination?: number;

    /**
    * Identification type that this Investigation Query corresponds to. <a href="/swagger-ui/#!/api/IdentificationTypeRequest">See Identification Types</a>.
    */
    // @ApiMember(DataType="int", Description="Identification type that this Investigation Query corresponds to. <a href=\"/swagger-ui/#!/api/IdentificationTypeRequest\">See Identification Types</a>.", IsRequired=true)
    public identificationTypeId: number;

    /**
    * Id of the Investigative Query's primary state. <a href="/swagger-ui/#!/api/StateRequest">See States</a>.
    */
    // @ApiMember(DataType="int", Description="Id of the Investigative Query's primary state. <a href=\"/swagger-ui/#!/api/StateRequest\">See States</a>.", IsRequired=true)
    public stateId: number;

    /**
    * ...
    */
    // @ApiMember(DataType="int", Description="...")
    public specialtyPlateId?: number;

    /**
    * The license plate type code as defined in the NCIC Code Manual
    */
    // @ApiMember(DataType="string", Description="The license plate type code as defined in the NCIC Code Manual")
    public licensePlateType: string;

    /**
    * The license plate year. By default this will be set to the current year
    */
    // @ApiMember(DataType="int", Description="The license plate year. By default this will be set to the current year")
    public licensePlateYear?: number;

    /**
    * ...
    */
    // @ApiMember(DataType="int", Description="...")
    public createdForUserId?: number;

    /**
    * Id of the Investigation this Query corresponds to.
    */
    // @ApiMember(DataType="Guid", Description="Id of the Investigation this Query corresponds to.", IsRequired=true)
    public investigationId: string;

    /**
    * First name of the person associated with the Investigation Query
    */
    // @ApiMember(DataType="string", Description="First name of the person associated with the Investigation Query")
    public firstName: string;

    /**
    * Last name of the person associated with the Investigation Query
    */
    // @ApiMember(DataType="string", Description="Last name of the person associated with the Investigation Query")
    public lastName: string;

    /**
    * Middle initial of the person associated with the Investigation Query
    */
    // @ApiMember(DataType="string", Description="Middle initial of the person associated with the Investigation Query")
    public middleInitial: string;

    /**
    * AKAs of the person associated with the Investigation Query
    */
    // @ApiMember(DataType="string", Description="AKAs of the person associated with the Investigation Query")
    public aka: string;

    /**
    * DOB of the person associated with the Investigation Query
    */
    // @ApiMember(DataType="DateTime", Description="DOB of the person associated with the Investigation Query")
    public dateOfBirth?: string;

    /**
    * Sex of the person associated with the Investigation Query
    */
    // @ApiMember(DataType="string", Description="Sex of the person associated with the Investigation Query")
    public sex: string;

    /**
    * Race of the person associated with the Investigation Query
    */
    // @ApiMember(DataType="string", Description="Race of the person associated with the Investigation Query")
    public race: string;

    /**
    * The type of search to do: LENSS, DMV, and/or CCH.
    */
    // @ApiMember(Description="The type of search to do: LENSS, DMV, and/or CCH.")
    public searchTypes: string[];

    public faceMatchType: string;
    /**
    * Query type either CriminalJustice/Employment for now.
    */
    // @ApiMember(Description="Query type either CriminalJustice/Employment for now.")
    public purposeCode: string;

    /**
    * Tattoo Description
    */
    // @ApiMember(Description="Tattoo Description")
    public tattooDescription: string;

    /**
    * Tattoo Colors.
    */
    // @ApiMember(Description="Tattoo Colors.")
    public tattooColors: string;

    /**
    * Tattoo Affiliations
    */
    // @ApiMember(Description="Tattoo Affiliations")
    public tattooAffiliations: string;

    /**
    * Social Media Profile Url
    */
    // @ApiMember(Description="Social Media Profile Url")
    public profileUrl: string;

    /**
    * Social Media Profile Description
    */
    // @ApiMember(Description="Social Media Profile Description")
    public profileDescription: string;

    /**
    * Social Media Profile Ids
    */
    // @ApiMember(Description="Social Media Profile Ids")
    public profileTypeId?: number;

    /**
    * Object to save notes on follow up queries from state response. This is only applicable to some of the state queries.
    */
    // @ApiMember(DataType="object", Description="Object to save notes on follow up queries from state response. This is only applicable to some of the state queries.")
    public responseFollowUpInfo: StateResponseFollowUpInfo;

    public wantedPersonOnly: boolean;
    public investigationTypeId: number;
    public fullQuickTagQuery: boolean;
    public fullDriversLicenseQuery: boolean;
    public socialSecurityNumber: string;
    public fullName: string;
    public searchDMVAndNCIC: boolean;
    public notes: string;

    public constructor(init?: Partial<CreateInvestigationQuery>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateInvestigationQuery'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InvestigationQueryDto(); }
}

// @Route("/api/InvestigationQueries/{QueryIds}/NcicData")
export class GetNcicData implements IReturn<NcicDataResponse[]>, IGet
{
    public queryIds: string[];
    public hasStateSwitchAccess?: boolean;
    public stateRequestLog: StateRequestLog[];

    public constructor(init?: Partial<GetNcicData>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetNcicData'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<NcicDataResponse>(); }
}

// @Route("/api/InvestigationQueries/{Id}", "PUT")
export class UpdateInvestigationQuery implements IReturnVoid
{
    public id: string;
    public related: boolean;

    public constructor(init?: Partial<UpdateInvestigationQuery>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateInvestigationQuery'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Investigations", "GET")
export class SearchInvestigation extends QueryDb_2<Investigation, InvestigationResponse> implements IReturn<QueryResponse<InvestigationResponse>>, IQuerySharable
{
    // @ApiMember()
    public utnStartsWith: string;

    // @ApiMember()
    public utn: string;

    // @ApiMember()
    public createdAtGreaterThanOrEqualTo?: string;

    // @ApiMember()
    public createdAtLessThanOrEqualTo?: string;

    // @ApiMember()
    public updatedAtLessThanOrEqualTo?: string;

    // @ApiMember()
    public updatedAtGreaterThanOrEqualTo?: string;

    // @ApiMember()
    public createdAtBetween: string[];

    // @ApiMember()
    public startTimeBetween: string[];

    // @ApiMember()
    public startTimeGreaterThanOrEqualTo?: string;

    // @ApiMember()
    public startTimeLessThanOrEqualTo?: string;

    // @ApiMember()
    public stopTimeBetween: string[];

    // @ApiMember()
    public stopTimeGreaterThanOrEqualTo?: string;

    // @ApiMember()
    public stopTimeLessThanOrEqualTo?: string;

    // @ApiMember()
    public investigationTypeId?: number;

    // @ApiMember()
    public investigationStateId?: number;

    // @ApiMember()
    public userId?: number;

    /**
    * The dispositions that the Investigation had.
    */
    // @ApiMember(AllowMultiple=true, DataType="int[]", Description="The dispositions that the Investigation had.")
    public dispositionTypeIds: number[];

    // @ApiMember()
    public hasDisposition?: boolean;

    // @ApiMember()
    public visibleToUserId?: number;

    // @ApiMember()
    public ownershipTypeId?: number;

    public investigationTypeIdNotIn: number[];
    public hasQuery?: boolean;

    public constructor(init?: Partial<SearchInvestigation>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchInvestigation'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<InvestigationResponse>(); }
}

// @Route("/api/IncompleteInvestigations", "GET")
export class SearchIncompleteInvestigations extends QueryDb_2<Investigation, SearchIncompleteInvestigationResponse> implements IReturn<QueryResponse<SearchIncompleteInvestigationResponse>>
{
    public agencyId?: number;
    public userFirstNameContains: string;
    public userLastNameContains: string;
    public investigationTypeId?: number;
    public investigationTypeIdNotIn: number[];
    public hasQueries?: boolean;

    public constructor(init?: Partial<SearchIncompleteInvestigations>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchIncompleteInvestigations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<SearchIncompleteInvestigationResponse>(); }
}

// @Route("/api/Investigations/{Id}", "GET")
export class GetInvestigation implements IReturn<InvestigationResponse>
{
    public id: string;

    public constructor(init?: Partial<GetInvestigation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInvestigation'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvestigationResponse(); }
}

export class CompleteInvestigation implements IReturn<InvestigationResponse>
{
    /**
    * Id of the Investigation to be updated.
    */
    // @ApiMember(DataType="Guid", Description="Id of the Investigation to be updated.", IsRequired=true)
    public id: string;

    /**
    * Notes of the Investigation taken by creator
    */
    // @ApiMember(DataType="string", Description="Notes of the Investigation taken by creator")
    public notes: string;

    /**
    * Case number of the Investigation
    */
    // @ApiMember(DataType="string", Description="Case number of the Investigation")
    public caseNumber: string;

    /**
    * Notes of the Investigation taken by dispatch
    */
    // @ApiMember(DataType="string", Description="Notes of the Investigation taken by dispatch")
    public dispatchNotes: string;

    /**
    * List of Dispositions associated witht the Investigation. <a href="/swagger-ui/#!/api/DispositionTypeRequest">See Dispositions</a>.
    */
    // @ApiMember(DataType="int", Description="List of Dispositions associated witht the Investigation. <a href=\"/swagger-ui/#!/api/DispositionTypeRequest\">See Dispositions</a>.")
    public dispositions: number[];

    public media: number[];
    public conversionData: GetNcicConversionSummary;
    public createAlertWithPacket: boolean;
    public createAlertWithIntel: boolean;
    public completionReason: string;

    public constructor(init?: Partial<CompleteInvestigation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CompleteInvestigation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InvestigationResponse(); }
}

// @Route("/api/Investigations", "PUT")
// @Route("/api/Investigations/{Id}", "PUT")
export class UpdateInvestigation implements IReturn<string>
{
    /**
    * Id of the Investigation to be updated.
    */
    // @ApiMember(DataType="Guid", Description="Id of the Investigation to be updated.", IsRequired=true)
    public id: string;

    /**
    * Notes of the Investigation taken by creator
    */
    // @ApiMember(DataType="string", Description="Notes of the Investigation taken by creator")
    public notes: string;

    /**
    * Case number of the Investigation
    */
    // @ApiMember(DataType="string", Description="Case number of the Investigation")
    public caseNumber: string;

    /**
    * Notes of the Investigation taken by dispatch
    */
    // @ApiMember(DataType="string", Description="Notes of the Investigation taken by dispatch")
    public dispatchNotes: string;

    /**
    * Id of the state associated with the Investigation. <a href="/swagger-ui/#!/api/StateRequest">See States</a>.
    */
    // @ApiMember(DataType="int", Description="Id of the state associated with the Investigation. <a href=\"/swagger-ui/#!/api/StateRequest\">See States</a>.", IsRequired=true)
    public investigationStateId: number;

    /**
    * List of Dispositions associated witht the Investigation. <a href="/swagger-ui/#!/api/DispositionTypeRequest">See Dispositions</a>.
    */
    // @ApiMember(DataType="int", Description="List of Dispositions associated witht the Investigation. <a href=\"/swagger-ui/#!/api/DispositionTypeRequest\">See Dispositions</a>.")
    public dispositions: number[];

    public media: number[];

    public constructor(init?: Partial<UpdateInvestigation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateInvestigation'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return ''; }
}

// @Route("/api/Investigations", "POST")
export class CreateInvestigation implements IReturn<InvestigationIdResponse>
{
    /**
    * Type of Investigation that this corresponds to.
    */
    // @ApiMember(DataType="int", Description="Type of Investigation that this corresponds to.", IsRequired=true)
    public investigationTypeId: number;

    /**
    * The user that the Investigation is being created for. This user will be the owner of the Investigation.
    */
    // @ApiMember(DataType="int", Description="The user that the Investigation is being created for. This user will be the owner of the Investigation.")
    public createdForId?: number;

    /**
    * Notes of the Investigation taken by creator
    */
    // @ApiMember(DataType="string", Description="Notes of the Investigation taken by creator")
    public notes: string;

    /**
    * Case number of the Investigation
    */
    // @ApiMember(DataType="string", Description="Case number of the Investigation")
    public caseNumber: string;

    /**
    * Notes of the Investigation taken by dispatch
    */
    // @ApiMember(DataType="string", Description="Notes of the Investigation taken by dispatch")
    public dispatchNotes: string;

    /**
    * To go ahead and run the initial query in an Investigation, this is the Identification Type Id of the query
    */
    // @ApiMember(DataType="int", Description="To go ahead and run the initial query in an Investigation, this is the Identification Type Id of the query")
    public quickRunIdentificationTypeId?: number;

    /**
    * To go ahead and run the initial query in an Investigation, this is the State Id of the query
    */
    // @ApiMember(DataType="int", Description="To go ahead and run the initial query in an Investigation, this is the State Id of the query")
    public quickRunStateId?: number;

    /**
    * To go ahead and run the initial query in an Investigation, this is the Identification Number of the query
    */
    // @ApiMember(DataType="string", Description="To go ahead and run the initial query in an Investigation, this is the Identification Number of the query")
    public quickRunIdentificationNumber: string;

    public constructor(init?: Partial<CreateInvestigation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateInvestigation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InvestigationIdResponse(); }
}

export class InvestigationUpdate implements IReturnVoid
{
    public investigationId: string;
    public investigationQueryId?: string;
    public stateResponseLogId?: string;
    public hasError: boolean;
    public media: MediaDto[];
    public skipUser?: number;

    public constructor(init?: Partial<InvestigationUpdate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvestigationUpdate'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/Investigations/{Id}/ConvertQuickTagToFullInvestigation", "PUT")
export class ConvertQuickTagToFullInvestigation implements IReturn<InvestigationIdResponse>
{
    public id: string;
    public investigationTypeId: number;

    public constructor(init?: Partial<ConvertQuickTagToFullInvestigation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConvertQuickTagToFullInvestigation'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new InvestigationIdResponse(); }
}

// @Route("/api/Investigation/{Id}", "PUT")
export class UpdateInvestigationType implements IReturnVoid
{
    public id: string;
    public investigationTypeId: number;

    public constructor(init?: Partial<UpdateInvestigationType>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateInvestigationType'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/LPR", "POST")
export class PostLPR implements IReturn<LPRResponse>
{
    public fileName: string;
    public fileData: string;

    public constructor(init?: Partial<PostLPR>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostLPR'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new LPRResponse(); }
}

// @Route("/api/LPR/Check", "GET")
export class CheckLicensePlate implements IReturn<CheckLicensePlateResponse>
{
    public licensePlateNumber: string;
    public stateId: number;

    public constructor(init?: Partial<CheckLicensePlate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CheckLicensePlate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CheckLicensePlateResponse(); }
}

// @Route("/api/media/{Id}/delete", "DELETE")
export class DeleteMedia implements IReturnVoid
{
    public id: number;

    public constructor(init?: Partial<DeleteMedia>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteMedia'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api/media", "GET")
export class GetMediaByParent implements IReturn<MediaDto[]>
{
    public parentId: string;

    public constructor(init?: Partial<GetMediaByParent>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMediaByParent'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<MediaDto>(); }
}

// @Route("/api/media", "POST")
export class UploadMedia implements IReturn<MediaDto[]>
{
    public parentType: string;
    public parentId?: string;

    public constructor(init?: Partial<UploadMedia>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadMedia'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Array<MediaDto>(); }
}

// @Route("/api/media/{Id}", "GET")
export class GetMedia implements IReturn<Object>
{
    public id: number;
    public size: string;

    public constructor(init?: Partial<GetMedia>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMedia'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Object(); }
}

// @Route("/api/MediaStore", "GET")
export class SearchMediaStore extends QueryDb_2<MediaStore, MediaStoreResponse> implements IReturn<QueryResponse<MediaStoreResponse>>
{
    public userId: number;

    public constructor(init?: Partial<SearchMediaStore>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchMediaStore'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<MediaStoreResponse>(); }
}

// @Route("/api/MediaStore", "POST")
export class UploadMediaStoreItem implements IReturn<MediaStoreResponse>
{

    public constructor(init?: Partial<UploadMediaStoreItem>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UploadMediaStoreItem'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MediaStoreResponse(); }
}

// @Route("/api/MediaStore/{Id}", "DELETE")
export class DeleteMediaStoreItem implements IReturnVoid
{
    public id: string;

    public constructor(init?: Partial<DeleteMediaStoreItem>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteMediaStoreItem'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api/UnreadMessages", "GET")
export class GetUnreadMessages implements IReturn<GetUnreadMessagesResponse>
{
    public conversationIds: string[];

    public constructor(init?: Partial<GetUnreadMessages>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUnreadMessages'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUnreadMessagesResponse(); }
}

// @Route("/api/Messages", "POST")
export class CreateMessage implements IReturn<MessageResponse>
{
    public conversationId: string;
    public content: string;
    public userSentAt: string;
    public media: number[];

    public constructor(init?: Partial<CreateMessage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateMessage'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MessageResponse(); }
}

// @Route("/api/Conversations/{ConversationId}/Messages", "GET")
export class SearchMessages extends QueryDb_2<Message, MessageResponse> implements IReturn<QueryResponse<MessageResponse>>
{
    public conversationId: string;
    public createdAtGreaterThanOrEqualTo?: string;
    public createdAtLessThanOrEqualTo?: string;

    public constructor(init?: Partial<SearchMessages>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchMessages'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<MessageResponse>(); }
}

// @Route("/api/Conversations/{ConversationId}/LastRead", "PUT")
export class MarkConversationAsRead implements IReturnVoid
{
    public conversationId: string;

    public constructor(init?: Partial<MarkConversationAsRead>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MarkConversationAsRead'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Conversations", "POST")
export class CreateConversation implements IReturn<ConversationResponse>
{
    public userIds: number[];
    public divisionIds: number[];

    public constructor(init?: Partial<CreateConversation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateConversation'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConversationResponse(); }
}

// @Route("/api/Conversations/{ConversationId}/Particpants", "POST")
export class AddConversationParticpants implements IReturn<ConversationResponse>
{
    public conversationId: string;
    public userIds: number[];
    public divisionIds: number[];

    public constructor(init?: Partial<AddConversationParticpants>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AddConversationParticpants'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConversationResponse(); }
}

// @Route("/api/Conversations/{ConversationId}/Participants", "DELETE")
export class RemoveConversationParticpants implements IReturnVoid
{
    public conversationId: string;
    public userIds: number[];
    public divisionIds: number[];
    public leaving: boolean;

    public constructor(init?: Partial<RemoveConversationParticpants>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RemoveConversationParticpants'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api/Conversations", "GET")
export class SearchConversations extends QueryDb_2<Conversation, ConversationResponse> implements IReturn<QueryResponse<ConversationResponse>>
{
    public searchTerm: string;

    public constructor(init?: Partial<SearchConversations>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchConversations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<ConversationResponse>(); }
}

// @Route("/api/Conversations/{ConversationId}", "GET")
export class GetConversation implements IReturn<ConversationResponse>
{
    public conversationId: string;
    public isStartConversationOperation: boolean;

    public constructor(init?: Partial<GetConversation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetConversation'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConversationResponse(); }
}

// @Route("/api/Conversations/{AgencyId}/{SearchTerm}/GetUsersAndDivisions", "GET")
export class GetUsersAndDivisions implements IReturn<UsersAndDivisionsResponse[]>
{
    public searchTerm: string;
    public agencyId: number;
    public userState?: number;
    public conversationId: string;

    public constructor(init?: Partial<GetUsersAndDivisions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUsersAndDivisions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<UsersAndDivisionsResponse>(); }
}

// @Route("/api/Conversations/UpdateConversationNotification/{ConversationId}/{ParticipantId}", "POST")
export class UpdateConversationNotification implements IReturnVoid
{
    public isNotificationEnabled: boolean;
    public participantId: number;
    public conversationId: string;

    public constructor(init?: Partial<UpdateConversationNotification>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateConversationNotification'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/Messages/{ConversationId}/Message", "DELETE")
export class DeleteMessage implements IReturnVoid
{
    public conversationId: string;
    public messageId: string;
    public partial: boolean;

    public constructor(init?: Partial<DeleteMessage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteMessage'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api/Messages/{ConversationId}/UndoMessage", "PUT")
export class UndoMessage implements IReturn<MessageResponse>
{
    public conversationId: string;
    public messageId: string;

    public constructor(init?: Partial<UndoMessage>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UndoMessage'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new MessageResponse(); }
}

// @Route("/api/NameSearch", "POST")
export class NameSearchMoreRequest implements IReturnVoid
{
    public queryId: string;
    public isDmvCandidateRequest: boolean;

    public constructor(init?: Partial<NameSearchMoreRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'NameSearchMoreRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/NameSearch/RapSheet")
export class CriminalHistoryRapsheetRequest implements IReturnVoid
{
    public queryId: string;
    public sid: string;

    public constructor(init?: Partial<CriminalHistoryRapsheetRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CriminalHistoryRapsheetRequest'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/Notes", "GET")
export class SearchNotes extends QueryDb_2<EntityNote, NoteResponse> implements IReturn<QueryResponse<NoteResponse>>
{
    /**
    * The entity type of the notes you want. One of SubjectPacket or Intel.
    */
    // @ApiMember(Description="The entity type of the notes you want. One of SubjectPacket or Intel.")
    public entityType: string;

    /**
    * The entitie id of the notes you want.
    */
    // @ApiMember(Description="The entitie id of the notes you want.")
    public entityId: string;

    public constructor(init?: Partial<SearchNotes>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchNotes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<NoteResponse>(); }
}

// @Route("/api/Notes", "POST")
export class CreateNote implements IReturn<NoteResponse>
{
    public note: string;
    public entityType: string;
    public entityId: string;

    public constructor(init?: Partial<CreateNote>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateNote'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new NoteResponse(); }
}

// @Route("/api/Notifications", "GET")
export class SearchNotifications extends QueryDb_2<Notification, NotificationResponse> implements IReturn<QueryResponse<NotificationResponse>>
{
    public read?: boolean;
    public entityType: string;
    public action: string;
    public senderNameContains: string;

    public constructor(init?: Partial<SearchNotifications>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchNotifications'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<NotificationResponse>(); }
}

// @Route("/api/Notifications/MarkRead", "PUT")
export class MarkNotificationRead implements IReturnVoid
{
    public id: string;

    public constructor(init?: Partial<MarkNotificationRead>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MarkNotificationRead'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Notifications/MarkAllRead", "PUT")
export class MarkAllRead implements IReturnVoid
{

    public constructor(init?: Partial<MarkAllRead>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MarkAllRead'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Notifications/{Id}", "Delete")
export class DeleteNotification implements IReturnVoid
{
    public id: string;

    public constructor(init?: Partial<DeleteNotification>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteNotification'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api/NotificationSettings", "GET")
export class SearchNotificationSettings extends QueryDb_1<NotificationSetting> implements IReturn<QueryResponse<NotificationSetting>>
{
    public userId?: number;

    public constructor(init?: Partial<SearchNotificationSettings>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchNotificationSettings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<NotificationSetting>(); }
}

// @Route("/api/NotificationSettings", "PUT")
export class UpdateNotificationSetting implements IReturn<NotificationSettingDto>
{
    public id: string;
    public userId: number;
    public notificationType: string;
    public notificationMechanism: string;
    public enabled: boolean;

    public constructor(init?: Partial<UpdateNotificationSetting>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateNotificationSetting'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new NotificationSettingDto(); }
}

// @Route("/api/SubjectPackets", "GET")
export class SearchSubjectPackets extends QueryDb_2<SubjectPacket, SubjectPacketResponse> implements IReturn<QueryResponse<SubjectPacketResponse>>, IQuerySharable
{
    public assignedTo?: number;
    public assignedToNotEqualTo?: number;
    public createdBy?: number;
    public createdByNotEqualTo?: number;
    public agencyId?: number;
    public utnStartsWith: string;
    public createdAtBetween: string[];
    public createdAtGreaterThanOrEqualTo?: string;
    public createdAtLessThanOrEqualTo?: string;
    public reviewDateBetween: string[];
    public reviewDateGreaterThanOrEqualTo?: string;
    public reviewDateLessThanOrEqualTo?: string;
    public updatedAtGreaterThanOrEqualTo?: string;
    public updatedAtLessThanOrEqualTo?: string;
    public subjectPacketStateId?: number;
    public subjectPacketStateName: string;
    public subjectTypeId?: number;
    public subjectTypeName: string;
    public subjectTypeIdIn: number[];
    public subjectPacketTypeId?: number;
    public subjectPacketTypeIdIn: number[];
    public developmentStatusId?: number;
    public developmentStatusIdIn: number[];
    public processing?: boolean;
    public deactivationReasonContains: string;
    public stateChangeApprovedByUserId?: number;
    public visibleToUserId?: number;
    public ownershipTypeId?: number;
    public reportNumberContains: string;
    public nameContains: string;
    public subjectPacketClassificationId?: number;
    public notes: string;
    public identification: string;

    public constructor(init?: Partial<SearchSubjectPackets>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchSubjectPackets'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<SubjectPacketResponse>(); }
}

// @Route("/api/SubjectPackets/{id}", "GET")
export class GetSubjectPacket implements IReturn<SubjectPacketResponse>
{
    // @Required()
    public id: string;

    public constructor(init?: Partial<GetSubjectPacket>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSubjectPacket'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SubjectPacketResponse(); }
}

// @Route("/api/SubjectPackets/{id}/InvestigationHits", "GET")
export class GetSubjectPacketInvestigationHits implements IReturn<CondensedInvestigationResponse[]>
{
    // @Required()
    public id: string;

    public constructor(init?: Partial<GetSubjectPacketInvestigationHits>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSubjectPacketInvestigationHits'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<CondensedInvestigationResponse>(); }
}

// @Route("/api/SubjectPackets/{id}/PriorInvestigations", "GET")
export class GetSubjectPacketPriorInvestigations implements IReturn<CondensedInvestigationResponse[]>
{
    // @Required()
    public id: string;

    public constructor(init?: Partial<GetSubjectPacketPriorInvestigations>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSubjectPacketPriorInvestigations'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<CondensedInvestigationResponse>(); }
}

// @Route("/api/SubjectPackets", "PUT")
export class UpdateSubjectPacket implements IReturnVoid
{
    /**
    * Id of the Subject Packet to be updated.
    */
    // @Required()
    // @ApiMember(DataType="Guid", Description="Id of the Subject Packet to be updated.", IsRequired=true)
    public id: string;

    /**
    * Name of the Subject Packet to be updated
    */
    // @ApiMember(DataType="string", Description="Name of the Subject Packet to be updated")
    public name: string;

    /**
    * List of Identifications related to this Subject Packet.
    */
    // @ApiMember(DataType="List", Description="List of Identifications related to this Subject Packet.", IsRequired=true)
    public identifications: IdentificationDto[];

    /**
    * List of Division ids related to this Subject Packet.
    */
    // @ApiMember(DataType="List", Description="List of Division ids related to this Subject Packet.")
    public divisions: number[];

    /**
    * List of user ids related to this Subject Packet.
    */
    // @ApiMember(DataType="List", Description="List of user ids related to this Subject Packet.")
    public users: number[];

    /**
    * Review date of the Subject Packet.
    */
    // @ApiMember(DataType="DateTime", Description="Review date of the Subject Packet.")
    public reviewDate?: string;

    /**
    * User id assigned to the Subject Packet.
    */
    // @ApiMember(DataType="int", Description="User id assigned to the Subject Packet.")
    public assignedTo?: number;

    public media: number[];
    public mediaToDelete: number[];
    public subjectPacketClassificationId?: number;
    public subjectTypeId?: number;
    public reportNumber: string;
    /**
    * Public notes for this Packet
    */
    // @ApiMember(Description="Public notes for this Packet")
    public publicNotes: string;

    /**
    * Public notes for this Packet (only the creator can see these)
    */
    // @ApiMember(Description="Public notes for this Packet (only the creator can see these)")
    public privateNotes: string;

    /**
    * The Development Status id (1 = In Development, 2 = Complete). Default: 1
    */
    // @ApiMember(Description="The Development Status id (1 = In Development, 2 = Complete). Default: 1")
    public developmentStatusId?: number;

    public isJuvenile: boolean;
    public isPrivate: boolean;
    public privateDivisions: number[];
    public firstName: string;
    public lastName: string;
    public middleInitial: string;
    public dateOfBirth?: string;
    public sex: string;
    public race: string;
    public locationAddress: string;
    public locationDescription: string;

    public constructor(init?: Partial<UpdateSubjectPacket>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSubjectPacket'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/SubjectPackets/{Id}/PendingDeactivate", "PUT")
export class MarkPacketPendingDeactivate implements IReturn<SubjectPacketResponse>
{
    public id: string;
    public deactivationReason: string;
    public supervisorUserId?: number;

    public constructor(init?: Partial<MarkPacketPendingDeactivate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MarkPacketPendingDeactivate'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new SubjectPacketResponse(); }
}

// @Route("/api/SubjectPackets/{Id}/Reactivate", "PUT")
export class MarkPacketReactivate implements IReturn<SubjectPacketResponse>
{
    public id: string;
    public reactivationReason: string;

    public constructor(init?: Partial<MarkPacketReactivate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MarkPacketReactivate'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new SubjectPacketResponse(); }
}

// @Route("/api/SubjectPackets/{Id}/ApproveDeactivate", "PUT")
export class ApprovePacketDeactivation implements IReturnVoid
{
    public id: string;

    public constructor(init?: Partial<ApprovePacketDeactivation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ApprovePacketDeactivation'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/SubjectPackets/{Id}/RejectDeactivate", "PUT")
export class RejectPacketDeactivation implements IReturnVoid
{
    public id: string;

    public constructor(init?: Partial<RejectPacketDeactivation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RejectPacketDeactivation'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/importSubjectPackets", "POST")
export class ImportSubjectPackets implements IReturnVoid
{

    public constructor(init?: Partial<ImportSubjectPackets>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportSubjectPackets'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/PermissionGroups", "GET")
export class SearchPermissionGroups extends QueryDb_2<PermissionGroup, PermissionGroupResponse> implements IReturn<QueryResponse<PermissionGroupResponse>>
{
    public id?: string;
    public nameContains: string;
    public systemManaged?: boolean;
    public agencyId?: number;

    public constructor(init?: Partial<SearchPermissionGroups>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchPermissionGroups'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<PermissionGroupResponse>(); }
}

// @Route("/api/PermissionGroups/{Id}", "GET")
export class GetPermissionGroup implements IReturn<PermissionGroupResponse>
{
    public id: string;

    public constructor(init?: Partial<GetPermissionGroup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPermissionGroup'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PermissionGroupResponse(); }
}

// @Route("/api/PermissionGroups", "POST")
export class CreatePermissionGroup implements IReturn<PermissionGroupResponse>
{
    public name: string;
    public agencyId?: number;
    public systemManaged: boolean;
    public permissions: string[];

    public constructor(init?: Partial<CreatePermissionGroup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreatePermissionGroup'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PermissionGroupResponse(); }
}

// @Route("/api/PermissionGroups", "PUT")
export class UpdatePermissionGroup implements IReturn<PermissionGroupResponse>
{
    public id: string;
    public name: string;
    public permissions: string[];

    public constructor(init?: Partial<UpdatePermissionGroup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatePermissionGroup'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new PermissionGroupResponse(); }
}

// @Route("/api/PermissionGroups/{Id}", "DELETE")
export class DeletePermissionGroup implements IReturnVoid
{
    public id: string;

    public constructor(init?: Partial<DeletePermissionGroup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeletePermissionGroup'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api/Agencies/{Id}/Permissions", "GET")
export class SearchAgencyPermissions implements IReturn<Permission[]>
{
    public id: number;

    public constructor(init?: Partial<SearchAgencyPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchAgencyPermissions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<Permission>(); }
}

// @Route("/api/PhotoLineupDataset", "GET")
export class SearchPhotoLineupDataset implements IReturn<PhotoLineupDatatsetResponse>
{
    public race: string;
    public sex: string;
    public build: string;
    public eyeColor: string;
    public eyeSight: string;
    public hairStyle: string;
    public hairColor: string;
    public beard: string;
    public mustache: string;
    public ageFrom: number;
    public ageTo: number;
    public weightFrom: number;
    public weightTo: number;
    public heightInFeetFrom: number;
    public heightInInchFrom: number;
    public heightInFeetTo: number;
    public heightInInchTo: number;
    public computedHeightFrom: number;
    public computedHeightTo: number;
    public count: number;
    public page: number;

    public constructor(init?: Partial<SearchPhotoLineupDataset>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchPhotoLineupDataset'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PhotoLineupDatatsetResponse(); }
}

// @Route("/api/PhotLineupMetadata/Update/{Id}", "PUT")
export class UpdatePhotoLineupMetadata implements IReturn<PhotoLineupDatatsetResult>
{
    public id: string;
    public race: string;
    public sex: string;
    public build: string;
    public eyeColor: string;
    public eyeSight: string;
    public hairStyle: string;
    public hairColor: string;
    public beard: string;
    public mustache: string;
    public birthYear?: number;
    public weight?: number;
    public heightInFeet: number;
    public heightInInch: number;
    public mediaId: number;
    public name: string;
    public driverLicenseNumber: string;
    public height?: number;
    public count: number;

    public constructor(init?: Partial<UpdatePhotoLineupMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatePhotoLineupMetadata'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new PhotoLineupDatatsetResult(); }
}

// @Route("/api/PhotLineupMetadata/Delete/{Id}", "DELETE")
export class DeletePhotoLineupMetadata implements IReturnVoid
{
    public id: string;

    public constructor(init?: Partial<DeletePhotoLineupMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeletePhotoLineupMetadata'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api/PhotoLineup", "GET")
export class SearchPhotoLineup extends QueryDb_2<PhotoLineup, PhotoLineupResponse> implements IReturn<QueryResponse<PhotoLineupResponse>>, IQuerySharable
{
    public titleContains: string;
    public createdBy?: number;
    public createdAtLessThanOrEqualTo?: string;
    public createdAtGreaterThanOrEqualTo?: string;
    public updatedAtLessThanOrEqualTo?: string;
    public updatedAtGreaterThanOrEqualTo?: string;
    public utnStartsWith: string;
    public visibleToUserId?: number;
    public ownershipTypeId?: number;

    public constructor(init?: Partial<SearchPhotoLineup>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchPhotoLineup'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<PhotoLineupResponse>(); }
}

// @Route("/api/PhotoLineups", "POST")
export class CreatePhotoLineup implements IReturn<PhotoLineupResponse>
{
    /**
    * Title of the Photo Line
    */
    // @ApiMember(DataType="string", Description="Title of the Photo Line")
    public title: string;

    /**
    * Media associated with this Photo Line
    */
    // @ApiMember(DataType="List", Description="Media associated with this Photo Line")
    public media: number[];

    /**
    * Media associated with this Photo Line
    */
    // @ApiMember(DataType="List", Description="Media associated with this Photo Line")
    public photoLineupMedias: number[];

    public assignedTo?: number;

    public constructor(init?: Partial<CreatePhotoLineup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreatePhotoLineup'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PhotoLineupResponse(); }
}

export class UpdatePhotoLineup implements IReturn<PhotoLineupResponse>
{
    /**
    * Identification of the PhotoLineup to be updated.
    */
    // @ApiMember(DataType="Guid", Description="Identification of the PhotoLineup to be updated.", IsRequired=true)
    public id: string;

    /**
    * Title of the Photo Line
    */
    // @ApiMember(DataType="string", Description="Title of the Photo Line")
    public title: string;

    public mediaToAdd: number[];
    public mediaToDelete: number[];
    /**
    * Media associated with this Photo Line
    */
    // @ApiMember(DataType="List", Description="Media associated with this Photo Line")
    public photoLineupMedias: number[];

    public constructor(init?: Partial<UpdatePhotoLineup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdatePhotoLineup'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new PhotoLineupResponse(); }
}

// @Route("/api/PhotLineup/{Id}", "GET")
export class GetPhotoLineup implements IReturn<PhotoLineupResponse>
{
    public id: string;

    public constructor(init?: Partial<GetPhotoLineup>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPhotoLineup'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PhotoLineupResponse(); }
}

// @Route("/api/PhotLineup/Media", "GET")
export class GetMediaForPhotoLineUpByMediaId implements IReturn<PhotoLineUpMediaResult>
{
    public mediaId: number;

    public constructor(init?: Partial<GetMediaForPhotoLineUpByMediaId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMediaForPhotoLineUpByMediaId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PhotoLineUpMediaResult(); }
}

// @Route("/api/PhotLineup/Media", "GET")
export class GetMediaForPhotoLine implements IReturn<PhotoLineUpMediaResult>
{
    public race: string;
    public sex: string;
    public build: string;
    public eyeColor: string;
    public eyeSight: string;
    public hairStyle: string;
    public hairColor: string;
    public beard: string;
    public mustache: string;
    public ageFrom: number;
    public ageTo: number;
    public weightFrom: number;
    public weightTo: number;
    public heightInFeetFrom: number;
    public heightInInchFrom: number;
    public heightInFeetTo: number;
    public heightInInchTo: number;
    public mediaHashesToSkip: string[];
    public computedHeightFrom: number;
    public computedHeightTo: number;
    public count: number;
    public page: number;

    public constructor(init?: Partial<GetMediaForPhotoLine>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMediaForPhotoLine'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PhotoLineUpMediaResult(); }
}

// @Route("/api/PhotLineup/Metadata", "POST")
export class CreatePhotoLineupMetadata implements IReturn<PhotoLineupMetadataResponse[]>
{
    public metadata: CreatePhotoRequest[];

    public constructor(init?: Partial<CreatePhotoLineupMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreatePhotoLineupMetadata'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Array<PhotoLineupMetadataResponse>(); }
}

// @Route("/Investigations/{Id}/Report", "POST")
export class CreateInvestigationReport implements IReturn<CreateReportResponse>
{
    public id: string;
    public stateResponseLogsToPrint: string[];

    public constructor(init?: Partial<CreateInvestigationReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateInvestigationReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateReportResponse(); }
}

// @Route("/Reports/{Id}")
export class GetReport implements IReturn<string>
{
    public id: string;

    public constructor(init?: Partial<GetReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetReport'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return ''; }
}

// @Route("/api/Seizures", "GET")
export class SearchSeizure extends QueryDb_2<Seizure, SeizureResponse> implements IReturn<QueryResponse<SeizureResponse>>
{
    public userId?: number;
    public seizureTypeId?: number;
    public caseNumberStartsWith: string;
    public utn: string;

    public constructor(init?: Partial<SearchSeizure>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchSeizure'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<SeizureResponse>(); }
}

// @Route("/api/Seizures/{Id}", "GET")
export class GetSeizure implements IReturn<SeizureResponse>
{
    // @Required()
    public id: string;

    public constructor(init?: Partial<GetSeizure>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSeizure'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SeizureResponse(); }
}

// @Route("/api/Seizures", "PUT")
// @Route("/api/Seizures/{Id}", "PUT")
export class UpdateSeizure implements IReturnVoid
{
    /**
    * Id of the Seizure to be updated.
    */
    // @Required()
    // @ApiMember(DataType="Guid", Description="Id of the Seizure to be updated.", IsRequired=true)
    public id: string;

    /**
    * Id of the Subject Packet that this corresponds to.
    */
    // @ApiMember(DataType="Guid", Description="Id of the Subject Packet that this corresponds to.")
    public subjectPacketId?: string;

    /**
    * The Investigation Id that resulted in this seizure.
    */
    // @ApiMember(DataType="Guid", Description="The Investigation Id that resulted in this seizure.")
    public userSubjectPacketBatchId?: string;

    /**
    * The Jursidiction of the Seizure (District Attorney Agency).
    */
    // @ApiMember(DataType="int", Description="The Jursidiction of the Seizure (District Attorney Agency).")
    public districtAttorneyAgencyId?: number;

    /**
    * User defined case number.
    */
    // @ApiMember(DataType="string", Description="User defined case number.")
    public caseNumber: string;

    /**
    * Items to update. If null, nothing happens. If empty, clears current items. If an item has an id, that item is updated. If item list omits an item, it will be deleted.
    */
    // @ApiMember(DataType="List", Description="Items to update. If null, nothing happens. If empty, clears current items. If an item has an id, that item is updated. If item list omits an item, it will be deleted.")
    public seizedItems: SeizedItemDto[];

    /**
    * Media ids associated with Seizure
    */
    // @ApiMember(DataType="List", Description="Media ids associated with Seizure")
    public media: number[];

    public constructor(init?: Partial<UpdateSeizure>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateSeizure'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Seizures", "POST")
export class CreateSeizure implements IReturn<SeizureResponse>
{
    /**
    * User defined case number.
    */
    // @Required()
    // @ApiMember(DataType="string", Description="User defined case number.", IsRequired=true)
    public caseNumber: string;

    /**
    * Id of the approval supervisor.
    */
    // @Required()
    // @ApiMember(DataType="Guid", Description="Id of the approval supervisor.", IsRequired=true)
    public approvalSupervisorId: number;

    /**
    * Type of Seizure that this corresponds to. <a href="/swagger-ui/#!/api/SeizureTypeRequest">See Seizure types</a>.
    */
    // @ApiMember(DataType="Guid", Description="Type of Seizure that this corresponds to. <a href=\"/swagger-ui/#!/api/SeizureTypeRequest\">See Seizure types</a>.", IsRequired=true)
    public seizureTypeId: number;

    /**
    * Items related to this Seizure.
    */
    // @ApiMember(DataType="List", Description="Items related to this Seizure.")
    public seizedItems: SeizedItemDto[];

    /**
    * Media ids associated with Seizure
    */
    // @ApiMember(DataType="List", Description="Media ids associated with Seizure")
    public media: number[];

    public constructor(init?: Partial<CreateSeizure>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateSeizure'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SeizureResponse(); }
}

// @Route("/api/Subscriptions", "GET")
export class GetSubscriptionDetails implements IReturn<Object[]>
{
    public userId?: number;
    public subscriptionId: string;

    public constructor(init?: Partial<GetSubscriptionDetails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSubscriptionDetails'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<Object>(); }
}

// @Route("/api/RemoveExpiredSubscriptions", "GET")
export class RemoveExpiredSubscriptions implements IReturn<number>
{

    public constructor(init?: Partial<RemoveExpiredSubscriptions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RemoveExpiredSubscriptions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return 0; }
}

// @Route("/api/Subscriptions/{SubscriptionId}/Pulse", "GET")
export class PulseSubscription implements IReturn<boolean>
{
    public subscriptionId: string;

    public constructor(init?: Partial<PulseSubscription>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PulseSubscription'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return false; }
}

// @Route("/api/ResetServerEventsServer", "GET")
export class ResetServerEventsServer implements IReturnVoid
{

    public constructor(init?: Partial<ResetServerEventsServer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ResetServerEventsServer'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/api/InitRedisServer", "GET")
export class InitRedisServer implements IReturnVoid
{

    public constructor(init?: Partial<InitRedisServer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InitRedisServer'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/api/ServerEventsServer", "GET")
export class GetServerEventsServerDetails implements IReturn<Object>
{

    public constructor(init?: Partial<GetServerEventsServerDetails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetServerEventsServerDetails'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Object(); }
}

// @Route("/api/Shares", "GET")
export class GetSharesByParent extends QueryDb_2<Share, ShareResponse> implements IReturn<QueryResponse<ShareResponse>>
{
    public entityType: string;
    public entityId: string;
    public negate?: boolean;

    public constructor(init?: Partial<GetSharesByParent>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSharesByParent'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<ShareResponse>(); }
}

// @Route("/api/Shares", "POST")
export class CreateShare implements IReturn<ShareResponse>
{
    public sharedWithUserId?: number;
    public sharedWithDivisionId?: number;
    public sharedWithAgencyId?: number;
    public entityType: string;
    public entityId: string;

    public constructor(init?: Partial<CreateShare>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateShare'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ShareResponse(); }
}

// @Route("/api/Shares/Unsubscribe", "POST")
export class UnsubscribeFromShare implements IReturnVoid
{
    public entityType: string;
    public entityId: string;

    public constructor(init?: Partial<UnsubscribeFromShare>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnsubscribeFromShare'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/Shares/{Id}", "POST,DELETE")
export class DeleteShare implements IReturnVoid
{
    public id: string;

    public constructor(init?: Partial<DeleteShare>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteShare'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/GetSiteConfiguration", "GET")
export class GetSiteConfiguration implements IReturn<SiteConfiguration>
{

    public constructor(init?: Partial<GetSiteConfiguration>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteConfiguration'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SiteConfiguration(); }
}

// @Route("/api/SaveSiteConfiguration", "POST")
export class SaveSiteConfiguration implements IReturn<SiteConfiguration>
{
    public siteConfiguration: SiteConfiguration;

    public constructor(init?: Partial<SaveSiteConfiguration>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveSiteConfiguration'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SiteConfiguration(); }
}

// @Route("/api/Speak", "GET")
export class SpeakRequest implements IReturn<Blob>
{
    public text: string;
    public options: SpeechOptions;

    public constructor(init?: Partial<SpeakRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SpeakRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/api/Speak", "GET")
export class JsonSpeakRequest implements IReturn<SpeakResponse>
{
    public text: string;
    public options: SpeechOptions;

    public constructor(init?: Partial<JsonSpeakRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'JsonSpeakRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SpeakResponse(); }
}

// @Route("/api/InvestigationQueries/{Id}/Speech", "GET")
export class SpeakInvestigationQuery implements IReturn<Blob>
{
    public id: string;
    public options: SpeechOptions;

    public constructor(init?: Partial<SpeakInvestigationQuery>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SpeakInvestigationQuery'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/api/StateLogon", "GET")
export class StateLogonApiRequest implements IReturn<StateLogonApiResponse>
{
    public userName: string;
    public password: string;
    public queryId?: string;

    public constructor(init?: Partial<StateLogonApiRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StateLogonApiRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StateLogonApiResponse(); }
}

// @Route("/api/StatePasswordChange", "GET")
export class StatePasswordChangeApiRequest implements IReturn<StateLogonApiResponse>
{
    public userName: string;
    public password: string;
    public newPassword: string;
    public queryId?: string;

    public constructor(init?: Partial<StatePasswordChangeApiRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatePasswordChangeApiRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StateLogonApiResponse(); }
}

// @Route("/api/StateLogoff", "GET")
export class StateLogoffApiRequest implements IReturnVoid, IGet
{

    public constructor(init?: Partial<StateLogoffApiRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StateLogoffApiRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/api/StateHosts", "GET")
export class SearchStateHosts extends QueryDb_2<State, StateHostOperationsResponse> implements IReturn<QueryResponse<StateHostOperationsResponse>>
{
    public id?: number;

    public constructor(init?: Partial<SearchStateHosts>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchStateHosts'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<StateHostOperationsResponse>(); }
}

// @Route("/api/StateHosts/{StateId}", "GET")
export class GetStateHostDetails implements IReturn<StateHostDetails>
{
    public stateId: number;

    public constructor(init?: Partial<GetStateHostDetails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetStateHostDetails'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StateHostDetails(); }
}

// @Route("/api/StateHosts/{StateId}/Restart", "POST")
export class RestartStateHost implements IReturnVoid
{
    public stateId: number;

    public constructor(init?: Partial<RestartStateHost>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RestartStateHost'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/StateRequestLogs", "GET")
export class SearchStateRequestLogs extends QueryDb_2<StateRequestLog, SearchStateRequestLogsResponse> implements IReturn<QueryResponse<SearchStateRequestLogsResponse>>
{
    public userId?: number;
    public agencyId?: number;
    public userFullNameContains: string;
    public internalResponseType: string;
    public orderByDesc: string;
    public dateSubmittedGreaterThanOrEqualTo?: string;
    public dateSubmittedLessThanOrEqualTo?: string;
    public investigationQuerySubjectPacketIdentificationTypeId?: number;
    public messageTypeContains: string;
    public processingFlagContains: string;
    public investigationQueryIdentificationNumberContains: string;
    public stateResponseLogTransactionContains: string;

    public constructor(init?: Partial<SearchStateRequestLogs>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchStateRequestLogs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<SearchStateRequestLogsResponse>(); }
}

// @Route("/api/StateResponseLog/{Id}")
export class GetStateResponseDetails implements IReturn<StateResponseDetails>
{
    public id: string;

    public constructor(init?: Partial<GetStateResponseDetails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetStateResponseDetails'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StateResponseDetails(); }
}

// @Route("/api/StateRequestLog/{Id}")
export class GetStateRequestDetails implements IReturn<StateRequestDetails>
{
    public id: string;

    public constructor(init?: Partial<GetStateRequestDetails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetStateRequestDetails'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StateRequestDetails(); }
}

// @Route("/api/TermsOfService", "GET")
export class GetLatestTermsOfService implements IReturn<TermsOfServiceVersion>
{

    public constructor(init?: Partial<GetLatestTermsOfService>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetLatestTermsOfService'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TermsOfServiceVersion(); }
}

// @Route("/api/TermsOfService", "POST")
export class AcceptLatestTermsOfService implements IReturnVoid
{

    public constructor(init?: Partial<AcceptLatestTermsOfService>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AcceptLatestTermsOfService'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/Users", "POST")
// @Route("/api/Agencies/{id}/Users", "POST")
export class CreateUsers implements IReturn<CreateUsersResponse>
{
    /**
    * Id of the Agency the user is associated with
    */
    // @ApiMember(DataType="int", Description="Id of the Agency the user is associated with")
    public agencyId: number;

    /**
    * User email(s)
    */
    // @ApiMember(DataType="List", Description="User email(s)")
    public emails: string[];

    public permissionGroups: string[];
    public divisions: number[];

    public constructor(init?: Partial<CreateUsers>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateUsers'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CreateUsersResponse(); }
}

// @Route("/api/Users/ResendEmail", "POST")
export class ResendEmail implements IReturnVoid
{
    public userId: number;

    public constructor(init?: Partial<ResendEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ResendEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/Users/ForgotPassword", "GET")
export class ForgotPassword implements IReturnVoid
{
    public email: string;

    public constructor(init?: Partial<ForgotPassword>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ForgotPassword'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/api/Users/SetupDefaultNotifications", "PUT")
export class SetupDefaultNotificationsForUser implements IReturn<NotificationSetting[]>
{
    public userId: number;

    public constructor(init?: Partial<SetupDefaultNotificationsForUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetupDefaultNotificationsForUser'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new Array<NotificationSetting>(); }
}

// @Route("/api/Users/ResetPassword", "POST")
export class ResetPassword implements IReturnVoid
{
    public email: string;
    public token: string;
    public password: string;
    public confirmPassword: string;

    public constructor(init?: Partial<ResetPassword>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ResetPassword'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/Users/ChangePassword/", "POST")
export class ChangePassword implements IReturnVoid
{
    public userId: number;
    public newPassword: string;
    public currentPassword: string;
    public confirmPassword: string;

    public constructor(init?: Partial<ChangePassword>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChangePassword'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/Users/changePin/", "POST")
export class ChangePin implements IReturnVoid
{
    public userId: number;
    public newPin: string;
    public currentPassword: string;
    public confirmPin: string;

    public constructor(init?: Partial<ChangePin>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ChangePin'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/RegisteredUsers/{Id}", "GET")
export class GetRegisteredUser implements IReturn<GetRegisteredUserResponse>
{
    public id: number;

    public constructor(init?: Partial<GetRegisteredUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRegisteredUser'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetRegisteredUserResponse(); }
}

// @Route("/api/Users/Verify/", "POST")
export class VerifyAccount implements IReturnVoid
{
    public userId: number;
    public token: string;
    public firstName: string;
    public lastName: string;
    public phoneNumber: string;
    public badgeNumber: string;
    public password: string;
    public confirmPassword: string;

    public constructor(init?: Partial<VerifyAccount>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'VerifyAccount'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/Users", "GET")
export class SearchUsers extends QueryDb_2<User, UserResponse> implements IReturn<QueryResponse<UserResponse>>
{
    public id?: number;
    public agencyId?: number;
    public firstNameContains: string;
    public lastNameContains: string;
    public displayName: string;
    public fullNameContains: string;
    public permissionGroupNameContains: string;
    public divisionNameContains: string;
    public emailContains: string;
    public phoneNumberContains: string;
    public badgeNumberContains: string;
    public smsNotificationsEnabled?: boolean;
    public emailNotificationsEnabled?: boolean;
    public accountState?: number;
    public accountStateIn: number[];
    public isActive?: boolean;
    public deleted?: boolean;
    public hasOri?: boolean;
    public hasPermission: string;
    public idNotEqualTo?: number;
    public idNotIn: number[];

    public constructor(init?: Partial<SearchUsers>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchUsers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<UserResponse>(); }
}

// @Route("/api/Users/{Id}", "GET")
export class GetUser implements IReturn<UserResponse>
{
    public id: number;

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUser'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UserResponse(); }
}

// @Route("/api/Users/{Id}/HasPackets", "GET")
export class GetDeactivationChecks implements IReturn<DeactivationChecksResponse>
{
    public id: number;

    public constructor(init?: Partial<GetDeactivationChecks>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDeactivationChecks'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeactivationChecksResponse(); }
}

// @Route("/api/Users/{Id}/Permissions", "GET")
export class GetUserPermissions implements IReturn<string[]>
{
    public id: number;

    public constructor(init?: Partial<GetUserPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserPermissions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/api/Users/{Id}/Suspend")
export class SuspendUser implements IReturnVoid
{
    public id: number;
    public accountStateId: number;

    public constructor(init?: Partial<SuspendUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SuspendUser'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Users/{Id}/Inactivate", "PUT")
export class InactivateUser implements IReturnVoid
{
    public id: number;
    public userToReceivePackets?: number;

    public constructor(init?: Partial<InactivateUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InactivateUser'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Users/{Id}/Unregister", "PUT")
export class UnregisterUser implements IReturnVoid
{
    public id: number;

    public constructor(init?: Partial<UnregisterUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnregisterUser'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Users/{Id}", "PUT")
export class UpdateUser implements IReturnVoid
{
    /**
    * User id to be updated
    */
    // @ApiMember(DataType="int", Description="User id to be updated", IsRequired=true)
    public id: number;

    /**
    * User Email
    */
    // @ApiMember(DataType="string", Description="User Email")
    public email: string;

    /**
    * User badge number
    */
    // @ApiMember(DataType="string", Description="User badge number")
    public badgeNumber: string;

    /**
    * Id of the Agency the user is associated with
    */
    // @ApiMember(DataType="int", Description="Id of the Agency the user is associated with")
    public agencyId?: number;

    /**
    * User SMS notification status
    */
    // @ApiMember(DataType="boolean", Description="User SMS notification status")
    public smsNotificationsEnabled?: boolean;

    /**
    * User email notification status
    */
    // @ApiMember(DataType="boolean", Description="User email notification status")
    public emailNotificationsEnabled?: boolean;

    /**
    * User phone number
    */
    // @ApiMember(DataType="string", Description="User phone number")
    public phoneNumber: string;

    /**
    * User active status
    */
    // @ApiMember(DataType="boolean", Description="User active status")
    public isActive?: boolean;

    /**
    * User role(s)
    */
    // @ApiMember(DataType="List", Description="User role(s)")
    public permissionGroups: string[];

    public permissionsToAllow: string[];
    public permissionsToDeny: string[];
    /**
    * User division(s)
    */
    // @ApiMember(DataType="List", Description="User division(s)")
    public divisions: string[];

    /**
    * User display name
    */
    // @ApiMember(DataType="string", Description="User display name")
    public displayName: string;

    /**
    * User first name
    */
    // @ApiMember(DataType="string", Description="User first name")
    public firstName: string;

    /**
    * User last name
    */
    // @ApiMember(DataType="string", Description="User last name")
    public lastName: string;

    public constructor(init?: Partial<UpdateUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateUser'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Users/Settings", "PUT")
export class UpdateUserSettings implements IReturnVoid
{
    public settings: UserSettings;

    public constructor(init?: Partial<UpdateUserSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateUserSettings'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Users/Settings", "GET")
export class GetUserSettings implements IReturn<UserSettings>
{

    public constructor(init?: Partial<GetUserSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserSettings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UserSettings(); }
}

// @Route("/api/Users/{UserId}/GetBearerToken", "GET")
export class GetBearerTokenRequest implements IReturn<GetBearerTokenResponse>
{
    public userId: number;

    public constructor(init?: Partial<GetBearerTokenRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBearerTokenRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetBearerTokenResponse(); }
}

// @Route("/api/Users/{UserId}/Impersonation", "DELETE")
export class StopImpersonation implements IReturnVoid
{
    public userId: number;

    public constructor(init?: Partial<StopImpersonation>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StopImpersonation'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

export class GiveMeCookiesNomNomNom implements IReturn<NomNomNomCookiesResponse>
{

    public constructor(init?: Partial<GiveMeCookiesNomNomNom>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GiveMeCookiesNomNomNom'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new NomNomNomCookiesResponse(); }
}

// @Route("/api/GetStateHostStatus", "POST")
export class GetStateHostStatus implements IReturnVoid
{
    public stateAbbr: string;
    public stateName: string;

    public constructor(init?: Partial<GetStateHostStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetStateHostStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/Users/UpdatePIN", "PUT")
export class UpdateUserPIN implements IReturnVoid
{
    public pin: string;
    public userId: number;

    public constructor(init?: Partial<UpdateUserPIN>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateUserPIN'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/Users/ValidatePIN", "POST")
export class ValidateUserPIN implements IReturn<boolean>
{
    public pin: string;
    public userId: number;

    public constructor(init?: Partial<ValidateUserPIN>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ValidateUserPIN'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return false; }
}

// @Route("/api/Users/{Id}/LockedStatus", "DELETE")
export class UnlockUser implements IReturnVoid
{
    public id: number;

    public constructor(init?: Partial<UnlockUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnlockUser'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api/Users/{Id}/GetUserAuthenticatorSecret", "GET")
export class GetUserMFAAuthenticatorSecret implements IReturn<GetUserMFAAuthenticatorSecretResponse>
{
    public checkNewUser: boolean;

    public constructor(init?: Partial<GetUserMFAAuthenticatorSecret>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserMFAAuthenticatorSecret'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserMFAAuthenticatorSecretResponse(); }
}

// @Route("/api/Users/{Id}/GetUserIsAuthenticatorEnabled", "GET")
export class GetUserIsAuthenticatorEnabled implements IReturn<GetUserIsAuthenticatorEnabledResponse>
{
    public userId: string;

    public constructor(init?: Partial<GetUserIsAuthenticatorEnabled>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserIsAuthenticatorEnabled'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserIsAuthenticatorEnabledResponse(); }
}

// @Route("/api/Users/{Id}/ValidateMFAAuthenticatorCode", "POST")
export class ValidateMFAAuthenticatorCode implements IReturn<ValidateMFAAuthenticatorCodeResponse>
{
    public user: number;
    public authenticatorMfaCode: string;
    public method: string;

    public constructor(init?: Partial<ValidateMFAAuthenticatorCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ValidateMFAAuthenticatorCode'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ValidateMFAAuthenticatorCodeResponse(); }
}

// @Route("/api/Users/{Id}/DisableMFAAuthenticator", "POST")
export class DisableMFAAuthenticator implements IReturnVoid
{
    public user: number;

    public constructor(init?: Partial<DisableMFAAuthenticator>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DisableMFAAuthenticator'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api/Users/{Id}/GetUserSecret", "GET")
export class GetUserSecret implements IReturn<GetUserSecretResponse>
{
    public userId?: number;

    public constructor(init?: Partial<GetUserSecret>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserSecret'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserSecretResponse(); }
}

// @Route("/api/Monitoring/Vehicle", "GET")
export class SearchVehicleMonitoring extends QueryDb_2<VehicleMonitoring, VehicleMonitoringResponse> implements IReturn<QueryResponse<VehicleMonitoringResponse>>
{
    public titleContains: string;
    public location: string;
    public licensePlateNumberContains: string;
    public stateId?: number;
    public createdBy?: number;
    public createdAtLessThanOrEqualTo?: string;
    public createdAtGreaterThanOrEqualTo?: string;
    public updatedAtLessThanOrEqualTo?: string;
    public updatedAtGreaterThanOrEqualTo?: string;

    public constructor(init?: Partial<SearchVehicleMonitoring>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchVehicleMonitoring'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<VehicleMonitoringResponse>(); }
}

// @Route("/api/Monitoring/Vehicle/{Id}", "GET")
export class GetVehicleMonitoring implements IReturn<VehicleMonitoringResponse>
{
    public id: string;

    public constructor(init?: Partial<GetVehicleMonitoring>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetVehicleMonitoring'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new VehicleMonitoringResponse(); }
}

// @Route("/api/Monitoring/Vehicle", "POST")
export class CreateVehicleMonitoring implements IReturn<VehicleMonitoringResponse>
{
    public title: string;
    public location: string;
    public licensePlateNumber: string;
    public stateId: number;

    public constructor(init?: Partial<CreateVehicleMonitoring>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CreateVehicleMonitoring'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new VehicleMonitoringResponse(); }
}

// @Route("/api/Monitoring/Vehicle", "PUT")
export class UpdateVehicleMonitoring implements IReturnVoid
{
    public id: string;
    public title: string;
    public location: string;
    public licensePlateNumber: string;
    public stateId: number;

    public constructor(init?: Partial<UpdateVehicleMonitoring>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateVehicleMonitoring'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/api/VIN/{VIN}", "GET")
export class DecodeVinRequest implements IReturn<DecodeVinResponse>
{
    public vin: string;
    public year: string;

    public constructor(init?: Partial<DecodeVinRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecodeVinRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DecodeVinResponse(); }
}

// @Route("/api/TimeZones", "GET")
export class GetTimeZones implements IReturn<TimeZone[]>
{

    public constructor(init?: Partial<GetTimeZones>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTimeZones'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<TimeZone>(); }
}

// @Route("/api/AccountStates", "GET")
export class GetAccountStates implements IReturn<AccountState[]>
{

    public constructor(init?: Partial<GetAccountStates>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAccountStates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AccountState>(); }
}

// @Route("/api/AgencyTypes", "GET")
export class GetAgencyTypes implements IReturn<AgencyType[]>
{

    public constructor(init?: Partial<GetAgencyTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAgencyTypes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AgencyType>(); }
}

// @Route("/api/DispositionTypes", "GET")
export class GetDispositionTypes implements IReturn<DispositionType[]>
{

    public constructor(init?: Partial<GetDispositionTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDispositionTypes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<DispositionType>(); }
}

// @Route("/api/EntityTypes", "GET")
export class GetEntityTypes implements IReturn<EntityType[]>
{

    public constructor(init?: Partial<GetEntityTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEntityTypes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<EntityType>(); }
}

// @Route("/api/IdentificationTypes", "GET")
export class GetIdentificationTypes implements IReturn<IdentificationTypeResponse[]>
{

    public constructor(init?: Partial<GetIdentificationTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIdentificationTypes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<IdentificationTypeResponse>(); }
}

// @Route("/api/IntelTypes", "GET")
export class GetIntelTypes implements IReturn<IntelType[]>
{

    public constructor(init?: Partial<GetIntelTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIntelTypes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<IntelType>(); }
}

// @Route("/api/InvestigationStates", "GET")
export class GetInvestigationStates implements IReturn<InvestigationState[]>
{

    public constructor(init?: Partial<GetInvestigationStates>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInvestigationStates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<InvestigationState>(); }
}

// @Route("/api/MediaParentTypes", "GET")
export class GetMediaParentTypes implements IReturn<MediaParentType[]>
{

    public constructor(init?: Partial<GetMediaParentTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMediaParentTypes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<MediaParentType>(); }
}

// @Route("/api/NotificationTypes", "GET")
export class GetNotificationTypes implements IReturn<NotificationType[]>
{

    public constructor(init?: Partial<GetNotificationTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetNotificationTypes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<NotificationType>(); }
}

// @Route("/api/PacketStates", "GET")
export class GetPacketStates implements IReturn<EntityState[]>
{

    public constructor(init?: Partial<GetPacketStates>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPacketStates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<EntityState>(); }
}

// @Route("/api/SubjectPacketTypes", "GET")
export class GetSubjectPacketTypes implements IReturn<SubjectPacketType[]>
{

    public constructor(init?: Partial<GetSubjectPacketTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSubjectPacketTypes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<SubjectPacketType>(); }
}

// @Route("/api/Permissions", "GET")
export class GetPermissions implements IReturn<Permission[]>
{

    public constructor(init?: Partial<GetPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPermissions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<Permission>(); }
}

// @Route("/api/InvestigationTypes", "GET")
export class GetInvestigationTypes implements IReturn<InvestigationType[]>
{

    public constructor(init?: Partial<GetInvestigationTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInvestigationTypes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<InvestigationType>(); }
}

// @Route("/api/Races", "GET")
export class GetRaces implements IReturn<string[]>
{

    public constructor(init?: Partial<GetRaces>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRaces'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/api/Sexes", "GET")
export class GetSexes implements IReturn<string[]>
{

    public constructor(init?: Partial<GetSexes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSexes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/api/States", "GET")
export class GetStates implements IReturn<StateResponse>
{

    public constructor(init?: Partial<GetStates>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetStates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StateResponse(); }
}

// @Route("/api/Classifications", "GET")
export class SubjectPacketClassificationRequest implements IReturn<SubjectPacketClassification[]>
{

    public constructor(init?: Partial<SubjectPacketClassificationRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SubjectPacketClassificationRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<SubjectPacketClassification>(); }
}

// @Route("/api/SubjectTypes/{IdentificationTypeId}", "GET")
export class SubjectTypeRequest implements IReturn<SubjectType[]>
{
    public identificationTypeId?: number;

    public constructor(init?: Partial<SubjectTypeRequest>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SubjectTypeRequest'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<SubjectType>(); }
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/api/Divisions/{DivisionId}/DefaultNotifications", "GET")
// @ValidateRequest(Validator="IsAuthenticated")
export class SearchDefaultNotifications extends QueryDb_2<AgencyDefaultSubjectNotification, DefaultNotificationResponse> implements IReturn<QueryResponse<DefaultNotificationResponse>>
{
    public divisionId: number;

    public constructor(init?: Partial<SearchDefaultNotifications>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchDefaultNotifications'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryResponse<DefaultNotificationResponse>(); }
}

